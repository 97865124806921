import { Component, Optional, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { CreateComponent } from '@app/common/templates/create/create.component';
import { Base } from '@app/models/base';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library';
//import { TblKomponenteTypCreateComponent } from '@app/views/stammdaten/tbl_komponente_typ/create/create.component';
// Request access to the user's camera

@Component({
	//templateUrl: '../../../../common/templates/create/create.component.html',
	//styleUrls: ['../style.scss', '../../../../common/templates/create/style.scss'],
	//templateUrl: '../../../../../common/templates/create/create.component.html',
	templateUrl: './vehicle_add_multiple_components_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/create/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class VehicleAddMultipleComponentsDialogComponent extends CreateComponent {
	selectedField: number = 0;
	@ViewChild('camera') cameraElement!: ElementRef<HTMLVideoElement>;
	nIndexKomponente: number = 0;
	entries: any[] = [];
	optionsEx: any = null;
	cameraShown: boolean = false

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		@Optional() public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService
	) {
		super(accountService, breadcrumbService, config, confirmationService, crudService, dialogService, globals, messageService, ref, router, translate);

		this.apiUrl = 'TblKomponente';
		this.name = 'MENU.KOMPONENTE';
		this.url = '/' + PATH.KOMPONENTE;
		let dateFieldgroup = [{ type: 'date', key: 'COMPONENT_LINK_FROM', required: false, width: 400, dateonly: true }];
		this.entries.push({});
		let initialFieldGroup =
		[
			{ type: 'dropdown', fgIndex: this.nIndexKomponente, key: 'bezeichnung', label: 'bezeichnung', required: false },
			{ type: 'text', fgIndex: this.nIndexKomponente, key: 'serial_no', required: false, width: 400 },
			{ type: 'object', fgIndex: this.nIndexKomponente, key: 'komponente_typ_id', label: 'komponente_typ', required: false, width: 400, apiUrl: 'TblKomponenteTyp' },
		]
		//this.fieldgroups.push([dateFieldgroup, initialFieldGroup]);
		this.fieldgroups.push(dateFieldgroup);
		this.fieldgroups.unshift(initialFieldGroup);

		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);
	}

	//ngAfterViewInit(): void {
	//  this.startCamera();
	//}

	toggleCamera() {
		this.cameraShown = !this.cameraShown;
		if (this.cameraShown) {
			this.startCamera();
		}
	}

	openCamera(fgIndex: number) {
		this.selectedField = fgIndex;
		this.cameraShown = true;
		this.startCamera();
	}	
  
	startCamera() {
		// Wait for a moment 
		setTimeout(() => {
		}, 1000);
		const hints = new Map();
		const formats = [BarcodeFormat.CODE_39, BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX];

		hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

		const codeReader = new BrowserMultiFormatReader();

		navigator.mediaDevices.getUserMedia({ video: true })
		.then((stream) => {
			if (this.cameraElement && this.cameraElement.nativeElement) {
				this.cameraElement.nativeElement.srcObject  = stream;
			} else {
				console.error('Video element not found.');
				return;
			}

			const videoTrack = stream.getVideoTracks()[0];

			codeReader.decodeFromVideoDevice(undefined, this.cameraElement.nativeElement, (result, err) => {
				if (this.cameraShown) {
					if (result) {
						console.log(result.getText());
						this.entries[this.selectedField]['serial_no'] = result.getText();
						this.selectedField = null;
						this.cameraShown = false;
					}
				} else {
					// Stops decoder and video track
					// when cameraShown is false
					codeReader.reset();
					videoTrack.stop();
				}
			});

			console.log('Started continuous decode from camera.');
		})
		.catch((error) => {
			console.error('Error accessing camera:', error);
		});
	}

	addFieldGroup() {
		++this.nIndexKomponente;
		this.entries.push({});
		let fieldGroup =
		[
			{ type: 'dropdown', fgIndex: this.nIndexKomponente, key: 'bezeichnung', label: 'bezeichnung', required: false },
			{ type: 'text', fgIndex: this.nIndexKomponente, key: 'serial_no', required: false, width: 400 },
			{ type: 'object', fgIndex: this.nIndexKomponente, key: 'komponente_typ_id', label: 'komponente_typ', required: false, width: 400, apiUrl: 'TblKomponenteTyp' },
		]
		this.fieldgroups.unshift(fieldGroup);
		this.getOptions();
	}

	getOptions(): void {
		if( this.nIndexKomponente === 0 ) {
			this.optionsEx = [];
			this.optionsEx.push({});
			this.options.bezeichnung = [];
			this.loading += 1;
			this.crudService.getVehicleComponentDefaultNames().then(res => {
				if(res && res.length > 0) {
					const o = [];
					res.forEach(dataEntry => {
						o.push({
							label: dataEntry,
							value: dataEntry
						});
					});
					this.options.bezeichnung = o;
				}

				this.fieldgroups.forEach(fg => {
					fg.forEach(field => {
						if (field.type == 'object' || field.type == 'list') {
							this.loading += 1;
							this.crudService.getAllEntries(field.apiUrl).then(res => {
								this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option.ds_this_id, object: option }));
								field.optionSortFieldOptions = [
									{ label: this.translate.instant('HEADERS.lfd_nummer'), value: 'lfd_nummer' },
									{ label: this.translate.instant('HEADERS.ankey'), value: 'ankey' },
									{ label: this.translate.instant('HEADERS.Bezeichnung'), value: 'bezeichnung' }
								];
								//field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
								//field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
								this.changeSorting(field, true);
								this.optionsEx[0] = this.options;
							}).catch(err => {
								err.error.forEach(e => {
									this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
								})
							}).finally(() => {
								this.loading -= 1;
							});
						}
					});
				});
			}).catch(() => {
				// no exception expected...
			}).finally(()=> {
				this.loading -= 1;
			})
		} else {
			this.optionsEx.push(this.options);
			//console.log('foobar!')
			//console.log('foobar entries...')
			//console.log(this.entries);
			//console.log('foobar optionsEx...')
			//console.log(this.optionsEx);
			//console.log('foobar index...')
			//console.log(this.nIndexKomponente);
			//console.log('foobar ENDE!')
		}
	}

	returnEmpty(): void {
		if( this.ref ) {
			this.ref.close({isEmpty: true});
		}
	}

	/*
	 * override
	 */
	createEntry(): void {
		//console.log('foobar!')
		//console.log('foobar entries...')
		//console.log(this.entries);
		//console.log('foobar optionsEx...')
		//console.log(this.optionsEx);
		//console.log('foobar ENDE!')

		if( this.entries != null && this.entries != undefined && this.entries.length > 0 ) {
			this.loading += 1;
			let createEntries: Base[] = [];
			this.entries.forEach(data => {
				if(data && data['bezeichnung'] && data['bezeichnung'].trim().length > 0) {
					if(data['serial_no'] != null && data['serial_no'] != undefined && data['serial_no'].trim().length > 0) {
						if(data['komponente_typ_id'] != null && data['komponente_typ_id'] != undefined) {
							createEntries.unshift(data);
						}
					}
				}
			});

			if( createEntries.length > 0 ) {
				this.crudService.createComponents(createEntries).then(res => {
					if(res && res.length > 0) {
						if(this.ref)
							this.ref.close({neu_angelegte: res, dateFrom: this.entry['COMPONENT_LINK_FROM']});
					} else {
						this.returnEmpty();
					}
				}).catch(() => {
					//
				}).finally(() => {
					this.loading -= 1;
				});
			} else {
				return this.returnEmpty();
			}
		} else {
			// leer, return empty
			this.returnEmpty();
		}
	}
}
