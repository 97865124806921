import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblObjektFilterDialogComponent extends FilterDialogComponent {
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		const optionGroup1 = new Map();
		optionGroup1.set('ankey', { type: 'text' });
		optionGroup1.set('filterflag_objekte_mit_historie', { type: 'boolean' });
		optionGroup1.set('typ', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblObjektTyp', values: [] });
		optionGroup1.set('status', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblObjektStatus', values: [] });
		optionGroup1.set('entsorger', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblFirma', values: [] });

		const optionGroup2 = new Map();
		optionGroup2.set('strasse', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblStrasse', values: [] });
		optionGroup2.set('hausnummer_von', { type: 'numeric', min: 0, max: null, step: 1 });
		optionGroup2.set('hausnummer_bis', { type: 'numeric', min: 0, max: null, step: 1 });
		optionGroup2.set('plz', { type: 'text' });
		optionGroup2.set('ort', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblOrt', values: [] });
		optionGroup2.set('gebiet', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblGebiet', values: [] });
		optionGroup2.set('gruppe', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblObjektGruppe', values: [] });
		optionGroup2.set('eigentuemer', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblKunde', values: [] });
		optionGroup2.set('pflichtiger', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblKunde', values: [] });

		this.optionGroups = [optionGroup1, optionGroup2];
	}
}
