<!-- Confirm -->
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading !== 0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>

<!-- Toolbar -->
<div class="card p-my-0">
	<p-toolbar>
		<div class="p-toolbar-group-left">
			<button *ngIf="createPermission" type="button" pButton pRipple icon="fa fa-floppy-disk"
				class="p-button-success p-mr-2" pTooltip="{{'BUTTONS.SAVE' | translate }}" tooltipPosition="bottom"
				(click)="createEntry()"></button>
		</div>
		<h5 class="p-m-0">{{ name | translate }}</h5>
		<div class="p-toolbar-group-right">
			<button type="button" pButton
				[icon]="state.horizontalGroups ? 'fa fa-grip-vertical' : 'fa fa-grip-horizontal'"
				class="p-button-secondary p-mx-2"
				pTooltip="{{(state.horizontalGroups ? 'BUTTONS.VERTICAL_GROUPS' : 'BUTTONS.HORIZONTAL_GROUPS') | translate }}"
				tooltipPosition="bottom" (click)="setHorizontalGroups(!state.horizontalGroups)"></button>
		</div>
	</p-toolbar>
</div>

<!-- Content -->
<div *ngIf="entry">
	<div class="p-grid p-formgrid">
		<div *ngFor="let fields of fieldgroups"
			[ngClass]="state.horizontalGroups ? 'p-col-12  p-my-2' : ('p-col-12' + (fieldgroups.length > 1 ? ' p-md-6' : '') + (fieldgroups.length > 3 ? ' p-lg-3' : '') + ' p-my-2')">
			<div class="card" style="height:100%;">
				<div class="p-grid p-formgrid">
					<div *ngFor="let field of fields"
						 [ngClass]="state.horizontalGroups ? 'p-col-12 p-md-6 p-lg-3 p-my-2' : 'p-col-12 p-my-2'">
						<h6 class="p-my-2">
							<!--{{ 'HEADERS.' + field.key | translate }}-->
							{{ (field.sub ? ('HEADERS.' + field.sub + '.' + field.key) : ('HEADERS.' + field.key)) | translate }}
						</h6>
						<div *ngIf="field.type == 'text'">
							<input type="text" pInputText [(ngModel)]="entry[field.key]" />
							<button type="button" pButton pRipple icon="fa fa-camera" *ngIf="field.key == 'serial_no'"
								class="p-button-secondary p-mx-2" pTooltip="open scanner" tooltipPosition="bottom"
								(click)="openCamera(field.fgIndex)"></button>
						</div>
						<div *ngIf="field.type == 'numeric'">
							<p-inputNumber [(ngModel)]="entry[field.key]" [showButtons]="true" [min]="0" mode="decimal"
										   locale="de-DE" [style]="{'width':'100%'}"></p-inputNumber>
						</div>
						<div *ngIf="field.type == 'number'">
							<p-inputNumber [(ngModel)]="entry[field.key]" [showButtons]="false" [min]="0" mode="decimal"
										   locale="de-DE" [style]="{'width':'100%'}"></p-inputNumber>
						</div>
						<div *ngIf="field.type == 'boolean'">
							<p-inputSwitch [(ngModel)]="entry[field.key]"></p-inputSwitch>
						</div>
						<div *ngIf="field.type == 'date'" [style.width]="'90%'" [style.max-width]="'400px'">
							<p-calendar [style]="{'width':'100%'}" [showIcon]="true" [showTime]="showTime(field)"
										[showSeconds]="true" hourFormat="24" dateFormat="dd/mm/yy"
										[(ngModel)]="entry[field.key]" [touchUI]="isMobile" appendTo="body"></p-calendar>
						</div>
						<div *ngIf="field.type == 'object'" class="p-inputgroup" [style.width]="'90%'"
							 [style.max-width]="'400px'">
							<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}"
										[options]="options[field.key]" [(ngModel)]="entry[field.key]" placeholder="-"
										[showClear]="true" [virtualScroll]="true" itemSize="30" [filter]="true" appendTo="body"
										dropdownDirective>
								<ng-template pTemplate="header">
									<div class="p-inputgroup p-px-4 p-pt-3" (click)="$event.stopPropagation()">
										<p-dropdown #optionSortFieldSelection class="dropdown-button-group"
													[style]="{'width':'100%'}" [options]="field.optionSortFieldOptions"
													placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true"
													(onChange)="changeSorting(field);$event.originalEvent.stopPropagation();"
													[(ngModel)]="field.optionSortField" appendTo="body" dropdownDirective>
										</p-dropdown>
										<button type="button" pButton pRipple
												[icon]="field.sortDescending ? 'fa fa-arrow-down-wide-short' : 'fa fa-arrow-down-short-wide'"
												class="p-button-secondary p-ml-1"
												(click)="field.sortDescending = !field.sortDescending; changeSorting(field);"
												pTooltip="{{'BUTTONS.CHANGE_SORTING' | translate }}"
												tooltipPosition="bottom"></button>
									</div>
								</ng-template>
							</p-dropdown>
							<div *ngIf="field.createComponent">
								<button type="button" pButton pRipple icon="fa fa-plus" class="p-button-success p-ml-1"
									(click)="createOption(field)" pTooltip="{{'BUTTONS.NEW' | translate }}"
									tooltipPosition="bottom"></button>
							</div>
						</div>
						<div *ngIf="field.type == 'list'" class="p-inputgroup" [style.width]="'90%'"
							 [style.max-width]="'400px'">
							<p-multiSelect [style]="{'width':'100%'}" [options]="options[field.key]" placeholder="-"
										   [(ngModel)]="entry[field.key]" [virtualScroll]="true" itemSize="30" appendTo="body"
										   (onPanelShow)="formatMultiselectLayout()" multiselectDirective>
								<ng-template pTemplate="header">
									<div class="p-inputgroup p-pb-2 p-pt-1 multiselectSort"
										 (click)="$event.stopPropagation()">
										<p-dropdown #optionSortFieldSelection class="dropdown-button-group"
													[style]="{'width':'100%'}" [options]="field.optionSortFieldOptions"
													placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true"
													(onChange)="changeSorting(field);$event.originalEvent.stopPropagation();"
													[(ngModel)]="field.optionSortField" appendTo="body" dropdownDirective>
										</p-dropdown>
										<button type="button" pButton pRipple
												[icon]="field.sortDescending ? 'fa fa-arrow-down-wide-short' : 'fa fa-arrow-down-short-wide'"
												class="p-button-secondary p-ml-1"
												(click)="field.sortDescending = !field.sortDescending; changeSorting(field);"
												pTooltip="{{'BUTTONS.CHANGE_SORTING' | translate }}"
												tooltipPosition="bottom"></button>
									</div>
								</ng-template>
							</p-multiSelect>
							<button type="button" pButton pRipple icon="fa fa-plus" class="p-button-success p-ml-2"
									(click)="createOption(field)" pTooltip="{{'BUTTONS.NEW' | translate }}"
									tooltipPosition="bottom"></button>
						</div>
						<div *ngIf="field.type === 'plz'">
							<p-inputMask [(ngModel)]="entry[field.key]" mask="99999" slotChar=""
										 (onComplete)="getPlacesFromPostalCode(field.key)">
							</p-inputMask>
						</div>
						<div *ngIf="field.type === 'village'" class="p-inputgroup" [style.width]="'90%'"
							 [style.max-width]="'400px'">
							<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}"
										[options]="placeNames[field.plz_field]" placeholder="-" [showClear]="true"
										[virtualScroll]="true" itemSize="30" [filter]="true" editable="true"
										[(ngModel)]="entry[field.key]" appendTo="body" dropdownDirective>
								<ng-template pTemplate="header">
									<div class="p-inputgroup p-px-4 p-pt-3" (click)="$event.stopPropagation()">
										<p-dropdown #optionSortFieldSelection class="dropdown-button-group"
													[style]="{'width':'100%'}" [options]="field.optionSortFieldOptions"
													placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true"
													(onChange)="changeSorting(field);$event.originalEvent.stopPropagation();"
													[(ngModel)]="field.optionSortField" appendTo="body" dropdownDirective>
										</p-dropdown>
										<button type="button" pButton pRipple
												[icon]="field.sortDescending ? 'fa fa-arrow-down-wide-short' : 'fa fa-arrow-down-short-wide'"
												class="p-button-secondary p-ml-1"
												(click)="field.sortDescending = !field.sortDescending; changeSorting(field);"
												pTooltip="{{'BUTTONS.CHANGE_SORTING' | translate }}"
												tooltipPosition="bottom"></button>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div *ngIf="field.type === 'dropdown'" class="p-inputgroup" [style.width]="'90%'"
							 [style.max-width]="'400px'">
							<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}"
										[options]="options[field.key]" [(ngModel)]="entry[field.key]" placeholder="-"
										[showClear]="true" [virtualScroll]="true" itemSize="30" [filter]="true"
										appendTo="body" dropdownDirective></p-dropdown>
						</div>
						<small *ngIf="field.required && (entry[field.key] == null || entry[field.key] === '')"
							   class="p-error block" translate>REQUIRED</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>