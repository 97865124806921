import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals, VIONSessionStorage } from '@app/common/global_variables';
import { Base } from '@app/models/base';
import { Collection } from '@app/models/collection';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { jsPDF } from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { defaults as defaultControls, FullScreen, ScaleLine, ZoomSlider } from 'ol/control';
import { Point } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Icon, Style } from 'ol/style';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { TblLeerungFilterDialogComponent } from '../filter_dialog/filter_dialog.component';
import { Splitter } from 'primeng/splitter';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblLeerungListComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('table') table: Table;
	@ViewChild('colselection') colselection: MultiSelect;
	@ViewChild('emptyCanvas', { static: false }) emptyCanvas: ElementRef;
	apiUrl: string = '';
	buttonColWidth: number = 150;
	cols: any[] = [];
	contentHeight: number = 4320;
	contextMenu: MenuItem[];
	coord = [10.447683, 51.163361];
	count: number = 0;
	createPermission: boolean = false;
	deletePermission: boolean = false;
	entries: Collection[];
	filters: string[];
	globalFilter: string = '';
	highlightedCollection = null;
	imageUrl: string;
	isMobile: boolean = false;
	isTableInit: boolean = false;
	leerungLayer: VectorLayer;
	leerungStyle: Style;
	loadFilters: any;
	loading: number = 0;
	loadTimestamp: Date;
	mapLeerungenListe: Map;
	mapLayer: TileLayer;
	name: string = '';
	oldCols: any[] = [];
	persistenceCheckInterval: any;
	possibleCols: any[] = [];
	readPermission: boolean = false;
	selectedEntry: Base;
	state: any;
	stateName: string = '';
	tooltip: string = '';
	trackByFunction = (index, item) => {
		return item.ds_auto_id;
	}
	updatePermission: boolean = false;
	url: string = '';
	zoom = 6.5;
	splitterFBDetailLayout: string = 'horizontal';
	@ViewChild('splitterFBDetail') splitterFBDetail: Splitter;

	/* todoItems$ = liveQuery(
		() => this.listTodoItems()
	);

	async listTodoItems() {
		return await database.tbl_leerung.toArray();
	} */

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
	) {
		this.apiUrl = 'TblLeerung';
		this.name = 'MENU.LEERUNGEN';
		this.url = '/' + PATH.LEERUNG;
		this.stateName = 'state' + this.apiUrl + 'List';

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'f_zeitpunkt', required: true, width: 400 },
			{ type: 'numeric', key: 'f_quelle', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_LRG', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_STOERSTOFF', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_ASF', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_ABR', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_status_ankey', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_status_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_gefaess_ankey', required: true, width: 400 },
			{ type: 'text', key: 'f_barcode', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_volumen_ankey', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_volumen_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_abfallart_ankey', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_ankey', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_eigentuemer_ankey', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_eigentuemer_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_kennzeichen', required: true, width: 400 },
			{ type: 'numeric', key: 'f_brutto', required: true, width: 400 },
			{ type: 'numeric', key: 'f_tara', required: true, width: 400 },
			{ type: 'numeric', key: 'f_netto', required: true, width: 400 },
			{ type: 'numeric', key: 'f_wiegeschein', required: true, width: 400 },
			{ type: 'text', key: 'standort', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_fahrtbericht_ankey', required: true, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'f_zeitpunkt', required: true, width: 400 },
			{ type: 'numeric', key: 'f_quelle', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_LRG', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_ABR', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_status_ankey', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_status_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_gefaess_ankey', required: true, width: 400 },
			{ type: 'text', key: 'f_barcode', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_volumen_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_ankey', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_eigentuemer_bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_kennzeichen', required: true, width: 400 },
			{ type: 'numeric', key: 'f_brutto', required: true, width: 400 },
			{ type: 'numeric', key: 'f_tara', required: true, width: 400 },
			{ type: 'numeric', key: 'f_netto', required: true, width: 400 },
			{ type: 'numeric', key: 'f_wiegeschein', required: true, width: 400 },
			{ type: 'text', key: 'standort', required: true, width: 400 },
		];

		/** 
		 f_quelle
		 VIRTUAL_flagresult_STOERSTOFF
		 VIRTUAL_flagresult_ASF
		 FREMD_status_ankey
		 FREMD_gefaess_ankey
		 FREMD_volumen_ankey
		 FREMD_abfallart_ankey
		 standort
		 FREMD_fahrtbericht_ankey
		*/
		this.breadcrumbService.setItems([
			{ label: 'MENU.BERICHTE' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		this.stateName = 'state' + this.apiUrl + 'List';

		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.DELETE);

		if (this.readPermission) {
			const storage = this.storageGetEntries();
			if (storage && storage.timestamp && storage.entries && storage.filters) {
				this.loading += 1;
				const storedTimestamp = new Date(storage.timestamp);
				this.loadTimestamp = storedTimestamp;
				this.loadFilters = storage.filters;
				this.processEntries(storage.entries);
				this.crudService.checkPersistence(this.apiUrl, storedTimestamp).then(isPersistent => {
					if (!isPersistent) {
						this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
					}
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			} else {
				if ((localStorage.getItem('defaultOpenVehicleDialog') === 'true')) {
					this.openFilterDialog();
				}
			}

			this.loading += 1;
			this.crudService.getHeader().then(res => {
				if (res) {
					const blob = new Blob([res], { type: 'application/octet-stream' });
					if (blob.size > 0) {
						const urlCreator = window.URL || window.webkitURL;
						this.imageUrl = urlCreator.createObjectURL(blob);
					}
				}
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}

		this.retrieveTableState(this.state);
		if (this.state && this.state.columnOrder) {
			this.cols = [];
			this.state.columnOrder.forEach(col => {
				this.possibleCols.forEach(c => {
					if (col == c.key) {
						this.cols.push(c);
					}
				});
			});
		}
		if (this.state && this.state.filters && this.state.filters.global) {
			this.globalFilter = this.state.filters.global.value;
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.oldCols = this.cols;
		this.filters = this.cols.map(c => c.key);

		this.loading -= 1;
	}
	
	handleFBSplitterChange(event): void {
		setTimeout(() => {
			this.mapLeerungenListe.updateSize();
		}, 100);
	}

	initSizing(): void {
		setTimeout(() => {
			if (window.innerWidth < 1280) {
				this.splitterFBDetailLayout = 'vertical';
			} else {
				this.splitterFBDetailLayout = 'horizontal';
			}
		}, 0);
		setTimeout(() => {
			if (this.splitterFBDetailLayout == 'vertical ') {
				document.getElementById('mapLeerungenListe').style.width = '100%';
				document.getElementById('table').style.width = '100%';
			}
		}, 0);
		setTimeout(() => {
			this.contentHeight = 200;
			document.getElementById('mapLeerungenListe').style.height = this.contentHeight + 'px';
		}, 0);
		setTimeout(() => {
			this.mapLeerungenListe.updateSize();
		}, 0);
		setTimeout(() => {
			if (this.elRef && this.elRef.nativeElement && this.elRef.nativeElement.parentElement && this.elRef.nativeElement.parentElement.offsetHeight) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 410 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.contentHeight < 200) {
					this.contentHeight = 200;
				}
			}
		}, 0);
	}

	/**
	 * berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	 * 
	 * @param bForce 
	 * @param state 
	 */
	resizeTableWidthFromContent(bForce, state?): void {
		var bResize = bForce;

		this.loading += 1;

		// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
		this.state = state ? state : JSON.parse(localStorage.getItem(this.stateName));
		if (this.state == undefined) {
			// force storage of table state
			this.table.saveState();
			// reload state
			this.state = JSON.parse(localStorage.getItem(this.stateName));
			bResize = true;
		}

		if (this.table && bResize) {
			// autosize columns
			const lTable = document.getElementById(this.table.id);
			var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
			// für alle spalten, alle daten            
			this.cols.forEach(col => {
				let columnname = this.translate.instant('HEADERS.' + col.key);
				let maxStringLength = this.getTextLength(columnname, lTableFont);
				// filter symbol
				maxStringLength = maxStringLength + 80;
				maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
				if (this.entries) {
					this.entries.forEach(row => {
						let newLength = 0;
						if (col.type == 'date') {
							if (row[col.key] != undefined)
								newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
							else
								newLength = 0;
						} else {
							newLength = this.getTextLength(row[col.key], lTableFont);
						}
						// margins zur zelle
						newLength = newLength + 26;
						if (newLength > maxStringLength)
							maxStringLength = newLength;
					})
				}

				col.width = maxStringLength;
			});

			//this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		}

		// standard funktion aufrufen
		this.resizeTableWidth();

		this.loading -= 1;
	}

	/**
	 * wenn eine spalte noch ein special symbol im header hat
	 * 
	 * @param maxStringLength 
	 * @param columnkey 
	 * @returns 
	 */
	adaptColumnSize(maxStringLength, columnkey): number {
		return maxStringLength;
	}

	/**
	 * Liefert die Länge der Darstellung eines Textes in Pixeln zurück
	 * 
	 * @param text Text, der überprüft werden soll
	 * @param styleFont Font, in der der Text geschrieben ist
	 * @returns Textbreite in Pixeln
	 */
	getTextLength(text: string, styleFont: string): number {
		const ctx = this.emptyCanvas.nativeElement.getContext('2d');
		ctx.font = styleFont;
		const textMetrics = ctx.measureText(text);
		return Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
	}

	ngAfterViewInit(): void {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		if (this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this.initTable();
		this.initMap();
	}

	ngAfterViewChecked(): void {
		if (!this.isTableInit && this.table.value) {
			this.isTableInit = true;
			this.resizeTableWidth();
			this.changeDetectorRef.detectChanges();
		}
	}

	ngOnDestroy(): void {
		clearInterval(this.persistenceCheckInterval);
	}

	@HostListener('window:resize', ['$event'])
	@HostListener('document:fullscreenchange')
	onResize(event): void {
		this.initTable();
	}

	// #endregion Angular

	// #region style
	hasLrgLockedFlag(entry) {
		if( entry ) {
			//if( entry['lfd_nummer'] && entry['lfd_nummer'] === 574 ) {
			//	return true;
			//} else return false;
			
			if( entry['VIRTUAL_flagresult_IdentcodeLockedOnLrg'] != null && entry['VIRTUAL_flagresult_IdentcodeLockedOnLrg'] === true ) {
				//console.log('%s: hasLrgLockedFlag TRUE', new Date());
				return true;
			} else return false;
		} else return false;
	}

	hasLrgStatus(entry) {
		if( entry ) {
			if( entry['f_status'] != null && entry['f_status'] > 0 ) {
				//console.log('%s: hasLrgStatus TRUE', new Date());
				return true;
			} else return false;
		} else return false;
	}
	// #endregion style

	// #region CRUD

	create(): void {
		this.router.navigate([this.url + '/' + PATH.CREATE]);
	}

	detail(target?: string): void {
		if (target == 'window') {
			window.open('/#/' + this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	edit(target?: string): void {
		if (target == 'window') {
			window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	delete(ds_this_id?: number): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				let sections: number[];
				this.crudService.getFilteredTripReportSections(ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id).then(res => {
					this.loading += 1;
					this.crudService.deleteTripReport(ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id).then(res => {
						this.loading += 1;
						this.crudService.deleteTripReportSections(sections).then(res => {
							// Do nothing
						}).catch(err => {
							err.error.forEach(e => {
								this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
							})
						}).finally(() => {
							this.loading -= 1;
						});
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
						this.entries.splice(this.entries.findIndex(x => x.ds_this_id == (ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id)), 1);
						this.count = this.entries.length;
						this.entries = [...this.entries];
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	// #endregion CRUD

	// #region Table

	buildReport(exportCollections: Collection[]): RowInput[] {
		const content = [];
		const dates = {};
		exportCollections.forEach(collection => {
			const dateString = new Date(collection.f_zeitpunkt).toLocaleDateString();
			if (dates[dateString]) {
				dates[dateString].Gesamt += 1;
			} else {
				dates[dateString] = { Gesamt: 1 };
			}

			const area = (collection.gebiet_id ? collection.gebiet_id + ' - ' : '') + (collection.f_gebiet ? collection.f_gebiet : 'Unbekannt');
			if (dates[dateString][area]) {
				dates[dateString][area].Gesamt += 1;
			} else {
				dates[dateString][area] = { Gesamt: 1 };
			}

			const file = collection.dateiname ? collection.dateiname : 'Unbekannt';
			if (dates[dateString][area][file]) {
				dates[dateString][area][file].Gesamt += 1;
			} else {
				dates[dateString][area][file] = { Gesamt: 1, Kennzeichen: collection.f_fahrzeug };
			}
		});

		const dateLineCounts = {};
		Object.keys(dates).forEach(date => {
			dateLineCounts[date] = 1;
			Object.keys(dates[date]).forEach(area => {
				Object.keys(dates[date][area]).forEach(file => {
					dateLineCounts[date] += 1;
				});
			});
		});

		Object.keys(dates).forEach(date => {
			let firstLineOfDate = true;
			const sortedAreas = Object.keys(dates[date]).sort((a, b) => (+a.split(' - ')[0] > +b.split(' - ')[0]) ? 1 : ((+b.split(' - ')[0] > +a.split(' - ')[0]) ? -1 : 0))
			sortedAreas.forEach(area => {
				let firstLineOfArea = true;
				if (area !== 'Gesamt') {
					Object.keys(dates[date][area]).forEach(file => {
						if (file !== 'Gesamt') {
							if (firstLineOfDate && firstLineOfArea) {
								content.push([
									{ content: date, rowSpan: dateLineCounts[date] },
									{ content: area, rowSpan: Object.keys(dates[date][area]).length },
									{ content: file },
									{ content: dates[date][area][file].Kennzeichen },
									{ content: dates[date][area][file].Gesamt }
								]);
								firstLineOfDate = false;
								firstLineOfArea = false;
							} else if (!firstLineOfDate && firstLineOfArea) {
								content.push([
									{ content: area, rowSpan: Object.keys(dates[date][area]).length },
									{ content: file },
									{ content: dates[date][area][file].Kennzeichen },
									{ content: dates[date][area][file].Gesamt }
								]);
								firstLineOfArea = false;
							} else {
								content.push([
									{ content: file },
									{ content: dates[date][area][file].Kennzeichen },
									{ content: dates[date][area][file].Gesamt }
								]);
							}
						}
					});
					content.push([
						{ content: 'Gesamt', colSpan: 2, styles: { fontStyle: 'bold' } },
						{ content: dates[date][area].Gesamt, styles: { fontStyle: 'bold' } }
					]);
				}
			});
			content.push([
				{ content: 'Gesamt', colSpan: 3, styles: { fontStyle: 'bold' } },
				{ content: dates[date].Gesamt, styles: { fontStyle: 'bold' } }
			]);
		});

		if (Object.keys(dates).length > 1) {
			content.push([
				{ content: 'Gesamt', colSpan: 4, styles: { fontStyle: 'bold' } },
				{ content: exportCollections.length, styles: { fontStyle: 'bold' } }
			]);
		}

		return content;
	}

	changeWheelSpeed(container, speedY): any {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	createReport(): void {
		this.loading += 1;
		if (!this.entries || this.entries.length === 0) {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.NO_DATA'), detail: this.translate.instant('ERRORS.LOAD_DATA_FIRST'), life: 3000 });
		} else {
			const doc = new jsPDF({
				orientation: 'p',
				format: 'a4'
			});

			let title = 'Leerungsbericht - ';
			if (this.loadFilters) {
				if (this.loadFilters.DateFrom.toLocaleDateString() === this.loadFilters.DateTo.toLocaleDateString()) {
					title += this.loadFilters.DateFrom.toLocaleDateString();
				} else {
					title += this.loadFilters.DateFrom.toLocaleDateString() + ' bis ' + this.loadFilters.DateTo.toLocaleDateString();
				}
			}

			const disposers = {};

			const collections = this.entries.sort((a, b) => { return a.f_zeitpunkt.getTime() - b.f_zeitpunkt.getTime(); });
			collections.forEach(collection => {
				const disposerName = collection.FREMD_entsorger_bezeichnung ? collection.FREMD_entsorger_bezeichnung : 'Entsorger ' + collection.entsorger_id;
				if (disposers[disposerName]) {
					disposers[disposerName].push(collection);
				} else {
					disposers[disposerName] = [collection];
				}
			});

			Object.keys(disposers).forEach(disposer => {
				let top = 15;

				if (this.imageUrl) {
					const img = new Image();
					img.src = this.imageUrl; // 'assets/images/collection_report_header.png';
					doc.addImage(img, 'png', 30, 10, 150, 18);
					top = 30;
				}
				
				autoTable(doc, {
					theme: 'grid',
					rowPageBreak: 'auto',
					startY: top,
					styles: { valign: 'middle', halign: 'center', fontSize: 8, cellPadding: 1 },
					head: [
						[{
							content: title + ' - ' + disposer,
							colSpan: 5,
							styles: {
								halign: 'center', fontSize: 14, fillColor: false, textColor: [0, 0, 0],
								cellPadding: { bottom: 5 }
							}
						}],
						[
							{ content: 'Datum', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } },
							{ content: 'Gebiet', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } },
							{ content: 'Datei', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } },
							{ content: 'Fahrzeug', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } },
							{ content: 'Anzahl', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } }
						]
					],
					body: this.buildReport(disposers[disposer])
				});
				doc.addPage();
			});
			doc.deletePage(doc.getNumberOfPages());

			doc.save(title + '.pdf');
		}

		this.loading -= 1;
	}

	exportCSV(): void {
		this.exportService.exportCSV(this.translate.instant('MENU.LEERUNGEN'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	exportPDF(): void {
		this.exportService.exportPDF(this.translate.instant('MENU.LEERUNGEN'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	exportXLSX(): void {
		this.exportService.exportXLSX(this.translate.instant('MENU.LEERUNGEN'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	getFilteredEntries(filters): void {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.crudService.getFilteredCollections(filters).then(res => {
			this.messageService.clear();
			this.loadTimestamp = new Date();
			this.processEntries(res);
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	initTable(): void {
		setTimeout(() => {
			this.contentHeight = 200;
			document.getElementById('mapLeerungenListe').style.height = this.contentHeight + 'px';
			this.resizeTableWidth();
		}, 0);
		setTimeout(() => {
			this.mapLeerungenListe.updateSize();
		}, 0);
		setTimeout(() => {
			if (this.elRef && this.elRef.nativeElement && this.elRef.nativeElement.parentElement && this.elRef.nativeElement.parentElement.offsetHeight) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 210 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.contentHeight < 200) {
					this.contentHeight = 200;
				}
			}
		}, 0);
		this.adaptMapSize();
	}

	isColFiltered(col): boolean {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.state && this.state.filters[col.key]) {
			Object.keys(this.state.filters[col.key]).forEach(filter => {
				if (this.state.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event): void {
		this.count = (this.table && this.table.filteredValue) ? this.table.filteredValue.length : this.entries.length;
	}

	onColReorder(event): void {
		this.retrieveTableState(this.state);
		const columnWidths = this.state.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.state.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	onColResize(event): void {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.retrieveTableState(this.state);
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));

		this.resizeTableWidth(this.state);
	}

	openFilterDialog(): void {
		const ref = this.dialogService.open(TblLeerungFilterDialogComponent, {
			header: this.translate.instant('Leerungen Filter'),
			width: '70%'
		});

		ref.onClose.subscribe((filters) => {
			if (filters) {
				this.loadFilters = filters;
				//console.log(this.loadFilters);
				this.getFilteredEntries(filters);
			}
		});
	}

	processEntries(entries): void {
		this.loading += 1;
		if (!this.persistenceCheckInterval) {
			this.persistenceCheckInterval = setInterval(() => {
				this.crudService.checkPersistence(this.apiUrl, this.loadTimestamp).then(isPersistent => {
					if (!isPersistent) {
						this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
					}
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				});
			}, 1000 * 60 * 10);
		}
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				entries.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key]);
					}
				});
			}
		});
		this.entries = entries;
		this.count = this.entries.length;
		this.storageSetEntries();
		setTimeout(() => {
			this.updateCollections(this.entries);
		}, 0);
		this.loading -= 1;
	}

	resetTable(): void {
		this.table.clearState();
		window.location.reload();
	}

	resizeTableWidth(state?): void {
		this.loading += 1;

		this.retrieveTableState(this.state);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			tableElement.style.width = '100%';
			const columnWidths = this.state ? this.state.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.state) {
					localStorage.setItem(this.stateName, JSON.stringify(this.state));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(state?): void {
		this.state = state ? state : JSON.parse(localStorage.getItem(this.stateName));
		if (this.table && (this.state == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.state = JSON.parse(localStorage.getItem(this.stateName));
		}
	}

	storageGetEntries(): any {
		return VIONSessionStorage.getInstance().get(this.apiUrl);
	}

	storageSetEntries(): void {
		VIONSessionStorage.getInstance().set(this.apiUrl, this.loadTimestamp, this.loadFilters, this.entries);
		//database.tbl_leerung.add(this.entries[0]);
	}

	toggleColumn(event): void {
		this.retrieveTableState(this.state);
		this.state.columnOrder = event.value.map(c => c.key);
		this.state.columnWidths = event.value.map(c => c.width);
		this.state.columnWidths = this.state.columnWidths.join(',');
		this.state.columnWidths = this.state.columnWidths + ',' + this.buttonColWidth;
		this.state.tableWidth = (this.state.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.resizeTableWidth(this.state);
	}

	// #endregion Table

	// #region Map

	adaptMapSize(): void {
		setTimeout(() => {
			document.getElementById('mapLeerungenListe').style.height = this.contentHeight + 130 + 'px';
		}, 0);
		setTimeout(() => {
			this.mapLeerungenListe.updateSize();
		}, 0);
	}

	center(entry): void {
		const long = entry.f_longitude ? entry.f_longitude / 6000000 : 0.0;
		const lat = entry.f_latitude ? entry.f_latitude / 6000000 : 0.0;
		if (long != null && lat != null && (long != 0.0 || lat != 0.0) && long >= -180.0 && long <= 180.0 && lat >= -90.0 && lat <= 90.0) {
			setTimeout(() => {
				this.mapLeerungenListe.updateSize();
				this.mapLeerungenListe.getView().animate({ center: [long, lat], zoom: (this.mapLeerungenListe.getView().getZoom() < 14 ? 14 : this.mapLeerungenListe.getView().getZoom()) });
				//this.mapLeerungenListe.getView().centerOn([entry.f_longitude ? entry.f_longitude / 6000000 : 0, entry.f_latitude ? entry.f_latitude / 6000000 : 0], this.mapLeerungenListe.getSize(), [this.mapLeerungenListe.getSize()[0] / 2, this.mapLeerungenListe.getSize()[1] / 2]);
			}, 100);
		}
	}

	displayTooltip(event): void {
		const pixel = event.pixel;
		document.getElementById('tooltip').style.display = 'none';
		this.mapLeerungenListe.forEachFeatureAtPixel(pixel, feature => {
			this.tooltip = feature.get('name');
			document.getElementById('tooltip').style.display = 'inherit';
			document.getElementById('tooltip').style.top = (document.getElementById('mapLeerungenListe').getBoundingClientRect().top - document.body.getBoundingClientRect().top - 25 + event.pixel[1]) + 'px';
			document.getElementById('tooltip').style.left = (document.getElementById('mapLeerungenListe').getBoundingClientRect().left - document.body.getBoundingClientRect().left + 5 + event.pixel[0]) + 'px';
		});
	}

	highlightCollection(collection: Collection, feature = null): void {
		const newCenter = [collection.f_longitude / 6000000, collection.f_latitude / 6000000];
		this.mapLeerungenListe.getView().animate({
			center: newCenter,
			zoom: 18,
			duration: 1000
		})

		const iconScale = 1.5 / (1.0 + this.mapLeerungenListe.getView().getMaxZoom() - this.mapLeerungenListe.getView().getZoom());

		if (this.highlightedCollection) {
			this.highlightedCollection.feature.setStyle(
				new Style({
					image: new Icon({
						src: 'assets/icons/trash-green.png',
						scale: iconScale
					})
				})
			);
		}
		if (!feature) {
			this.leerungLayer.getSource().getFeatures().forEach(f => {
				if (f.get('name').split(' - ')[1] === collection.f_barcode) {
					feature = f;
				}
			});
		} else {
			this.table.scrollToVirtualIndex(this.table.value.indexOf(collection));
		}
		feature.setStyle(
			new Style({
				image: new Icon({
					src: 'assets/icons/trash-brown.png',
					scale: iconScale
				})
			})
		);
		this.selectedEntry = collection;
		this.highlightedCollection = { collection, feature };
	}

	initMap(): void {
		this.leerungStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({ color: 'red' }),
			})
		});

		this.mapLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: environment.mapUrl,
				format: 'image/png',
				crossOrigin: 'anonymous'
			})
		});
		this.leerungLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: this.leerungStyle,
			name: 'Leerungen'
		});

		useGeographic();
		this.mapLeerungenListe = new Map({
			controls: defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]),
			target: 'mapLeerungenListe',
			view: new View({
				center: this.coord,
				zoom: this.zoom,
				minZoom: 5,
				maxZoom: 18,
			}),
			renderer: 'webgl',
			layers: [
				this.mapLayer,
				this.leerungLayer
			]
		});
		this.mapLeerungenListe.on('pointermove', event => {
			this.displayTooltip(event);
		});
		this.mapLeerungenListe.on('moveend', () => {
			if (this.mapLeerungenListe.getView().getZoom() !== this.zoom) {
				this.zoom = this.mapLeerungenListe.getView().getZoom();
				const scale = 1.5 / (1.0 + this.mapLeerungenListe.getView().getMaxZoom() - this.mapLeerungenListe.getView().getZoom());
				this.leerungLayer.getSource().getFeatures().forEach(feature => {
					feature.getStyle().getImage().setScale(scale);
				});
				this.leerungLayer.getSource().changed();
			}
		});
		this.mapLeerungenListe.on('click', (event) => {
			this.mapLeerungenListe.forEachFeatureAtPixel(
				event.pixel,
				(feature) => {
					this.entries.forEach(c => {
						if (c.f_barcode === feature.get('name').split(' - ')[1]) {
							this.highlightCollection(c, feature);
							return true;
						}
					});
				},
				{
					hitTolerance: 5,
					layerFilter: (layer) => {
						return layer === this.leerungLayer;
					}
				}
			);
		});
	}

	updateCollections(shownCollections: Collection[]): void {
		let newCenter;
		this.leerungLayer.getSource().clear();

		const iconScale = 1.5 / (1.0 + this.mapLeerungenListe.getView().getMaxZoom() - this.mapLeerungenListe.getView().getZoom());

		shownCollections.forEach(c => {
			if (c.f_latitude !== 0 || c.f_longitude !== 0) {
				newCenter = [c.f_longitude / 6000000, c.f_latitude / 6000000];
				const feature = new Feature({
					geometry: new Point([c.f_longitude / 6000000, c.f_latitude / 6000000]), // 6000000 ist der Umrechnungsfaktor zur Datenbank, keine Ahnung wieso
					name: c.f_zeitpunkt.toLocaleDateString('de-DE') + ' ' + c.f_zeitpunkt.toLocaleTimeString('de-DE') + (c.f_barcode ? ' - ' + c.f_barcode : '')
				})
				feature.setStyle(
					new Style({
						image: new Icon({
							src: 'assets/icons/trash-green.png',
							scale: iconScale
						})
					})
				);
				this.leerungLayer.getSource().addFeature(feature);
			}
		});

		if (newCenter) {
			this.mapLeerungenListe.getView().animate({
				center: newCenter,
				zoom: this.mapLeerungenListe.getView().getZoom() + 5.5,
				duration: 1000
			})
		}
	}

	// #endregion Map
}
