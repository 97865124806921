<p-dialog
    header="{{'TWOFACTOR.CODE_TITLE'|translate}}"
    [modal]="true"
    [(visible)]="twoFactorCheckPopUpShown"
    [style]="{ width: '25rem' }"
    (onHide)="onDialogHide()"
    >
    <p style="width: 100%; text-align: center;"><span class="p-text-secondary block mb-8">{{'TWOFACTOR.CODE_INFO'|translate}}</span></p>
	<div class="card">
		<input
			id="twoFactorCodeInput"
			name="twoFactorAuthCode"
			type="text" pInputText
			style="text-align: center; font-family: monospace; font-size: 300%; width: 100%"
			maxlength="6"
			[(ngModel)]="twoFactorAuthCode"/>
	</div>
	<button style="width: 100%" type="button" pButton pRipple
		icon="fa fa-paper-plane"
		class="p-button-secondary"
		(click)="validateTwoFactorCode()"></button>
</p-dialog>