import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { Timespans } from '@app/models/date';
import { Module, Operation } from '@app/models/permission';
import { tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import { AccountService } from '@app/services/account.service';
import { DateService } from '@app/services/date.service';
import { ExportService } from '@app/services/export.service';
import { MapService } from '@app/services/map.service';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';

@Component({
	templateUrl: './collection_filter_dialog.component.html',
	styleUrls: ['../style.scss', '../../../../components/app.component.scss'],
	providers: [MessageService, ConfirmationService]
})
export class OnlineCollectionsFilterDialogComponent {
	loading = 0;
	isMobile: boolean = false;
	readPermission: boolean = false;

	// options
	@ViewChild('colselection') colselection: MultiSelect;
	@ViewChild('vehiclesTable') vehiclesTable: Table;
	@ViewChild('emptyCanvas', { static: false }) emptyCanvas: ElementRef;
	contextMenu: MenuItem[]; // Kontextmenu der Tabelle
	selectedVehicle: tbl_fahrzeug;
	eventTypes = [];
	onlineStatusTypes: any;
	onlineStatusTypeOptions: any[];
	statusTypes = [];
	statusApiUrl: string = '';
	timespans = [];
	vehicles: tbl_fahrzeug[];
	vehiclesApiUrl: string = '';
	vehiclesTableCols: any[] = [];
	vehiclesTableCount: number = 0;
	vehiclesTableFilters = [];
	vehiclesTableGlobalFilter: string = '';
	vehiclesTableIsInit: boolean = false;
	vehiclesTableName: string = '';
	vehiclesTablePossibleCols: any[] = [];
	vehiclesTableState: any;
	vehiclesTableStateName: string = '';

	// selection
	fromDate = new Date();
	selectedEventTypes = [];
	selectedStatus = [];
	selectedTimespan = 0;
	selectedVehicles = [];
	toDate = new Date();

	constructor(
		public accountService: AccountService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public config: DynamicDialogConfig,
		public crudService: CRUDService,
		public dateService: DateService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public mapService: MapService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
	) {
	}

	// #region Angular

	ngOnInit() {
		this.statusApiUrl = 'TblLeerungBarcodeStatus';

		this.vehiclesApiUrl = 'TblFahrzeug';
		this.vehiclesTableName = 'ONLINE.VEHICLES';
		this.vehiclesTableStateName = 'stateOnlineLeerungFilter' + this.vehiclesApiUrl + 'List';

		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);

		if (this.readPermission) {
			this.loadOptions();
		}

		this.vehiclesTablePossibleCols = [
			{ type: 'text', key: 'ankey', required: true, width: 150 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 150 },
			{ type: 'text', key: 'kennzeichen', required: true, width: 150 },
			{ type: 'text', key: 'logbox_serial', required: true, width: 150 },
			{ type: 'text', key: 'FREMD_firma_bezeichnung', required: true, width: 200 },
			{ type: 'online', key: 'onlineStatus', required: true, width: 150 }
		];
		this.vehiclesTableCols = [
			{ type: 'online', key: 'onlineStatus', required: true, width: 150 },
			{ type: 'text', key: 'kennzeichen', required: true, width: 150 },
			{ type: 'text', key: 'logbox_serial', required: true, width: 150 }
		];
		this.retrieveTableState(this.vehiclesTableState);
		if (this.vehiclesTableState && this.vehiclesTableState.columnOrder) {
			this.vehiclesTableCols = [];
			this.vehiclesTableState.columnOrder.forEach(col => {
				this.vehiclesTablePossibleCols.forEach(c => {
					if (col == c.key) {
						this.vehiclesTableCols.push(c);
					}
				});
			});
		}
		if (this.vehiclesTableState && this.vehiclesTableState.filters && this.vehiclesTableState.filters.global) {
			this.vehiclesTableGlobalFilter = this.vehiclesTableState.filters.global.value;
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.onlineStatusTypeOptions = [
			{ color: 'green', value: 1 },
			{ color: 'orange', value: 0 },
			{ color: 'crimson', value: -1 },
			{ color: '', value: null },
		];

		this.onlineStatusTypes =
		{
			0: 'crimson',
			1: 'orange',
			2: 'green',
		};

		this.selectedEventTypes = [
			{ name: 'Leerungen', value: '5' }
		];

		this.translate.get('init').subscribe((text: string) => {
			this.vehiclesTablePossibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.initContextMenu();
		this.vehiclesTableFilters = this.vehiclesTableCols.map(c => c.key);
		this.resizeTableWidth();
	}

	handleContextMenuSelect(event) {
		// das ist dann die mit rechts angeklickte datarow (hat nichts mit der selektion aus dem multiselect zu tun)
		this.selectedVehicle = event.data;
	}

	handleRowSelection(event) {
		// und das hier ist die gerade neu selektierte zeile aus dem multiselect
		// unbenutzt
	}

	initContextMenu(): void {
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				//{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				//{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				//{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				//{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				//{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				//{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				//{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				//{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) }
			];
			this.vehiclesTablePossibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	ngAfterViewInit() {
		if (this.vehiclesTable.filters) {
			let restoredFilter = false;
			this.vehiclesTableFilters.forEach(col => {
				Object.keys(this.vehiclesTable.filters[col]).forEach(filter => {
					if (this.vehiclesTable.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const tableViewports = document.querySelectorAll<HTMLElement>('.cdk-virtual-scroll-viewport');
		tableViewports.forEach(el => {
			this.changeWheelSpeed(el, 0.9);
		});
		setTimeout(() => {
			this.resizeTableWidth();
		}, 0);
	}

	ngAfterViewChecked() {
		if (!this.vehiclesTableIsInit && this.vehiclesTable.value) {
			this.vehiclesTableIsInit = true;
			this.resizeTableWidth();
			this.changeDetectorRef.detectChanges();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		setTimeout(() => {
			this.resizeTableWidth();
		}, 0);
	}

	// #endregion Angular

	// #region Spezial

	convertTimespanSelection(event) {
		const timespan = this.dateService.getTimespan(event.value);
		if (timespan != null) {
			this.fromDate = timespan.fromDate;
			this.toDate = timespan.toDate;
		}
	}

	inputDate(dateField, input: string) {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			if (dateField == 'fromDate') {
				this.fromDate = date;
			} else if (dateField == 'toDate') {
				this.toDate = date;
			}
			this.selectedTimespan = -1;
		}
	}

	load() {
		this.loading += 1;
		if (this.accountService.checkPermissions(Module.Masterdata, Operation.READ)) {
			let logboxIds = '';
			this.selectedVehicles.forEach(v => {
				if (logboxIds.length > 0) {
					logboxIds += ',';
				}
				logboxIds += v.logbox_serial;
			});
			let selectedEvents = '';
			this.selectedEventTypes.forEach(e => {
				if (selectedEvents.length > 0) {
					selectedEvents += ',';
				}
				selectedEvents += e.value;
			});
			let selectedStates = '';
			this.selectedStatus.forEach(e => {
				if (selectedStates.length > 0) {
					selectedStates += ',';
				}
				selectedStates += e.value;
			});

			this.fromDate.setHours(0, 0, 0, 0);
			this.toDate.setHours(23, 59, 59, 999);

			this.ref.close({ seSignatures: logboxIds, dateFrom: this.fromDate, dateTo: this.toDate, exportType: 1, eventTypes: selectedEvents, status: selectedStates });
		} else {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.ACCESS_DENIED'), detail: this.translate.instant('ERRORS.ACCESS_DENIED_MSG'), life: 3000 });
		}
		this.loading -= 1;
	}

	loadOptions() {
		// tbl_fahrzeug
		this.loading += 1;
		this.crudService.getAllEntries(this.vehiclesApiUrl).then(res => {
			this.vehicles = res;
			this.updateLastGPS();
			this.vehiclesTableCount = this.vehicles.length;
			this.vehiclesTablePossibleCols.forEach(c => {
				if (c.type == 'date') {
					this.vehicles.forEach(e => {
						if (e[c.key] != null) {
							e[c.key] = new Date(e[c.key]);
						}
					});
				}
			});
			this.resizeTableWidth();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});

		// tbl_leerung_barcode_status
		this.loading += 1;
		this.crudService.getAllEntries(this.statusApiUrl).then(res => {
			res.forEach(status => {
				this.statusTypes.push({ name: status.bezeichnung, value: status.nkey });
			});
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.translate.get('init').subscribe((text: string) => {
			// Timespans
			Object.values(Timespans).filter((v) => isNaN(Number(v))).forEach((key, index) => {
				this.timespans.push(
					{ label: this.translate.instant('TIMESPANS.' + key), value: index }
				);
			});

			// Events
			this.eventTypes = [
				{ name: 'Leerungen', value: '5' },
				{ name: 'Anmeldungen', value: '1' },
				{ name: 'Abmeldungen', value: '2' },
			];
		});
	}

	updateLastGPS() {
		this.loading += 1;
		this.vehicles.forEach(vehicle => {
			vehicle['LastUpdate'] = null;
			vehicle['onlineStatus'] = null;
		});
		let logboxIds = this.vehicles.map(a => a.logbox_serial);
		this.mapService.getLastGPS(logboxIds).then(res => {
			if (res) {
				res.forEach(dataset => {
					for (let vehicle of this.vehicles) {
						if (vehicle.logbox_serial == dataset.f_system_id) {
							vehicle['LastGPS'] = [dataset['Map_CenterLongitude'], dataset['Map_CenterLatitude']];
							let lastUpdate = new Date(dataset['f_jdzeit_String']);
							vehicle['LastUpdate'] = lastUpdate.toLocaleString();
							const now = new Date();
							vehicle['onlineStatus'] = -1;
							if (lastUpdate.getFullYear() == now.getFullYear() &&
								lastUpdate.getMonth() == now.getMonth() &&
								lastUpdate.getDate() == now.getDate()) {
								vehicle['onlineStatus'] = 0;
								const tolerance = new Date();
								tolerance.setMinutes(tolerance.getMinutes() - 20);
								if (lastUpdate > tolerance) {
									vehicle['onlineStatus'] = 1;
								}
							}
							break;
						}
					}
				});
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion Spezial

	// #region Table

	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	isColFiltered(col) {
		let isFiltered = false;
		if (this.vehiclesTable && this.vehiclesTable.filters && this.vehiclesTable.filters[col]) {
			Object.keys(this.vehiclesTable.filters[col]).forEach(filter => {
				if (this.vehiclesTable.filters[col][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.vehiclesTableState && this.vehiclesTableState.filters && this.vehiclesTableState.filters[col]) {
			Object.keys(this.vehiclesTableState.filters[col]).forEach(filter => {
				if (this.vehiclesTableState.filters[col][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event) {
		this.vehiclesTableCount = this.vehiclesTable.filteredValue ? this.vehiclesTable.filteredValue.length : this.vehicles.length;
	}

	onColReorder(event) {
		this.retrieveTableState(this.vehiclesTableState);
		const columnWidths = this.vehiclesTableState.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.vehiclesTableState.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.vehiclesTableStateName, JSON.stringify(this.vehiclesTableState));
	}

	onColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element) - 1;
		this.retrieveTableState(this.vehiclesTableState);
		this.vehiclesTableCols[index].width = Number(event.element.style.width.split('px')[0]);
		this.vehiclesTableState.columnWidths = ([50].concat(this.vehiclesTableCols.map(c => c.width))).join(',');
		localStorage.setItem(this.vehiclesTableStateName, JSON.stringify(this.vehiclesTableState));

		this.resizeTableWidth(this.vehiclesTableState);
	}

	resetTable() {
		this.vehiclesTable.clearState();
		window.location.reload();
	}

	resizeTableWidth(state?) {
		this.loading += 1;

		this.retrieveTableState(this.vehiclesTableState);
		if (this.vehiclesTable) {
			const tableElement = document.getElementById(this.vehiclesTable.id);
			tableElement.style.width = '100%';
			const columnWidths = this.vehiclesTableState && this.vehiclesTableState.columnWidths ? this.vehiclesTableState.columnWidths.split(',') : [50].concat(this.vehiclesTableCols.map(c => c.width));
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.vehiclesTableCols.length; index++) {
				this.vehiclesTableCols[index].width = Number(columnWidths[index + 1]);
			}
			if (tableWidthOffset > 0 && this.vehiclesTableCols.length > 0) {
				this.vehiclesTableCols[this.vehiclesTableCols.length - 1].width += tableWidthOffset;
			}

			document.getElementById(this.vehiclesTable.id + '-table').style.width = this.vehiclesTableCols.reduce((summe, element) => summe + element.width, 0) + 50 + 'px';
			document.getElementById(this.vehiclesTable.id + '-table').style.minWidth = this.vehiclesTableCols.reduce((summe, element) => summe + element.width, 0) + 50 + 'px';

			setTimeout(() => {
				if (this.vehiclesTableState) {
					localStorage.setItem(this.vehiclesTableStateName, JSON.stringify(this.vehiclesTableState));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(state?) {
		this.vehiclesTableState = state ? state : JSON.parse(localStorage.getItem(this.vehiclesTableStateName));
		if (this.vehiclesTable && (this.vehiclesTableState == undefined)) {
			// for storage of table state
			this.vehiclesTable.saveState();
			// reload and parse
			this.vehiclesTableState = JSON.parse(localStorage.getItem(this.vehiclesTableStateName));
		}
	}

	toggleColumn(event) {
		this.retrieveTableState(this.vehiclesTableState);
		this.vehiclesTableState.columnOrder = event.value.map(c => c.key);
		this.vehiclesTableState.columnWidths = [50].concat(event.value.map(c => c.width));
		this.vehiclesTableState.columnWidths = this.vehiclesTableState.columnWidths.join(',');
		this.vehiclesTableState.tableWidth = (this.vehiclesTableState.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.vehiclesTableFilters = event.value.map(c => c.key);
		localStorage.setItem(this.vehiclesTableStateName, JSON.stringify(this.vehiclesTableState));
		this.resizeTableWidth(this.vehiclesTableState);
	}

	/**
	 * Liefert die Länge der Darstellung eines Textes in Pixeln zurück
	 * 
	 * @param text Text, der überprüft werden soll
	 * @param styleFont Font, in der der Text geschrieben ist
	 * @returns Textbreite in Pixeln
	 */
	getTextLength(text: string, styleFont: string): number {
		const ctx = this.emptyCanvas.nativeElement.getContext('2d');
		ctx.font = styleFont;
		const textMetrics = ctx.measureText(text);
		return Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
	}

	/**
	 * wenn eine spalte noch ein special symbol im header hat
	 * 
	 * @param maxStringLength 
	 * @param columnkey 
	 * @returns 
	 */
	adaptColumnSize(maxStringLength, columnkey): number {
		return maxStringLength;
	}

	/**
	 * berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	 * 
	 * @param bForce 
	 * @param state 
	 */
	resizeTableWidthFromContent(bForce, state?): void {
		var bResize = bForce;

		this.loading += 1;

		// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
		this.vehiclesTableState = state ? state : JSON.parse(localStorage.getItem(this.vehiclesTableStateName));
		if (this.vehiclesTableState == undefined) {
			// force storage of table state
			this.vehiclesTable.saveState();
			// reload state
			this.vehiclesTableState = JSON.parse(localStorage.getItem(this.vehiclesTableStateName));
			bResize = true;
		}

		if (this.vehiclesTable && bResize) {
			// autosize columns
			const lTable = document.getElementById(this.vehiclesTable.id);
			var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
			// für alle spalten, alle daten            
			this.vehiclesTableCols.forEach(col => {
				let columnname = this.translate.instant('HEADERS.' + col.key);
				let maxStringLength = this.getTextLength(columnname, lTableFont);
				// filter symbol
				maxStringLength = maxStringLength + 80;
				maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
				if (this.vehicles) {
					this.vehicles.forEach(row => {
						try {
							let newLength = 0;
							if (col.type == 'date') {
								if (row[col.key] != undefined)
									newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
								else
									newLength = 0;
							} else {
								newLength = this.getTextLength(row[col.key], lTableFont);
							}
							// margins zur zelle
							newLength = newLength + 26;
							if (newLength > maxStringLength)
								maxStringLength = newLength;
						} catch(err) {
							//console.log(err);
						};
					})
				}

				col.width = maxStringLength;
			});

			// crashes here...
			//this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		}

		// standard funktion aufrufen
		//this.resizeTableWidth(this.vehiclesTableState);

		this.loading -= 1;
	}
	// #endregion Table
}
