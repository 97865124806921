import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals, VIONSessionStorage } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { colors, colorsHex } from '@app/models/tbl_fahrtbericht_abschnitt_typ';
import { tbl_gps } from '@app/models/tbl_gps';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { defaults as defaultControls, FullScreen, ScaleLine, ZoomSlider } from 'ol/control';
import { LineString, Point } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Icon, Stroke, Style } from 'ol/style';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Splitter } from 'primeng/splitter';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblFahrtberichtDetailComponent extends ListComponent implements OnInit {
	tableFBLeerungenRedrawEntprell = true;
	basefields: any[];
	entry;
	fieldgroups: any[];
	id: number;
	loading = 0;
	url = '/' + PATH.FAHRTEBRICHT;
	sections;
	geoData: tbl_gps[];
	locale = 'de';

	activeTab: any;
	initialTabWidth: string = '10px';
	leerungLayer: VectorLayer;
	leerungStyle: Style;
	routeBorderLayer: VectorLayer;
	routeLayer: VectorLayer;
	routeStartStyle: Style;
	routeEndStyle: Style;
	routeTracklineStyles: Style[];
	routeBorderStyle: Style;
	routeArrowLayer: VectorLayer;
	lastArrow = [];

	contentHeight: number = 800;
	contentHeightEx: string = '800px';
	highlightedCollection = null;
	map: Map;
	mapLayer: TileLayer;
	tooltip: string = '';
	coord = [10.447683, 51.163361];
	zoom = 6.5;

	fieldsAllgemein_Bericht: any[];
	fieldsAllgemein_Kennzahlen: any[];
	fieldsAllgemein_Tourdaten_Group1: any[];
	fieldsAllgemein_Tourdaten_Group2: any[];
	buttonColWidth: number = 110;
	defaultColWidth = 120;
	colWidthAddition_s = 10;
	colWidthAddition_m = 30;
	colWidthAddition_l = 50;
	colWidthAddition_xl = 80;
	isTableFBLeerungenInit: boolean = false;
	splitterFBDetailLayout: string = 'horizontal';

	//	@ViewChild('tableFBLeerungen') tableFBLeerungen: Table;
	@ViewChild('splitterFBDetail') splitterFBDetail: Splitter;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public datepipe: DatePipe
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];
		this.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : this.locale;
		this.stateName = 'stateFBLeerungenList';

		this.breadcrumbService.setItems([
			{ label: 'MENU.BERICHTE' },
			{ label: 'MENU.FAHRTBERICHTE', routerLink: [this.url + '/list'] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/detail/' + this.id] }
		]);

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: this.defaultColWidth },
			{ type: 'text', key: 'ankey', required: true, width: this.defaultColWidth },
			{ type: 'date', key: 'f_zeitpunkt', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'f_barcode', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'f_status', required: false, width: this.defaultColWidth },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_LRG', required: false, width: this.defaultColWidth },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_ABR', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_strasse_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_ort_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_eigentuemer_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_entsorger_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_gefaess_barcode', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_volumen_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_tour_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_fahrzeug_kennzeichen', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_fahrzeug_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_objekt_ankey', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_gebiet_bezeichnung', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'geo_hausnummer', required: false, width: this.defaultColWidth },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: this.defaultColWidth },
			{ type: 'date', key: 'f_zeitpunkt', required: false, width: this.defaultColWidth + this.colWidthAddition_m },
			{ type: 'text', key: 'f_barcode', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'f_status', required: false, width: this.defaultColWidth },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_LRG', required: false, width: this.defaultColWidth },
			{ type: 'boolean', key: 'VIRTUAL_flagresult_ABR', required: false, width: this.defaultColWidth },
			{ type: 'text', key: 'FREMD_strasse_bezeichnung', required: false, width: this.defaultColWidth + this.colWidthAddition_xl },
			{ type: 'text', key: 'FREMD_ort_bezeichnung', required: false, width: this.defaultColWidth + this.colWidthAddition_s },
			{ type: 'text', key: 'FREMD_eigentuemer_bezeichnung', required: false, width: this.defaultColWidth + this.colWidthAddition_xl },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: false, width: this.defaultColWidth + this.colWidthAddition_s },
			{ type: 'text', key: 'FREMD_volumen_bezeichnung', required: false, width: this.defaultColWidth + this.colWidthAddition_s },
			{ type: 'text', key: 'geo_hausnummer', required: false, width: this.defaultColWidth + this.colWidthAddition_m },
		];

		this.fieldsAllgemein_Bericht = [
			{ type: 'string', key: 'ankey', label: 'Schlüssel' },
			{ type: 'string', key: 'anfang_zeit_string', label: 'Anfang' },
			{ type: 'string', key: 'ende_zeit_string', label: 'Ende' },
		];
		this.fieldsAllgemein_Kennzahlen = [
			{ type: 'string', key: 'ende_anzahl_2rad', label: '2Rad' },
			{ type: 'string', key: 'ende_anzahl_4rad', label: '4Rad' },
		];
		this.fieldsAllgemein_Tourdaten_Group1 = [
			{ type: 'link', key: 'FREMD_tour_bezeichnung', label: 'Tour', link: '#/' + PATH.TOUR + '/' + PATH.DETAIL + '/', link_id: 'tour_id' },
			{ type: 'link', key: 'FREMD_abfallart_bezeichnung', label: 'Abfallart', link: '#/' + PATH.ABFALLART + '/' + PATH.DETAIL + '/', link_id: 'abfallart_id' },
			{ type: 'string', key: 'FREMD_typ_bezeichnung', label: 'Typ', link: '', link_id: 'typ_id' },
			{ type: 'link', key: 'FREMD_entsorger_bezeichnung', label: 'Entsorger', link: '#/' + PATH.FIRMA + '/' + PATH.DETAIL + '/', link_id: 'entsorger_id' },
		];
		this.fieldsAllgemein_Tourdaten_Group2 = [
			{ type: 'link', key: 'FREMD_fahrzeug_bezeichnung', label: 'Fahrzeug', link: '#/' + PATH.FAHRZEUG + '/' + PATH.DETAIL + '/', link_id: 'fahrzeug_id' },
			{ type: 'link', key: 'FREMD_fahrer_bezeichnung', label: 'Fahrer', link: '#/' + PATH.MITARBEITER + '/' + PATH.DETAIL + '/', link_id: 'mitarbeiter_id' },
			{ type: 'link', key: 'FREMD_lader1_bezeichnung', label: 'Lader1', link: '#/' + PATH.MITARBEITER + '/' + PATH.DETAIL + '/', link_id: 'mitarbeiter_id' },
			{ type: 'link', key: 'FREMD_lader2_bezeichnung', label: 'Lader2', link: '#/' + PATH.MITARBEITER + '/' + PATH.DETAIL + '/', link_id: 'mitarbeiter_id' },
		];
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.get();

		this.translate.get('init').subscribe((text: string) => {
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	initContextMenu(): void {
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				//{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				//{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				//{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				//{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				//{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				//{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				//{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				//{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event): void {
		this.initSizing();
		setTimeout(() => {
			if (this.splitterFBDetailLayout != 'vertical ') {
				document.getElementById('mapFBdetail').style.width = '100%';
				document.getElementById('table').style.width = '100%';
			}
		}, 0);
	}

	handleFBSplitterChange(event): void {
		if (this.splitterFBDetailLayout == 'vertical ') {
			document.getElementById('mapLeerungenListe').style.width = 'auto';
			document.getElementById('table').style.width = 'auto';
		} else {
			document.getElementById('mapLeerungenListe').style.width = '100%';
			document.getElementById('table').style.width = '100%';
		}

		this.initSizing();
		setTimeout(() => {
			this.tableFBLeerungenRedrawEntprell = false;
		}, 0);
		setTimeout(() => {
			this.tableFBLeerungenRedrawEntprell = true;
		}, 10);
		this.resizeTableWidth();
	}

	handleFBTabChange(event): void {
		this.initSizing();
		setTimeout(() => {
			this.tableFBLeerungenRedrawEntprell = false;
		}, 0);
		setTimeout(() => {
			this.tableFBLeerungenRedrawEntprell = true;
			document.getElementById('table').style.width = this.initialTabWidth;
			this.changeDetectorRef.detectChanges();
		}, 10);
		this.resizeTableWidth();

		setTimeout(() => {
			document.getElementById('table').style.width = 'auto';
		}, 250);
	}

	ngAfterViewInit(): void {
		this.initialTabWidth = 0.01 * this.splitterFBDetail._panelSizes[1] * this.splitterFBDetail.containerViewChild.nativeElement.clientWidth - this.splitterFBDetail.gutterSize + 'px';
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initSizing();
		});

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this.initSizing();
		this.initMap();
		//this.initMapData(); // wird beim Laden angestoßen, weil hier die Daten noch nicht verfügbar sind
		setTimeout(() => {
			this.initSizing();
		}, 125);
	}

	ngAfterViewChecked(): void {
		if (!this.isTableFBLeerungenInit && this.table.value) {
			this.isTableFBLeerungenInit = true;
			this.resizeTableWidth();
			this.changeDetectorRef.detectChanges();
		}
	}

	changeWheelSpeed(container, speedY): any {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	delete(): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteTripReport(this.entry.ds_this_id).then(res => {
					let sectionids: number[] = this.sections.map(x => x.ds_this_id);
					this.crudService.deleteTripReportSections(sectionids).then(res => {

					});

					// hier eintrag aus gepufferter Liste löschen
					let storage = VIONSessionStorage.getInstance().get(this.apiUrl);
					if (null != storage && undefined != storage) {
						const storedTimestamp = new Date(storage.timestamp);
						if (null != storage.entries && undefined != storage.entries) {
							let index = 0;
							let foundElement = false;
							for (let i = 0; i < storage.entries.length; ++i) {
								if (storage.entries[i]['ds_this_id'] === this.entry.ds_this_id) {
									foundElement = true;
									break;
								}
								index += 1;
							}
							if (foundElement && index < storage.entries.length) {
								storage.entries.splice(index, 1);
								VIONSessionStorage.getInstance().set(this.apiUrl, storedTimestamp, storage.filters, storage.entries);
							}
						}
					}

					this.router.navigate([this.url + '/list']);
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	edit(): void {
		this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.id]);
	}

	get(): void {
		this.sections = [];
		this.entries = [];
		this.geoData = [];
		this.loading += 1;
		this.crudService.getTripReport(this.id).then(res => {
			this.entry = res;

			// "sub-load": Abschnitte
			this.loading += 1;
			this.crudService.getFilteredTripReportSections(this.id).then(res => {
				this.sections = res;
				this.datesToStrings();
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
			// "sub-load" Abschnitte: done

			// "sub-load": Leerungen
			this.loading += 1;
			this.crudService.getFilteredCollections({
				Logbox_id: null,
				Client_id: null,
				Area_id: null,
				Fraction_id: null,
				Volume_id: null,
				Status: null,
				Barcode: '',
				DateFrom: null,
				DateTo: null,
				bericht_id: this.id,			// wie in Atos
				abfrage_nur_relevante: true,	// wie in Atos
				abfrage_datentypauswahl: 0,		// wie in Atos
				Count: 100000, // 100.000 sollten für einen ernst zu nehmenden Fahrtbericht deutlich ausreichend sein als Limit ;)
			}).then(res => {
				this.entries = res;
				if (null !== this.map && undefined !== this.map) {
					this.addCollectionsToMap();
				}
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
			// "sub-load" Leerungen: done

			// "sub-load": Geodata
			this.loading += 1;
			this.crudService.getFilteredGps({
				bericht_id: this.id			// wie in Atos
			}).then(res => {
				this.geoData = res;
				//console.log('added ' + this.geoData.length + ' gps points');
				if (null !== this.map && undefined !== this.map) {
					this.addGpsTrackToMap();
				}
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
			// "sub-load" Geodata: done
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	initMapData(): void {
		//  Route und Leerungen in Karte einfügen
		this.addCollectionsToMap();
		this.addGpsTrackToMap();
		this.rescaleMapLayers();
	}

	addCollectionsToMap(): void {
		// fügt die geladenen Leerungen in den LeerungsLayer der Karte ein
		let featCounter = 0;
		let newCenter;
		this.leerungLayer.getSource().clear();

		const iconScale = 1.5 / (1.0 + this.map.getView().getMaxZoom() - this.map.getView().getZoom());
		this.entries.forEach(c => {
			//if (c.f_latitude !== 0 || c.f_longitude !== 0) {
			//	newCenter = [c.f_longitude / 6000000, c.f_latitude / 6000000];
			//	c.f_zeitpunkt = new Date(c.f_zeitpunkt);
			//	const feature = new Feature({
			//		geometry: new Point([c.f_longitude / 6000000, c.f_latitude / 6000000]), // 6000000 ist der Umrechnungsfaktor zur Datenbank, keine Ahnung wieso
			//		name: c.f_zeitpunkt.toLocaleDateString('de-DE') + ' ' + c.f_zeitpunkt.toLocaleTimeString('de-DE') + (c.f_barcode ? ' - ' + c.f_barcode : '')
			//	})
			//	feature.setStyle(
			//		new Style({
			//			image: new Icon({
			//				src: 'assets/icons/trash-green.png',
			//				scale: iconScale
			//			})
			//		})
			//	);
			//	this.leerungLayer.getSource().addFeature(feature);
			//	++featCounter;
			//}
			if (c['f_latitude'] !== 0 || c['f_longitude'] !== 0) {
				newCenter = [c['f_longitude'] / 6000000, c['f_latitude'] / 6000000];
				c['f_zeitpunkt'] = new Date(c['f_zeitpunkt']);
				const feature = new Feature({
					geometry: new Point([c['f_longitude'] / 6000000, c['f_latitude'] / 6000000]), // 6000000 ist der Umrechnungsfaktor zur Datenbank, keine Ahnung wieso
					name: c['f_zeitpunkt'].toLocaleDateString('de-DE') + ' ' + c['f_zeitpunkt'].toLocaleTimeString('de-DE') + (c['f_barcode'] ? ' - ' + c['f_barcode'] : '')
				})
				feature.setStyle(
					new Style({
						image: new Icon({
							src: 'assets/icons/trash-green.png',
							scale: iconScale
						})
					})
				);
				this.leerungLayer.getSource().addFeature(feature);
				++featCounter;
			}
		});

		this.count = this.entries.length;

		if (newCenter) {
			this.map.getView().animate({
				center: newCenter,
				zoom: this.map.getView().getZoom() + 5.5,
				duration: 1000
			})
		}
		//console.log('added collections (features) to map: ' + featCounter);
	}

	addGpsTrackToMap(): void {
		//let routeFeatures = [];
		//let arrowFeatures = [];
		this.lastArrow = [0, 0];
		let startPointSet = false;
		let dataset: tbl_gps = null;
		for (var idx = 0; idx < this.geoData.length; ++idx) {
			if (this.geoData[idx].converted_f_latitude !== null && this.geoData[idx].converted_f_longitude !== null) {
				const old_dataset: tbl_gps = dataset;
				dataset = this.geoData[idx];
				let datetime = new Date(dataset.f_jdzeit_String);
				if (dataset) {
					if (!startPointSet) {
						// Draw Start
						const routeStartFeature = new Feature({
							geometry: new Point(
								[
									dataset.converted_f_longitude, dataset.converted_f_latitude
								]
							),
							name: 'Start - ' + datetime.toLocaleString()
						});
						routeStartFeature.setStyle(this.routeStartStyle);
						this.routeLayer.getSource().addFeature(routeStartFeature);
						startPointSet = true;
					} else {
						if (old_dataset !== null && old_dataset !== undefined && (old_dataset.converted_f_latitude !== dataset.converted_f_latitude || old_dataset.converted_f_longitude !== dataset.converted_f_longitude)) {
							// Track-Line
							const routeFeature = new Feature({
								geometry: new LineString(
									[
										[dataset.converted_f_longitude, dataset.converted_f_latitude],
										[old_dataset.converted_f_longitude, old_dataset.converted_f_latitude]
									]
								),
								name: datetime.toLocaleString()
							});
							const routeBorderFeature = new Feature({
								geometry: new LineString(
									[
										[dataset.converted_f_longitude, dataset.converted_f_latitude],
										[old_dataset.converted_f_longitude, old_dataset.converted_f_latitude]
									]
								),
								name: datetime.toLocaleString()
							});
							routeBorderFeature.setStyle(this.routeBorderStyle);
							if (null !== dataset.abschnitttyp_id && undefined !== dataset.abschnitttyp_id && dataset.abschnitttyp_id >= 0 && dataset.abschnitttyp_id <= 15) {
								routeFeature.setStyle(this.routeTracklineStyles[dataset.abschnitttyp_id]);
							}
							this.routeBorderLayer.getSource().addFeature(routeBorderFeature);
							this.routeLayer.getSource().addFeature(routeFeature);

							// Direction-Arrow
							if (dataset.f_heading !== 0 && !this.isNearOldArrow([dataset.converted_f_longitude, dataset.converted_f_latitude])) {
								const deltaLong = dataset.converted_f_longitude - this.lastArrow[0];
								const deltaLat = dataset.converted_f_latitude - this.lastArrow[1];
								const angle = Math.atan2(deltaLong, deltaLat);
								this.lastArrow = [dataset.converted_f_longitude, dataset.converted_f_latitude];
								const arrowFeature = new Feature({
									geometry: new Point(
										this.lastArrow
									),
									name: 'Fahrtrichtung'
								});
								arrowFeature.setStyle(
									new Style({
										image: new Icon({
											src: 'assets/icons/arrow.svg',
											scale: 0.75,
											rotation: angle
										})
									})
								);
								this.routeArrowLayer.getSource().addFeature(arrowFeature);
							}

							if (idx === (this.geoData.length - 1)) {
								// Draw Start
								const routeEndFeature = new Feature({
									geometry: new Point(
										[
											dataset.converted_f_longitude, dataset.converted_f_latitude
										]
									),
									name: 'Ende - ' + datetime.toLocaleString()
								});
								routeEndFeature.setStyle(this.routeStartStyle);
								this.routeLayer.getSource().addFeature(routeEndFeature);
							}
						}
					}
				}
			}
		}
	}

	isNearOldArrow(coords: number[]): boolean {
		const distance = 0.001;
		const isOutsideBoundingBox = (
			this.lastArrow[0] - distance > coords[0] ||
			this.lastArrow[0] + distance < coords[0] ||
			this.lastArrow[1] - distance > coords[1] ||
			this.lastArrow[1] + distance < coords[1]
		);
		return !isOutsideBoundingBox;
	}

	datesToStrings(): void {
		if (this.entry.anfang_zeit) {
			const start_date = new Date(this.entry.anfang_zeit);
			this.entry.anfang_zeit_string = start_date.toLocaleString();
		}
		if (this.entry.ende_zeit) {
			const end_date = new Date(this.entry.ende_zeit);
			this.entry.ende_zeit_string = end_date.toLocaleString();
		}
		this.sections.forEach(e => {
			if (e.anfang_zeit) {
				e.anfang_zeit_string = formatDate(e.anfang_zeit, 'HH:mm', this.locale);
			}
			if (e.ende_zeit) {
				e.ende_zeit_string = formatDate(e.ende_zeit, 'HH:mm', this.locale);
			}
			if (e.zum_anfang_zeit) {
				e.zum_anfang_zeit_string = formatDate(e.zum_anfang_zeit, 'HH:mm', this.locale);
			}
			if (e.vom_ende_zeit) {
				e.vom_ende_zeit_string = formatDate(e.vom_ende_zeit, 'HH:mm', this.locale);
			}
		});
	}

	isDarkMode(): boolean {
		return (localStorage.getItem('darkMode') === 'true');
	}

	getBackgroundColor(typ_id): string {
		return ('rgba(' + colors[typ_id] + ',0.5)');
	}

	// #region Map
	adaptTabSizes(): void {
		setTimeout(() => {
			// mit Footer-Berechnung, so wäre es "theoretisch" richtig, aber in dieser View rutscht der Footer eigentlich immer unten raus und die Seite scrollt...
			//let calcHeight = 'calc(100vh - ' + ((localStorage.getItem('showFooter') === 'true') ? '90px' : '40px') + ' - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '21rem' : '17rem') + ')';

			// ...daher: ohne Footer-Berechnung mit fixem Abzug 40px führt hier zu dem besseren Ergebnis
			let calcHeight = 'calc(100vh - 75px - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '21rem' : '17rem') + ')';
			this.contentHeightEx = 'calc(100vh - 150px - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '21rem' : '17rem') + ')';

			//document.getElementById('mapFBdetail').style.width = 'auto';
			document.getElementById('mapFBdetail').style.height = calcHeight;
		}, 0);
		setTimeout(() => {
			this.map.updateSize();
		}, 0);
		//this.changeDetectorRef.detectChanges();
	}

	center(entry): void {
		this.activeTab = 0;
		const long = entry.f_longitude ? entry.f_longitude / 6000000 : 0.0;
		const lat = entry.f_latitude ? entry.f_latitude / 6000000 : 0.0;
		if (long != null && lat != null && (long != 0.0 || lat != 0.0) && long >= -180.0 && long <= 180.0 && lat >= -90.0 && lat <= 90.0) {
			setTimeout(() => {
				this.map.updateSize();
				this.map.getView().animate({ center: [long, lat], zoom: (this.map.getView().getZoom() < 14 ? 14 : this.map.getView().getZoom()) });
			}, 100);
		}
	}

	displayTooltip(event): void {
		const pixel = event.pixel;
		document.getElementById('tooltip').style.display = 'none';
		this.map.forEachFeatureAtPixel(pixel, feature => {
			this.tooltip = feature.get('name');
			document.getElementById('tooltip').style.display = 'inherit';
			document.getElementById('tooltip').style.top = (document.getElementById('mapFBdetail').getBoundingClientRect().top - document.body.getBoundingClientRect().top - 25 + event.pixel[1]) + 'px';
			document.getElementById('tooltip').style.left = (document.getElementById('mapFBdetail').getBoundingClientRect().left - document.body.getBoundingClientRect().left + 5 + event.pixel[0]) + 'px';
		});
	}

	initSizing(): void {
		setTimeout(() => {
			if (window.innerWidth < 1280) {
				this.splitterFBDetailLayout = 'vertical';
			} else {
				this.splitterFBDetailLayout = 'horizontal';
			}
		}, 0);
		setTimeout(() => {
			if (this.splitterFBDetailLayout == 'vertical ') {
				document.getElementById('mapFBdetail').style.width = '100%';
				document.getElementById('table').style.width = '100%';
			}
		}, 0);
		setTimeout(() => {
			this.contentHeight = 400;
			document.getElementById('mapFBdetail').style.height = this.contentHeight + 'px';
		}, 0);
		setTimeout(() => {
			this.map.updateSize();
		}, 0);
		setTimeout(() => {
			if (this.elRef && this.elRef.nativeElement && this.elRef.nativeElement.parentElement && this.elRef.nativeElement.parentElement.offsetHeight) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 210 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.contentHeight < 400) {
					this.contentHeight = 400;
				}
			}
		}, 0);
		this.adaptTabSizes();
	}

	initMap(): void {
		this.leerungStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({ color: 'red' }),
			})
		});
		this.mapLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: environment.mapUrl,
				format: 'image/png',
				crossOrigin: 'anonymous'
			})
		});
		this.leerungLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: this.leerungStyle,
			name: 'Leerungen'
		});
		this.routeBorderLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: [
				new Style({
					stroke: new Stroke({
						color: '#000000',
						width: 6
					})
				})
			],
			name: 'Route'
		});
		this.routeLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: [
				new Style({
					stroke: new Stroke({
						color: '#00FF00',
						width: 4
					})
				})
			],
			opacity: 0.9,
			name: 'Route'
		});
		this.routeArrowLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			minZoom: 15,
			opacity: 0.9
			//name: 'A-' + vehicle['mapId']
		});
		this.routeStartStyle = new Style({
			image: new Circle({
				radius: 5,
				fill: new Fill({ color: '#000080' })
			})
		});
		this.routeEndStyle = new Style({
			image: new Circle({
				radius: 5,
				fill: new Fill({ color: '#AA0000' })
			})
		});
		this.routeBorderStyle = new Style({
			stroke: new Stroke({
				color: '#000000',
				width: 6,
			})
		});
		this.routeTracklineStyles = [];
		for (let i = 0; i < 16; ++i) {
			this.routeTracklineStyles.push(new Style({
				stroke: new Stroke({
					color: colorsHex[i],
					width: 4,
				})
			}));
		}

		useGeographic();
		this.map = new Map({
			controls: defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]),
			target: 'mapFBdetail',
			view: new View({
				center: this.coord,
				zoom: this.zoom,
				minZoom: 5,
				maxZoom: 18,
			}),
			renderer: 'webgl',
			layers: [
				this.mapLayer,
				this.routeBorderLayer,
				this.routeLayer,
				this.leerungLayer,
				this.routeArrowLayer
			]
		});
		this.map.on('pointermove', event => {
			this.displayTooltip(event);
		});
		this.map.on('moveend', () => {
			if (this.map.getView().getZoom() !== this.zoom) {
				this.rescaleMapLayers();
			}
		});
	}

	rescaleMapLayers(): void {
		this.zoom = this.map.getView().getZoom();

		const scaleLrg = 0.7 / (1.0 + this.map.getView().getMaxZoom() - this.zoom);
		this.leerungLayer.getSource().getFeatures().forEach(feature => {
			feature.getStyle().getImage().setScale(scaleLrg);
		});
		this.leerungLayer.getSource().changed()

		let widthRouteBorder = 6;
		let widthRoute = 4;
		let routeZoomLevel = this.map.getView().getMaxZoom() - this.map.getView().getZoom();

		//console.log(routeZoomLevel);
		if (routeZoomLevel >= 0 && routeZoomLevel <= 2.5) {
			widthRouteBorder = 6;
			widthRoute = 4;
		} else if (routeZoomLevel > 2.5 && routeZoomLevel <= 3.5) {
			widthRouteBorder = 4;
			widthRoute = 2;
		} else if (routeZoomLevel > 3.5) {
			widthRouteBorder = 2;
			widthRoute = 1;
		}

		this.routeBorderLayer.getSource().getFeatures().forEach(feature => {
			feature.getStyle().getStroke().setWidth(widthRouteBorder);
		});
		this.routeLayer.getSource().getFeatures().forEach(feature => {
			if (null != feature.getStyle().getStroke()) {
				feature.getStyle().getStroke().setWidth(widthRoute);
			}
		});
		this.routeBorderLayer.getSource().changed();
		this.routeLayer.getSource().changed();
	}

	// #endregion Map

	// #region FBLeerungen

	trackByFunction = (index, item) => {
		return item.ds_auto_id;
	}

	//storageGetEntries() {
	//return VIONSessionStorage.getInstance().get(this.apiUrl);
	// none
	//}

	//storageSetEntries() {
	//VIONSessionStorage.getInstance().set(this.apiUrl, this.loadTimestamp, this.loadFilters, this.entries);
	// none
	//}

	/**
	 * Liefert die Länge der Darstellung eines Textes in Pixeln zurück
	 * 
	 * @param text Text, der überprüft werden soll
	 * @param styleFont Font, in der der Text geschrieben ist
	 * @returns Textbreite in Pixeln
	 */
	getTextLength(text: string, styleFont: string): number {
		const ctx = this.emptyCanvas.nativeElement.getContext('2d');
		ctx.font = styleFont;
		const textMetrics = ctx.measureText(text);
		return Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
	}

	/**
	 * wenn eine spalte noch ein special symbol im header hat
	 * 
	 * @param maxStringLength 
	 * @param columnkey 
	 * @returns 
	 */
	adaptColumnSize(maxStringLength, columnkey): number {
		return maxStringLength;
	}

	/**
	 * berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	 * 
	 * @param bForce 
	 * @param state 
	 */
	resizeTableWidthFromContent(bForce, state?): void {
		var bResize = bForce;

		this.loading += 1;

		// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
		this.state = state ? state : JSON.parse(localStorage.getItem(this.stateName));
		if (this.state == undefined) {
			// force storage of table state
			this.table.saveState();
			// reload state
			this.state = JSON.parse(localStorage.getItem(this.stateName));
			bResize = true;
		}

		if (this.table && bResize) {
			// autosize columns
			const lTable = document.getElementById(this.table.id);
			var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
			// für alle spalten, alle daten            
			this.cols.forEach(col => {
				let columnname = this.translate.instant('HEADERS.' + col.key);
				let maxStringLength = this.getTextLength(columnname, lTableFont);
				// filter symbol
				maxStringLength = maxStringLength + 80;
				maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
				if (this.entries) {
					this.entries.forEach(row => {
						try {
							let newLength = 0;
							if (col.type == 'date') {
								if (row[col.key] != undefined)
									newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
								else
									newLength = 0;
							} else {
								newLength = this.getTextLength(row[col.key], lTableFont);
							}
							// margins zur zelle
							newLength = newLength + 26;
							if (newLength > maxStringLength)
								maxStringLength = newLength;
						} catch(err) {
							//console.log(err);
						};
					})
				}

				col.width = maxStringLength;
			});

			// crashes here
			//this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		}

		// standard funktion aufrufen
		this.resizeTableWidth(this.state);

		this.loading -= 1;
	}
	// #endregion FBLeerungen
}