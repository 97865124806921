import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	//templateUrl: './logbox_pubkey_approval_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class LogboxPubkeyApprovalDialogComponent extends FilterDialogComponent {
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		const optionGroup1 = new Map();
		optionGroup1.set('fingerprint_logbox_pubkey', { type: 'text_readonly' });
		optionGroup1.set('fingerprint_server_pubkey', { type: 'text_readonly' });
		optionGroup1.set('timestamp_logbox_pubkey', { type: 'text_readonly' });
		optionGroup1.set('approved', { type: 'boolean' });
		optionGroup1.set('2fa_code', { type: 'text' });
		this.optionGroups = [optionGroup1];
	}

	ngOnInit() {
		super.ngOnInit();
		if(this.manualData && this.manualData.length > 0 && this.manualData[0] && this.manualData[0].length > 0) {
			//selections[option.key]
			for( let i = 0; i < this.manualData[0].length; ++i ) {
				this.selections[this.manualData[0][i]['fieldkey']] = this.manualData[0][i]['value'];
			}
		}
	}
}
