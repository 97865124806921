import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { Policy } from '@app/models/policy';
import { tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import * as fs from 'file-saver';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { VehicleAddComponentDialogComponent } from './vehicle_add_component_dialog/vehicle_add_component_dialog.component';
import { TabView } from 'primeng/tabview';
import { VehicleAddProcesstaskDialogComponent } from './vehicle_add_processtask_dialog/vehicle_add_processtask_dialog.component';
import { VehicleCheckProcessTaskDialogComponent } from './vehicle_check_processtask_dialog/vehicle_check_processtask_dialog.component';
import { VehicleAddProcessScheduleDialogComponent } from './vehicle_add_process_schedule/vehicle_add_process_schedule_dialog.component';
import { VehicleProcessScheduleHistoryListComponent } from './vehicle_process_schedule_history_list/vehicle_process_schedule_history_list.component';
import { truncate } from 'fs';
import { VehicleAddMultipleComponentsDialogComponent } from './vehicle_add_multiple_components_dialog/vehicle_add_multiple_components_dialog.component';
import { VehicleReplaceComponentDialogComponent } from './vehicle_replace_component_dialog/vehicle_replace_component_dialog.component';
import { LogboxPubkeyApprovalDialogComponent } from './logbox_pubkey_approval_dialog/logbox_pubkey_approval_dialog.component';
import { TwoFactorCheckComponent } from '@app/components/twofactor.component';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblFahrzeugDetailComponent implements OnInit {
	readonly msgTimeoutError: number = 3000;
	currentTabIndex: number = 0;
	visibleTableComponentHistory: boolean = true;
	visibleTableProcessTasks: boolean = true;
	visibleServiceDataFields: boolean = true;

	@ViewChild('twoFactorPopup') twoFactorCheck!: TwoFactorCheckComponent;

	/* Table generic */
	@ViewChild('emptyCanvas', { static: false }) emptyCanvas: ElementRef;
	buttonColWidth: number = 150;
	isMobile: boolean = false;
	minTableHeight: number = 80;
	/* ENDE Table generic */

	/* Table ComponentHistory */
	@ViewChild('tableComponentHistory') tableComponentHistory: Table;
	@ViewChild('colselectionComponentHistory') colselectionComponentHistory: MultiSelect;
	contentHeightComponentHistory: number = 4320;
	entriesComponentHistory: Base[];
	colsComponentHistory: any[] = [];
	possibleColsComponentHistory: any[] = [];
	selectedEntryComponentHistory: Base;
	contextMenuComponentHistory: MenuItem[];
	countComponentHistory: number = 0;
	filtersComponentHistory: string[];
	globalFilterComponentHistory: string = '';
	isTableInitComponentHistory: boolean = false;
	tableStateComponentHistory: any;
	tableStateNameComponentHistory: string = '';
	historyUrl: string = '';
	stateComponentHistory: any;
	stateNameComponentHistory: string = '';
	/* ENDE Table ComponentHistory */

	/* Table ProcessTasks */
	@ViewChild('tableProcessTasks') tableProcessTasks: Table;
	@ViewChild('colselectionProcessTasks') colselectionProcessTasks: MultiSelect;
	// implicit ... entriesComponentHistory: Base[];
	contentHeightProcessTasks: number = 1000;
	colsProcessTasks: any[] = [];
	possibleColsProcessTasks: any[] = [];
	selectedEntryProcessTasks: Base;
	contextMenuProcessTasks: MenuItem[];
	countProcessTasks: number = 0;
	filtersProcessTasks: string[];
	globalFilterProcessTasks: string = '';
	isTableInitProcessTasks: boolean = false;
	tableStateProcessTasks: any;
	tableStateNameProcessTasks: string = '';
	tasksUrl: string = '';
	stateProcessTasks: any;
	stateNameProcessTasks: string = '';
	/* ENDE Table ProcessTasks */

	isVisy = false;
	
	@ViewChild('tabView') tabView: TabView;
	vehicleProcessingData: any;
	vehicleProcessingFieldGroups: any[];
	vehicleProcessingfields1: any[];
	vehicleProcessingfields2: any[];
	assistfields: any[];
	
	attachmentListStyle = 'list';
	attachments: any[];
	attachUrl: string;
	autosizeColumns: boolean = false; // Tabellenspaltenbreiten automatisch berechnen
	basefields: any[];
	
	serviceData: any = null;
	servicedataFieldGroups: any[];
	servicedatafields1: any[];
	servicedatafields2: any[];
	serviceProcessData: any;
	url_edit: string = PATH.EDIT; // Url Zusatz für die Edit-Seite nach der url
	url: string = '';
	userAdjustedColumnSize: boolean = false; // Tabellenspaltenbreiten vom Nutzer gesetzt
	
	vehicle: tbl_fahrzeug;
	vehicleFieldGroups: any[];
	vehiclefilefields1: any[];
	vehiclefilefields2: any[];
	vehiclefilefields3: any[];
	vehiclefilefields4: any[];
	reloading = false; // Soll die Tabelle neu laden
	createPermission: boolean = false;
	readPermission: boolean = false; // Darf der Nutzer Stammdateneinträge ansehen
	updatePermission: boolean = false; // Darf der Nutzer Stammdateneinträge bearbeiten
	deletePermission: boolean = false; // Darf der Nutzer Stammdateneinträge löschen

	vehicleProcessScheduleSupply: any = null;
	vehicleProcessScheduleDispatch: any = null;

	id: number;
	loading = 0;
	remoteMaintenancePort = false;

	constructor(
		private accountService: AccountService,
		private breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		private confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public exportService: ExportService,
		private messageService: MessageService,
		private router: Router,
		private sanitizer: DomSanitizer,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public globals: Globals,
		public elRef: ElementRef,
	) {
		this.url = '/' + PATH.FAHRZEUG;
		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];

		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: 'MENU.FAHRZEUGE', routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);

		this.isVisy = this.accountService.isAuthorized(Policy.ViSyUser);

		if(this.isVisy) {
			this.basefields = [
				{ type: 'string', key: 'ankey', label: 'ankey' },
				{ type: 'string', key: 'bezeichnung', label: 'bezeichnung' },
				{ type: 'string', key: 'kennzeichen', label: 'kennzeichen' },
				{ type: 'string', key: 'logbox_serial', label: 'logbox_serial' },
				
				{ type: 'boolean', key: 'VIRTUAL_has_logbox_pubkey_data', label: 'Logbox PubKey registriert' },
				{ type: 'string', key: 'VIRTUAL_logbox_pubkey_stored_by_userid', label: 'Logbox PubKey registriert von' },
				{ type: 'string', key: 'VIRTUAL_logbox_pubkey_stored_by_username', label: 'Logbox PubKey registriert von' },
				{ type: 'date', key: 'VIRTUAL_logbox_pubkey_stored_timestamp', showTime: true, label: 'Logbox PubKey registriert am/um' },
				{ type: 'date', key: 'VIRTUAL_conditional_pubkey_registration_expiration', showTime: true, label: 'Logbox PubKey Registrierung erlaubt bis' },
				{ type: 'string', key: 'approval_pubkey_logbox_userid', label: 'Logbox PubKey Abnahme erteilt von' },
				{ type: 'string', key: 'approval_pubkey_logbox_username', label: 'Logbox PubKey Abnahme erteilt von' },
				{ type: 'date', key: 'approval_pubkey_logbox_timestamp', showTime: true, label: 'Logbox PubKey Abnahme erteilt am/um' },
				{ type: 'string', key: 'approval_pubkey_logbox_fingerprint', label: 'Logbox PubKey Abnahme Fingerprint' },
				{ type: 'string', key: 'approval_pubkey_server_fingerprint', label: 'Server PubKey Abnahme Fingerprint' },
				{ type: 'string', key: 'fahrgestellnummer', label: 'fahrgestellnummer' },
				{ type: 'string', key: 'bemerkungen', label: 'bemerkungen' },
				{ type: 'object', key: 'FREMD_aufbau_bezeichnung', label: 'FREMD_aufbau_bezeichnung', url: '/' + PATH.AUFBAU + '/' + PATH.DETAIL, id: 'aufbau_id' },
				{ type: 'object', key: 'FREMD_fahrgestell_bezeichnung', label: 'FREMD_fahrgestell_bezeichnung', url: '/' + PATH.FAHRGESTELL + '/' + PATH.DETAIL, id: 'fahrgestell_id' },
				{ type: 'object', key: 'FREMD_lifter_bezeichnung', label: 'FREMD_lifter_bezeichnung', url: '/' + PATH.LIFTER + '/' + PATH.DETAIL, id: 'lifter_id' },
			];
		} else {
			this.basefields = [
				{ type: 'string', key: 'ankey', label: 'ankey' },
				{ type: 'string', key: 'bezeichnung', label: 'bezeichnung' },
				{ type: 'string', key: 'kennzeichen', label: 'kennzeichen' },
				{ type: 'string', key: 'logbox_serial', label: 'logbox_serial' },
				{ type: 'string', key: 'fahrgestellnummer', label: 'fahrgestellnummer' },
				{ type: 'string', key: 'bemerkungen', label: 'bemerkungen' },
				{ type: 'object', key: 'FREMD_aufbau_bezeichnung', label: 'FREMD_aufbau_bezeichnung', url: '/' + PATH.AUFBAU + '/' + PATH.DETAIL, id: 'aufbau_id' },
				{ type: 'object', key: 'FREMD_fahrgestell_bezeichnung', label: 'FREMD_fahrgestell_bezeichnung', url: '/' + PATH.FAHRGESTELL + '/' + PATH.DETAIL, id: 'fahrgestell_id' },
				{ type: 'object', key: 'FREMD_lifter_bezeichnung', label: 'FREMD_lifter_bezeichnung', url: '/' + PATH.LIFTER + '/' + PATH.DETAIL, id: 'lifter_id' },
			];
		}

		this.vehiclefilefields1 = [
			{ type: 'object', key: 'FREMD_firma_bezeichnung', label: 'FREMD_firma_bezeichnung', url: '/' + PATH.FIRMA + '/' + PATH.DETAIL, id: 'firma_id' },
			{ type: 'string', key: 'lexware_projekt_nummer', label: 'lexware_projekt_nummer' },
			{ type: 'string', key: 'bemerkung', label: 'bemerkung' },
			{ type: 'boolean', key: 'vorbereitung_set_1', label: 'vorbereitung_set_1' },
			{ type: 'boolean', key: 'vorbereitung_set_2', label: 'vorbereitung_set_2' },
			{ type: 'boolean', key: 'vorbereitung_set_3', label: 'vorbereitung_set_3' },
			{ type: 'boolean', key: 'datenaufzeichnung', label: 'datenaufzeichnung' },
			{ type: 'boolean', key: 'rueckfahrkataster', label: 'rueckfahrkataster' },
			{ type: 'date', key: 'rueckfahrkataster_bis', label: 'rueckfahrkataster_bis', showTime: false },
			{ type: 'string', key: 'simkartennummer', label: 'simkartennummer' },
			{ type: 'date', key: 'simkarte_bis', label: 'simkarte_bis', showTime: false },
		];

		this.vehiclefilefields3 = [
			{ type: 'boolean', key: 'rueckfahrassistent', label: 'rueckfahrassistent' },
			{ type: 'boolean', key: 'unterlaufschutz_seitenlader', label: 'unterlaufschutz_seitenlader' },
			{ type: 'boolean', key: 'abbiege_rechts_180_grad', label: 'abbiege_rechts_180_grad' },
			{ type: 'boolean', key: 'abbiege_rechts_3d', label: 'abbiege_rechts_3d' },
			{ type: 'boolean', key: 'abbiege_rechts_kamera', label: 'abbiege_rechts_kamera' },
			{ type: 'boolean', key: 'abbiege_rechts_radar', label: 'abbiege_rechts_radar' },
			{ type: 'boolean', key: 'abbiege_links_3d', label: 'abbiege_links_3d' },
			{ type: 'boolean', key: 'abbiege_links_kamera', label: 'abbiege_links_kamera' },
			{ type: 'boolean', key: 'abbiege_links_radar', label: 'abbiege_links_radar' },
		];

		this.servicedatafields1 = [
			{ type: 'string', key: 'Servicedaten_sbox_signature', label: 'Servicedaten_sbox_signature' },
			{ type: 'string', key: 'Servicedaten_kennzeichen', label: 'Servicedaten_kennzeichen' },
			{ type: 'string', key: 'Servicedaten_ts_lastlogin', label: 'Servicedaten_ts_lastlogin' },
			{ type: 'string', key: 'Servicedaten_ts_lastgps', label: 'Servicedaten_ts_lastgps' },
			{ type: 'string', key: 'Servicedaten_ts_lastreboot', label: 'Servicedaten_ts_lastreboot' },
			{ type: 'string', key: 'Servicedaten_ts_lastidle', label: 'Servicedaten_ts_lastidle' },
			{ type: 'string', key: 'Servicedaten_gps_ts_timestamp', label: 'Servicedaten_gps_ts_timestamp' },
			{ type: 'string', key: 'Servicedaten_gps_sats_in_view', label: 'Servicedaten_gps_sats_in_view' },
			{ type: 'string', key: 'Servicedaten_gps_sats_used', label: 'Servicedaten_gps_sats_used' },
			{ type: 'string', key: 'Servicedaten_gps_longitude', label: 'Servicedaten_gps_longitude' },
			{ type: 'string', key: 'Servicedaten_gps_latitude', label: 'Servicedaten_gps_latitude' },
		];

		this.servicedatafields2 = [
			{ type: 'string', key: 'Servicedaten_server_ip', label: 'Servicedaten_server_ip' },
			{ type: 'string', key: 'Servicedaten_reboot_version', label: 'Servicedaten_reboot_version' },
			{ type: 'string', key: 'Servicedaten_palm_version', label: 'Servicedaten_palm_version' },
			{ type: 'string', key: 'Servicedaten_ident_version', label: 'Servicedaten_ident_version' },
			{ type: 'string', key: 'Servicedaten_waage_version', label: 'Servicedaten_waage_version' },
			{ type: 'string', key: 'Servicedaten_flags', label: 'Servicedaten_flags' },
			{ type: 'string', key: 'Servicedaten_login_counter', label: 'Servicedaten_login_counter' },
			{ type: 'number', key: 'Servicedaten_FernzugangPortDisplay', label: 'Servicedaten_FernzugangPortDisplay' },
			{ type: 'number', key: 'Servicedaten_FernzugangPortLogbox', label: 'Servicedaten_FernzugangPortLogbox' },
			{ type: 'string', key: 'Servicedaten_FernzugangTriggered', label: 'Servicedaten_FernzugangTriggered' },
		];

		this.vehicleFieldGroups = [
			{ title: 'Fahrzeug', fields: this.basefields }
		]

		if (this.isVisy) {
			this.vehicleFieldGroups.push(
				{ title: 'Fahrzeugakte', fields: this.vehiclefilefields1 },
				{ title: 'Produkte', fields: this.vehiclefilefields3 },
			);

			this.servicedataFieldGroups = [
				{ title: 'GPS', fields: this.servicedatafields1 },
				{ title: 'Remote', fields: this.servicedatafields2 },
			];
		}

		this.historyUrl = '/' + PATH.FAHRZEUGAKTE_KOMPONENTE_LINK;
		this.possibleColsComponentHistory = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_ankey', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_serial_no', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_typ_ankey', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_kennzeichen', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_fahrgestellnummer', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_logbox_serial', required: false, width: 400 },
			{ type: 'text', key: 'zugeordnet_von_string', required: false, width: 400 },
			{ type: 'text', key: 'zugeordnet_bis_string', required: false, width: 400 },
			{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_bemerkungen', required: false, width: 400 },

		];
		this.colsComponentHistory = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'FREMD_komponente_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_serial_no', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'zugeordnet_von_string', required: false, width: 400 },
			{ type: 'text', key: 'zugeordnet_bis_string', required: false, width: 400 },
			{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_komponente_bemerkungen', required: false, width: 400 },
		];

		this.tasksUrl = '/' + PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT;
		this.possibleColsProcessTasks = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: false, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 300 },
			{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abschnitt_typ_ankey', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abschnitt_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'geoeffnet_string_german', required: false, width: 300, dateonly: true },
			{ type: 'text', key: 'geschlossen_string_german', required: false, width: 300, dateonly: true },
			{ type: 'text', key: 'FREMD_abgezeichnet_mitarbeiter_ankey', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abgezeichnet_mitarbeiter_bezeichnung', required: false, width: 300 },
			{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
		];
		this.colsProcessTasks = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 300 },
			{ type: 'text', key: 'FREMD_abschnitt_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'geoeffnet_string_german', required: false, width: 300, dateonly: true },
			{ type: 'text', key: 'geschlossen_string_german', required: false, width: 300, dateonly: true },
			{ type: 'text', key: 'FREMD_abgezeichnet_mitarbeiter_bezeichnung', required: false, width: 300 },
			{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
		];
	}

	ngOnInit() {
		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.DELETE);

		if (this.readPermission) {
			this.getEntry();
		}

		this.tableStateNameComponentHistory = 'state' + 'TblFahrzeugKomponenten' + 'History' + 'List';
		this.retrieveTableStateComponentHistory(this.tableStateNameComponentHistory, this.tableStateComponentHistory);
		if (this.tableStateComponentHistory) {
			this.colsComponentHistory = [];
			if(this.tableStateComponentHistory['columnOrder']) {
				this.tableStateComponentHistory['columnOrder'].forEach(col => {
					this.possibleColsComponentHistory.forEach(c => {
						if (col == c.key) {
							this.colsComponentHistory.push(c);
						}
					});
				});
				if (this.tableStateComponentHistory.filters.global) {
					this.globalFilterComponentHistory = this.tableStateComponentHistory.filters.global.value;
				}
			}
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenuComponentHistory = [
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detailComponentHistory() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detailComponentHistory('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detailComponentHistory('window') },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw', command: () => this.resizeTableWidthFromContentComponentHistory(true, this.tableStateComponentHistory) },
				{ label: this.translate.instant('CONTEXT_MENU.REPLACE_COMPONENT'), icon: 'pi pi-fw pi-search', command: () => this.komponentenAustausch() },
			];
			this.possibleColsComponentHistory.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.tableStateNameProcessTasks = 'state' + 'FahrzeugAbwicklungAbschnitt' + 'Tasks' + 'List';
		this.retrieveTableStateProcessTasks(this.tableStateNameProcessTasks, this.tableStateProcessTasks);
		if (this.tableStateProcessTasks) {
			this.colsProcessTasks = [];
			if(this.tableStateProcessTasks['columnOrder']) {
				this.tableStateProcessTasks['columnOrder'].forEach(col => {
					this.possibleColsProcessTasks.forEach(c => {
						if (col == c.key) {
							this.colsProcessTasks.push(c);
						}
					});
				});
				if (this.tableStateProcessTasks.filters.global) {
					this.globalFilterProcessTasks = this.tableStateProcessTasks.filters.global.value;
				}
			}
		}

		this.translate.get('init').subscribe((text: string) => {
			this.contextMenuProcessTasks = [
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detailProcessTasks() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detailProcessTasks('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detailProcessTasks('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-search', command: () => this.editProcessTasks() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-search', command: () => this.editProcessTasks('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.editProcessTasks('window') },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw', command: () => this.resizeTableWidthFromContentProcessTasks(true, this.tableStateProcessTasks) },
			];
			this.possibleColsProcessTasks.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	ngAfterViewInit(): void {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			//this.initTableComponentHistory();
			//this.initTableProcessTasks();
			this._onResize();
		});
	
		if (this.tableComponentHistory && this.tableComponentHistory.filters) {
			let restoredFilter = false;
			this.filtersComponentHistory.forEach(col => {
				Object.keys(this.tableComponentHistory.filters[col]).forEach(filter => {
					if (this.tableComponentHistory.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 5000 });
			}
		}

		if (this.tableProcessTasks && this.tableProcessTasks.filters) {
			let restoredFilter = false;
			this.filtersProcessTasks.forEach(col => {
				Object.keys(this.tableProcessTasks.filters[col]).forEach(filter => {
					if (this.tableProcessTasks.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 5000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);
		this.initTableComponentHistory();
		this.initTableProcessTasks();
	}
	
	ngAfterViewChecked(): void {
		let bDetectChanges = false;
		if (!this.isTableInitComponentHistory && this.tableComponentHistory && this.tableComponentHistory.value) {
			this.isTableInitComponentHistory = true;
			this.resizeTableWidthFromContentComponentHistory(false, this.tableStateComponentHistory);
	
			bDetectChanges = true;
		}

		if (!this.isTableInitProcessTasks && this.tableProcessTasks && this.tableProcessTasks.value) {
			this.isTableInitProcessTasks = true;
			this.resizeTableWidthFromContentProcessTasks(false, this.tableStateProcessTasks);
	
			bDetectChanges = true;
		}

		if(bDetectChanges) {
			const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
			if (el != null)
				this.changeWheelSpeed(el, 0.9);
			this._detectChanges();
		}
	}
	
	@HostListener('window:resize', ['$event'])
	onResize(event): void {
		this._onResize();
	}

	_onResize() {
		//this.initTableComponentHistory();
		//this.initTableProcessTasks();
		this.resizeTableWidthFromContentComponentHistory(false, this.tableStateComponentHistory);
		this.resizeTableWidthFromContentProcessTasks(false, this.tableStateProcessTasks);
	}

	getEntry() {
		this.vehicle = null;
		this.raiseLoading();
		this.crudService.getVehicle(this.id).then(res => {
			this.vehicle = res;

			// Fahrzeugakte laden
			if (this.isVisy && this.vehicle.akte_id) {
				this.raiseLoading(); // finaly in 451
				this.crudService.getVehicleFile(this.vehicle.akte_id).then(response => {
					this.vehicle = { ...response, ...this.vehicle };
					this.vehicleFieldGroups.forEach(fg => {
						fg.fields.forEach(field => {
							if (field.type == 'date') {
								if (this.vehicle[field.key] != null) {
									this.vehicle[field.key] = this.parseDate(new Date(this.vehicle[field.key]), field.showTime);
								}
							}
						});
					});
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
						}
					})
				}).finally(() => {
					this.lowerLoading();
				});
			} else {
				this.vehicleFieldGroups.forEach(fg => {
					fg.fields.forEach(field => {
						if (field.type == 'date') {
							if (this.vehicle[field.key] != null) {
								this.vehicle[field.key] = this.parseDate(new Date(this.vehicle[field.key]), field.showTime);
							}
						}
					});
				});
			}

			// Lifter laden
			if (this.vehicle.lifter_id) {
				this.raiseLoading();
				this.crudService.getLifter(this.vehicle.lifter_id).then(response => {
					this.vehicle.lifter = response;
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
						}
					})
				}).finally(() => {
					this.lowerLoading();
				});
			}

			// Aufbau laden
			if (this.vehicle.aufbau_id) {
				this.raiseLoading();
				this.crudService.getConstruction(this.vehicle.aufbau_id).then(response => {
					this.vehicle.aufbau = response;
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
						}
					})
				}).finally(() => {
					this.lowerLoading();
				});
			}

			// Fahrgestell laden
			if (this.vehicle.fahrgestell_id) {
				this.raiseLoading();
				this.crudService.getChassis(this.vehicle.fahrgestell_id).then(response => {
					this.vehicle.fahrgestell = response;
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
						}
					})
				}).finally(() => {
					this.lowerLoading();
				});
			}

			if (this.isVisy) {
				this.getComponentHistory();
				this.raiseLoading();
				this.crudService.getAllAttachments('fahrzeug', this.id).then(response => {
					this.attachments = response;
					this.attachments.forEach(attachment => {
						this.displayFile(attachment);
					});
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
						}
					});
				}).finally(() => {
					this.lowerLoading();
				});

				// nur bei bedarf laden/anlegen
				//this.loadVehicleProcessData();
			}
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
		this.attachUrl = `${environment.apiUrl}/tblanhang/create/fahrzeug/${this.id}`;
		//const options = await this.accountService.getOptions();
	}

	//ngOnDestroy() {
	//	Object.keys(this.durationCounterIntervals).forEach(key => {
	//		this.stopTimer(+key);
	//	});
	//}

	setzeAktiv() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.ACTIVATE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.internal_AktivInaktiv(true);
			}
		});
	}

	setzeInaktiv() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DEACTIVATE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.internal_AktivInaktiv(false);
			}
		});
	}

	private async internal_AktivInaktiv(value: boolean) {
		this.raiseLoading();
		this.crudService.setVehicleActiveState(this.vehicle.ds_this_id, value).then(() => {
			window.location.reload();
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
	}

	delete() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.raiseLoading();
				this.crudService.deleteVehicle(this.vehicle.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					if (this.vehicle.akte_id) {
						this.crudService.deleteVehicleFile(this.vehicle.akte_id).then(res => {
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
						}).catch(err => {
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
								}
							})
						}).finally(() => {
							this.router.navigate([this.url + '/' + PATH.LIST]);
						});
					} else {
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
						this.router.navigate([this.url + '/' + PATH.LIST]);
					}
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
						}
					})
				}).finally(() => {
					this.lowerLoading();
				});
			}
		});
	}

	deleteFile(attachment) {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.raiseLoading();
				this.crudService.deleteAttachment(attachment.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					this.attachments.splice(this.attachments.indexOf(attachment), 1);
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
						}
					})
				}).finally(() => {
					this.lowerLoading();
				});
			}
		});
	}

	displayFile(attachment) {
		this.raiseLoading();
		this.crudService.getAttachment(attachment.ds_this_id).then(res => {
			if (this.getFileType(attachment.bezeichnung) === 'image') {
				const blob = new Blob([res], { type: 'application/octet-stream' });
				attachment.fileUrl = this.loadImageFile(blob);
			} else if (this.getFileType(attachment.bezeichnung) === 'text') {
				const blob = new Blob([res], { type: 'text/plain' });
				attachment.fileUrl = this.loadPdfFile(blob);//this.loadTextFile(blob);
			} else if (this.getFileType(attachment.bezeichnung) === 'pdf') {
				const blob = new Blob([res], { type: 'application/pdf' });
				attachment.fileUrl = this.loadPdfFile(blob);
			}
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
	}

	downloadFile(attachment) {
		this.raiseLoading();
		this.crudService.getAttachment(attachment.ds_this_id).then(res => {
			const blob = new Blob([res], { type: 'application/octet-stream' });
			fs.saveAs(blob, attachment.bezeichnung);
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
	}

	edit() {
		this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.id]);
	}

	getFileType(fileName) {
		const fileExtension = fileName.split('.').slice(-1)[0].toLowerCase();
		if (['png', 'jpg', 'gif'].includes(fileExtension)) {
			return 'image';
		} else if (['conf', 'txt'].includes(fileExtension)) {
			return 'text';
		} else if (['pdf'].includes(fileExtension)) {
			return 'pdf';
		}
	}

	getRemoteMaintenancePort() {
		this.raiseLoading();
		this.crudService.getVehicleRemoteMaintenancePort(this.id).then(res => {
			this.serviceData.Servicedaten_FernzugangPortDisplay = res['Servicedaten_FernzugangPortDisplay'];
			this.serviceData.Servicedaten_FernzugangPortLogbox = res['Servicedaten_FernzugangPortLogbox'];
			this.serviceData.Servicedaten_FernzugangTriggered = res['Servicedaten_FernzugangTriggered'];

			this.remoteMaintenancePort = true;
			this.redrawServiceDataFields();
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
	}

	isAdmin(): boolean {
		return this.accountService.isAuthorized(Policy.ViSyAdmins);
	}

	getBooleanKeyvalue(field: any, key: string): boolean {
		if( field && key && key.length > 0 ) {
			return ((field[key] != null) && (field[key] != undefined) && (field[key] === true)) ? true : false;
		}
		return false;
	}

	initVehicleProcessDataSmart() {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicleProcessingData ) {
			return;
		}
		this.raiseLoading();
		this.crudService.getOrCreateVehicleProcessingFile(this.vehicle.akte_id).then(res => {
			this.vehicleProcessingData = res;
			this.getVehicleProcessScheduleAll();
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
	}

	refreshVehicleProcessDataView() {
		this.raiseLoading();
		try {
			let countAll = 0;
			let countDone = 0;
			let countWorking = 0;
			if( this.vehicleProcessingData.linkedTasks && this.vehicleProcessingData.linkedTasks.length > 0 ) {
				this.vehicleProcessingData.linkedTasks.forEach(t => {
					if(t) {
						++countAll;
						let geschlossen = this.getBooleanKeyvalue(t, 'geschlossen');
						let in_bearbeitung = this.getBooleanKeyvalue(t, 'in_bearbeitung');
						if(geschlossen)
							++countDone;
						else if(in_bearbeitung)
							++countWorking;
					}
				});
			}
			this.countProcessTasks = countAll;
			this.vehicleProcessingData.SYNTH_anzahl_abschnitte_insgesamt = countAll;
			this.vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt = countDone;
			this.vehicleProcessingData.SYNTH_anzahl_abschnitte_in_bearbeitung = countWorking;
			this.vehicleProcessingData.progressData = {
				labels: ['Abgeschlossen', 'In Arbeit', 'Offen'],
				datasets: [
					{
						data: [this.vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt, this.vehicleProcessingData.SYNTH_anzahl_abschnitte_in_bearbeitung, this.vehicleProcessingData.SYNTH_anzahl_abschnitte_insgesamt - this.vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt - this.vehicleProcessingData.SYNTH_anzahl_abschnitte_in_bearbeitung],
						backgroundColor: [
							"#78cc86",
							"#ffd54f",
							"#f75965"
						],
						borderWidth: 0
					}
				]
			};
			this.vehicleProcessingData.progressDataOptions = {
				plugins: {
					legend: {
						display: false
					}
				}
			};
			setTimeout(() => {
				try {
					if( this.elRef && this.elRef.nativeElement ) {
						const progressPercentage = this.elRef.nativeElement.querySelector('#progressPercentage') as HTMLElement;
						let value = (this.vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt / this.vehicleProcessingData.SYNTH_anzahl_abschnitte_insgesamt);
							let valuePerc = value * 100;
							let valuePercTrunc = Math.trunc(valuePerc);
							//console.log(value);
							//console.log(valuePerc);
							//console.log(valuePercTrunc);
						if (progressPercentage) {
							//progressPercentage.innerHTML = Math.trunc((this.vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt / this.vehicleProcessingData.SYNTH_anzahl_abschnitte_insgesamt)) * 100 + '%';
							progressPercentage.innerHTML = valuePercTrunc + '%';
						} else {
							const progressChart = this.elRef.nativeElement.querySelector('#progressChart > div');
							//progressChart.insertAdjacentHTML('beforeend', '<h4 id="progressPercentage">' + (this.vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt / this.vehicleProcessingData.SYNTH_anzahl_abschnitte_insgesamt) * 100 + '%</h4>');
							progressChart.insertAdjacentHTML('beforeend', '<h4 id="progressPercentage">' + valuePercTrunc + '%</h4>');
						}
					}
				} catch {}
			}, 100);
			this.initTableProcessTasks();
			//this.resizeTableWidthFromContentProcessTasks(true, this.tableStateProcessTasks);
			setTimeout(() => this._onResize(), 1000);
			this.getVehicleProcessScheduleAll();
		}
		catch(err) {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		} finally {
			this.lowerLoading();
		}
	}

	loadVehicleProcessData(forceReloadData: boolean) {
		if( !this.isVisy ) {
			return;
		}
		if( !forceReloadData && this.vehicleProcessingData && this.vehicleProcessingData.linkedTasks ) {
			return this.refreshVehicleProcessDataView();
		}

		this.raiseLoading();
		this.crudService.getOrCreateVehicleProcessingFile(this.vehicle.akte_id).then(res => {
			this.vehicleProcessingData = res;
			this.raiseLoading();
			this.crudService.getVehicleProcessTasksByFile(this.vehicleProcessingData['ds_this_id']).then(tasks => {
				this.vehicleProcessingData.linkedTasks = tasks;
				this.refreshVehicleProcessDataView();
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
					}
				})
			}).finally(() => {
				this.lowerLoading();
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
	}
	
	loadImageFile(blob: Blob) {
		const unsafeImg = URL.createObjectURL(blob);
		return this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
	}

	loadPdfFile(blob: Blob) {
		const unsafeUrl = URL.createObjectURL(blob);
		return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
	}

	loadTextFile(blob: Blob) {
		let fileReader: FileReader = new FileReader();
		fileReader.onloadend = (x => {
			return fileReader.result;
		});
		fileReader.readAsText(blob);
	}

	loadServiceData() {
		let bakDisplay: number;
		let bakLogbox: number;
		let bakDatetime: string;
		if (this.remoteMaintenancePort) {
			bakDisplay = this.serviceData.Servicedaten_FernzugangPortDisplay
			bakLogbox = this.serviceData.Servicedaten_FernzugangPortLogbox
			bakDatetime = this.serviceData.Servicedaten_FernzugangTriggered
		}
		this.raiseLoading();
		this.crudService.getVehicleServiceData(this.id).then(res => {
			this.serviceData = res;
			if (this.remoteMaintenancePort) {
				this.serviceData.Servicedaten_FernzugangPortDisplay = bakDisplay;
				this.serviceData.Servicedaten_FernzugangPortLogbox = bakLogbox;
				this.serviceData.Servicedaten_FernzugangTriggered = bakDatetime;
			}
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
	}

	parseDate(date: Date, showTime: boolean): string {
		const options: Intl.DateTimeFormatOptions = { dateStyle: 'medium', timeStyle: showTime ? 'short' : undefined };
		if (date) {
			return new Intl.DateTimeFormat(this.translate.currentLang, options).format(date);
		} else {
			return null;
		}
	}

	processEntries() {
		this.servicedataFieldGroups.forEach(fg => {
			fg.fields.forEach(field => {
				if (field.type == 'date') {
					if (this.serviceProcessData && this.serviceProcessData[field.key] != null) {
						this.serviceProcessData[field.key] = this.parseDate(new Date(this.serviceProcessData[field.key]), field.showTime);
					}
				}
			});
		});

		//this.assemblyFieldGroups.forEach(fg => {
		//	fg.fields.forEach(field => {
		//		if (field.type == 'date') {
		//			if (this.assemblyData && this.assemblyData[field.key] != null) {
		//				this.assemblyData[field.key] = this.parseDate(new Date(this.assemblyData[field.key]), field.showTime);
		//			}
		//		}
		//	});
		//});

		this.vehicleFieldGroups.forEach(fg => {
			fg.fields.forEach(field => {
				if (field.type == 'date') {
					if (this.vehicle && this.vehicle[field.key] != null) {
						this.vehicle[field.key] = this.parseDate(new Date(this.vehicle[field.key]), field.showTime);
					}
				}
			});
		});
	}

	refreshAttachments() {
		this.crudService.getAllAttachments('fahrzeug', this.id).then(response => {
			this.attachments = response;
		});
	}

	setReceiveMessagesFlag() {
		this.raiseLoading();
		this.crudService.setVehicleReceiveMessagesFlag(this.id).then(res => {
			this.loadServiceData();
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
				}
			})
		}).finally(() => {
			this.lowerLoading();
		});
	}

	detailComponentHistory(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntryComponentHistory.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntryComponentHistory.ds_this_id);
		} else {
			this.router.navigate([this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntryComponentHistory.ds_this_id]);
		}
	}

	detailProcessTasks(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.tasksUrl + '/' + PATH.DETAIL + '/' + this.selectedEntryProcessTasks.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.tasksUrl + '/' + PATH.DETAIL + '/' + this.selectedEntryProcessTasks.ds_this_id);
		} else {
			this.router.navigate([this.tasksUrl + '/' + PATH.DETAIL + '/' + this.selectedEntryProcessTasks.ds_this_id]);
		}
	}

	editProcessTasks(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.tasksUrl + '/' + PATH.EDIT + '/' + this.selectedEntryProcessTasks.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.tasksUrl + '/' + PATH.EDIT + '/' + this.selectedEntryProcessTasks.ds_this_id);
		} else {
			this.router.navigate([this.tasksUrl + '/' + PATH.EDIT + '/' + this.selectedEntryProcessTasks.ds_this_id]);
		}
	}

	vehicleProcessDetailTask(dataRow: any) {
		if( dataRow && dataRow['ds_this_id']) {
			window.open('/#/' + this.tasksUrl + '/' + PATH.DETAIL + '/' + dataRow['ds_this_id']);
		}
	}

	vehicleProcessEditTask(dataRow: any) {
		if( dataRow && dataRow['ds_this_id']) {
			window.open('/#/' + this.tasksUrl + '/' + PATH.EDIT + '/' + dataRow['ds_this_id']);
		}
	}

	getComponentHistory() {
		if( !this.isVisy ) {
			return;
		}
		//this.messageService.clear('refresh');
		this.raiseLoading();
		this.crudService.getVehicleComponentHistoryByVehicle(this.id).then(res => {
			this.entriesComponentHistory = res;
			this.countComponentHistory = this.entriesComponentHistory.length;
			this.initTableComponentHistory();
			//this.resizeTableWidthFromContentComponentHistory(true, this.tableStateComponentHistory);
			setTimeout(() => this._onResize(), 1000);
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: this.msgTimeoutError });
			});
		}).finally(() => {
			this.lowerLoading();
		});
	}

	tabChanged(event) {
		this.currentTabIndex = event.index;
		//this.initTableComponentHistory();
		//this.initTableProcessTasks();
		//this.resizeTableWidthFromContentComponentHistory(false, this.tableStateComponentHistory);
		//this.resizeTableWidthFromContentProcessTasks(false, this.tableStateProcessTasks);

		if( this.isTableVisibleProcessTasks() ) {
			setTimeout(() => this.loadVehicleProcessData(false), 0);
		} else if( this.isTableVisibleComponentHistory() ) {
			setTimeout(() => this._onResize(), 1000);
		} else if( this.isTabVisibleProcessSchedule() ) {
			setTimeout(() => this.initVehicleProcessDataSmart(), 0);
		}
	}

	isTableVisibleComponentHistory() {
		return this.currentTabIndex === 0;
		//return true;
	}

	isTableVisibleProcessTasks() {
		return this.currentTabIndex === 1;
		//return true;
	}

	isTabVisibleProcessSchedule() {
		return this.currentTabIndex === 2;
	}

	exportPDFComponentHistory() {
		this.exportService.exportPDF(this.translate.instant(this.tableStateNameComponentHistory), this.tableComponentHistory.value, this.colsComponentHistory);
	}

	exportXLSXComponentHistory() {
		this.exportService.exportXLSX(this.translate.instant(this.tableStateNameComponentHistory), this.tableComponentHistory.value, this.colsComponentHistory);
	}

	initTableComponentHistory() {
		if( this.isTableVisibleComponentHistory() ) {
			this.contentHeightComponentHistory = 0;
			setTimeout(() => {
				const detailDiv = document.getElementById('details');
				if (detailDiv) {
					try {
						if( this.elRef && this.elRef.nativeElement ) {
							this.contentHeightComponentHistory = this.elRef.nativeElement.parentElement.offsetHeight - 320 - detailDiv.offsetHeight + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
							if (this.entriesComponentHistory && this.entriesComponentHistory.length > 0) {
								let entryHeight;
								if (this.entriesComponentHistory.length < 5) {
									entryHeight = this.minTableHeight + (this.entriesComponentHistory.length * 45);
								} else {
									entryHeight = this.minTableHeight + (5 * 45);
								}
								if (entryHeight > this.contentHeightComponentHistory) {
									this.contentHeightComponentHistory = entryHeight;
								}
							} else {
								this.contentHeightComponentHistory = this.minTableHeight;
							}
							this.redrawTableComponentHistory();
						}
					} catch {}
				}
			}, 0);
			this.resizeTableWidthFromContentComponentHistory(false, this.tableStateComponentHistory);
			//this._detectChanges();
		}
	}

	redrawTableComponentHistory(): void {
		this.visibleTableComponentHistory = false;
		setTimeout(() => this.visibleTableComponentHistory = true, 0);
	}
	_redrawCallbackComponentHistory() {
		this.visibleTableComponentHistory = true;
		//this.resizeTableWidthFromContentComponentHistory(false, this.tableStateComponentHistory);
	}

	redrawTableProcessTasks(): void {
		this.visibleTableProcessTasks = false;
		setTimeout(() => this.visibleTableProcessTasks = true, 0);
	}

	_redrawCallbackProcessTask() {
		this.visibleTableProcessTasks = true;
		//this.resizeTableWidthFromContentProcessTasks(false, this.tableStateProcessTasks);
	}

	redrawServiceDataFields(): void {
		this.visibleServiceDataFields = false;
		setTimeout(() => this.visibleServiceDataFields = true, 0);
	}

	isColFilteredComponentHistory(col) {
		let isFiltered = false;
		if (this.tableComponentHistory && this.tableComponentHistory.filters[col.key]) {
			Object.keys(this.tableComponentHistory.filters[col.key]).forEach(filter => {
				if (this.tableComponentHistory.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.tableStateComponentHistory && this.tableStateComponentHistory.filters[col.key]) {
			Object.keys(this.tableStateComponentHistory.filters[col.key]).forEach(filter => {
				if (this.tableStateComponentHistory.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilterComponentHistory(event) {
		//try {
		//	if( this.tableComponentHistory && this.tableComponentHistory.filteredValue != null && this.tableComponentHistory.filteredValue != undefined )
		//		this.countComponentHistory = this.tableComponentHistory.filteredValue.length;
		//	else if( this.entriesComponentHistory != null && this.entriesComponentHistory != undefined )
		//		this.countComponentHistory = this.entriesComponentHistory.length;
		//	else this.countComponentHistory = 0;
		//} catch {
		//	this.countComponentHistory = 0;
		//}
		//this.countComponentHistory = this.tableComponentHistory.filteredValue ? this.tableComponentHistory.filteredValue.length : this.entriesComponentHistory.length;
		this.countComponentHistory = this.getCountTableComponentHistorySafe();
	}

	onColReorderComponentHistory(event) {
		this.tableStateComponentHistory = JSON.parse(localStorage.getItem(this.tableStateNameComponentHistory));
		const columnWidths = this.tableStateComponentHistory.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.tableStateComponentHistory.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.tableStateNameComponentHistory, JSON.stringify(this.tableStateComponentHistory));
	}

	onColResizeComponentHistory(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.tableStateComponentHistory = JSON.parse(localStorage.getItem(this.tableStateNameComponentHistory));
		this.colsComponentHistory[index].width = Number(event.element.style.width.split('px')[0]);
		this.tableStateComponentHistory.columnWidths = (this.colsComponentHistory.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.tableStateNameComponentHistory, JSON.stringify(this.tableStateComponentHistory));
		
		this.resizeTableWidthComponentHistory();
	}

	resetTableComponentHistory() {
		this.tableComponentHistory.clearState();
		window.location.reload();
	}

	getCountTableComponentHistorySafe(): number {
		let count: number = 0;
		try {
			if(this.tableComponentHistory != null && this.tableComponentHistory != undefined && this.tableComponentHistory.filteredValue != null && this.tableComponentHistory.filteredValue != undefined ) {
				count = this.tableComponentHistory.filteredValue.length
			} else if( this.tableComponentHistory != null && this.tableComponentHistory != undefined && this.tableComponentHistory.value != null && this.tableComponentHistory.value != undefined ) {
				count = this.tableComponentHistory.value.length;
			} else if( this.entriesComponentHistory != null && this.entriesComponentHistory != undefined ) {
				count = this.entriesComponentHistory.length;
			} else count = 0;
		} catch {
			count = 0;
		}
		return count;
	}

	resizeTableWidthComponentHistory() {
		try {
			this.raiseLoading();
			this.retrieveTableStateComponentHistory(this.tableStateNameComponentHistory, this.tableStateComponentHistory);
			if (this.tableComponentHistory) {
				const tableElement = document.getElementById(this.tableComponentHistory.id);
				tableElement.style.width = '100%';
				const columnWidths = this.tableStateComponentHistory ? this.tableStateComponentHistory.columnWidths.split(',') : (this.colsComponentHistory.map(c => c.width)).concat([this.buttonColWidth]);
				const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
				const tableWidthOffset = tableElement.clientWidth - contentWidth;
				for (let index = 0; index < this.colsComponentHistory.length; index++) {
					this.colsComponentHistory[index].width = Number(columnWidths[index]);
				}
				if (tableWidthOffset > 0 && this.colsComponentHistory.length > 0) {
					this.colsComponentHistory[this.colsComponentHistory.length - 1].width += tableWidthOffset;
					let count = this.getCountTableComponentHistorySafe();
					if (this.contentHeightComponentHistory < (count) * this.tableComponentHistory.virtualRowHeight) {
						this.colsComponentHistory[this.colsComponentHistory.length - 1].width -= 10;
					}
				}

				document.getElementById(this.tableComponentHistory.id + '-table').style.width = this.colsComponentHistory.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
				document.getElementById(this.tableComponentHistory.id + '-table').style.minWidth = this.colsComponentHistory.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

				setTimeout(() => {
					if (this.tableStateComponentHistory) {
						localStorage.setItem(this.stateNameComponentHistory, JSON.stringify(this.tableStateComponentHistory));
						if (this.stateComponentHistory) {
							localStorage.setItem(this.stateNameComponentHistory, JSON.stringify(this.stateComponentHistory));
						}
					}
				}, 0);
			}
		} catch {
			//
		} finally {
			this.lowerLoading();
		}
	}

	retrieveTableStateComponentHistory(stateName, state?) {
		//if( this.isTableVisibleComponentHistory() ) {
			this.tableStateComponentHistory = state ? state : JSON.parse(localStorage.getItem(stateName));
			if (this.tableComponentHistory && (this.tableStateComponentHistory == undefined)) {
				// for storage of table state
				this.tableComponentHistory.saveState();
				// reload and parse
				this.tableStateComponentHistory = JSON.parse(localStorage.getItem(stateName));
			}
		//}
	}

	toggleColumnComponentHistory(event) {
		this.tableStateComponentHistory = JSON.parse(localStorage.getItem(this.tableStateNameComponentHistory));
		this.tableStateComponentHistory.columnOrder = event.value.map(c => c.key);
		this.tableStateComponentHistory.columnWidths = event.value.map(c => c.width);
		this.tableStateComponentHistory.columnWidths = this.tableStateComponentHistory.columnWidths.join(',');
		this.tableStateComponentHistory.columnWidths = this.tableStateComponentHistory.columnWidths + ',' + this.buttonColWidth;
		this.tableStateComponentHistory.tableWidth = (this.tableStateComponentHistory.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filtersComponentHistory = event.value.map(c => c.key);
		localStorage.setItem(this.tableStateNameComponentHistory, JSON.stringify(this.tableStateComponentHistory));
		this.resizeTableWidthComponentHistory();
	}

	// berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	resizeTableWidthFromContentComponentHistory(bForce, state?) {
		if( this.isTableVisibleComponentHistory() ) {
			var bResize = bForce;
			try {
				this.raiseLoading();
				// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
				this.tableStateComponentHistory = state ? state : JSON.parse(localStorage.getItem(this.tableStateNameComponentHistory));
				if (this.tableStateComponentHistory == undefined) {
					// force storage of table state
					this.tableComponentHistory.saveState();
					// reload state
					this.tableStateComponentHistory = JSON.parse(localStorage.getItem(this.tableStateNameComponentHistory));
					bResize = true;
				}

				if (this.tableComponentHistory && bResize) {
					// autosize columns
					const lTable = document.getElementById(this.tableComponentHistory.id);
					var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
					// für alle spalten, alle daten            
					this.colsComponentHistory.forEach(col => {
						let columnname = this.translate.instant('HEADERS.' + col.key);
						let maxStringLength = this.getTextLength(columnname, lTableFont);
						// filter symbol
						maxStringLength = maxStringLength + 80;
						maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
						if (this.entriesComponentHistory) {
							this.entriesComponentHistory.forEach(row => {
								let newLength = 0;
								if (col.type == 'date') {
									if (row[col.key] != undefined)
										newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
									else
										newLength = 0;
								} else {
									newLength = this.getTextLength(row[col.key], lTableFont);
								}
								// margins zur zelle
								newLength = newLength + 26;
								if (newLength > maxStringLength)
									maxStringLength = newLength;
							})
						}

						col.width = maxStringLength;
					});

					this.tableStateComponentHistory.columnWidths = (this.colsComponentHistory.map(c => c.width)).concat([this.buttonColWidth]).join(',');
					localStorage.setItem(this.tableStateNameComponentHistory, JSON.stringify(this.tableStateComponentHistory));
				}

				// standard funktion aufrufen
				this.resizeTableWidthComponentHistory();
			} catch {
				//
			} finally {
				this.lowerLoading();
				//this.redrawTableComponentHistory();
			}
		}
	}

	/////////////////////
	exportPDFProcessTasks() {
		this.exportService.exportPDF(this.translate.instant(this.tableStateNameProcessTasks), this.tableProcessTasks.value, this.colsProcessTasks);
	}

	exportXLSXProcessTasks() {
		this.exportService.exportXLSX(this.translate.instant(this.tableStateNameProcessTasks), this.tableProcessTasks.value, this.colsProcessTasks);
	}

	initTableProcessTasks() {
		if( this.isTableVisibleProcessTasks() ) {
			this.contentHeightProcessTasks = 0;
			setTimeout(() => {
				const detailDiv = document.getElementById('details');
				if (detailDiv) {
					try {
						if( this.elRef && this.elRef.nativeElement ) {
							this.contentHeightProcessTasks = this.elRef.nativeElement.parentElement.offsetHeight - 320 - detailDiv.offsetHeight + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
							if (this.vehicleProcessingData && this.vehicleProcessingData.linkedTasks && this.vehicleProcessingData.linkedTasks.length > 0) {
								let entryHeight;
								if (this.vehicleProcessingData.linkedTasks.length < 5) {
									entryHeight = this.minTableHeight + (this.vehicleProcessingData.linkedTasks.length * 45);
								} else {
									entryHeight = this.minTableHeight + (5 * 45);
								}
								if (entryHeight > this.contentHeightProcessTasks) {
									this.contentHeightProcessTasks = entryHeight;
								}
							} else {
								this.contentHeightProcessTasks = this.minTableHeight;
							}
							this.redrawTableProcessTasks();
						}
					} catch {}
				}
			}, 0);
			this.resizeTableWidthFromContentProcessTasks(false, this.tableStateProcessTasks);
			//this._detectChanges();
		}
	}

	isColFilteredProcessTasks(col) {
		let isFiltered = false;
		if (this.tableProcessTasks && this.tableProcessTasks.filters[col.key]) {
			Object.keys(this.tableProcessTasks.filters[col.key]).forEach(filter => {
				if (this.tableProcessTasks.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.tableStateProcessTasks && this.tableStateProcessTasks.filters[col.key]) {
			Object.keys(this.tableStateProcessTasks.filters[col.key]).forEach(filter => {
				if (this.tableStateProcessTasks.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilterProcessTasks(event) {
		//try {
		//	if( this.tableProcessTasks && this.tableProcessTasks.filteredValue != null && this.tableProcessTasks.filteredValue != undefined )
		//		this.countProcessTasks = this.tableProcessTasks.filteredValue.length;
		//	else if( this.vehicleProcessingData && this.vehicleProcessingData.linkedTasks != null && this.vehicleProcessingData.linkedTasks != undefined )
		//		this.countProcessTasks = this.vehicleProcessingData.linkedTasks.length;
		//	else this.countProcessTasks = 0;
		//} catch {
		//	this.countProcessTasks = 0;
		//}
		this.countProcessTasks = this.getCountTableProcessTasksSafe();
		//this.countProcessTasks = this.tableProcessTasks.filteredValue ? this.tableProcessTasks.filteredValue.length : this.vehicleProcessingData.linkedTasks.length;
	}

	onColReorderProcessTasks(event) {
		this.tableStateProcessTasks = JSON.parse(localStorage.getItem(this.tableStateNameProcessTasks));
		const columnWidths = this.tableStateProcessTasks.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.tableStateProcessTasks.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.tableStateNameProcessTasks, JSON.stringify(this.tableStateProcessTasks));
	}

	onColResizeProcessTasks(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.tableStateProcessTasks = JSON.parse(localStorage.getItem(this.tableStateNameProcessTasks));
		this.colsProcessTasks[index].width = Number(event.element.style.width.split('px')[0]);
		this.tableStateProcessTasks.columnWidths = (this.colsProcessTasks.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.tableStateNameProcessTasks, JSON.stringify(this.tableStateProcessTasks));

		this.resizeTableWidthProcessTasks();
	}

	resetTableProcessTasks() {
		this.tableProcessTasks.clearState();
		window.location.reload();
	}

	getCountTableProcessTasksSafe(): number {
		let count: number = 0;
		try {
			if(this.tableProcessTasks != null && this.tableProcessTasks != undefined && this.tableProcessTasks.filteredValue != null && this.tableProcessTasks.filteredValue != undefined ) {
				count = this.tableProcessTasks.filteredValue.length
			} else if( this.tableProcessTasks != null && this.tableProcessTasks != undefined && this.tableProcessTasks.value != null && this.tableProcessTasks.value != undefined ) {
				count = this.tableProcessTasks.value.length;
			} else if( this.vehicleProcessingData != null && this.vehicleProcessingData != undefined && this.vehicleProcessingData.linkedTasks != null && this.vehicleProcessingData.linkedTasks != undefined ) {
				count = this.vehicleProcessingData.linkedTasks.length;
			} else count = 0;
		} catch {
			count = 0;
		}
		return count;
	}

	resizeTableWidthProcessTasks() {
		try {
			this.raiseLoading();
			this.retrieveTableStateProcessTasks(this.tableStateNameProcessTasks, this.tableStateProcessTasks);
			if (this.tableProcessTasks) {
				const tableElement = document.getElementById(this.tableProcessTasks.id);
				tableElement.style.width = '100%';
				const columnWidths = this.tableStateProcessTasks ? this.tableStateProcessTasks.columnWidths.split(',') : (this.colsProcessTasks.map(c => c.width)).concat([this.buttonColWidth]);
				const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
				const tableWidthOffset = tableElement.clientWidth - contentWidth;
				for (let index = 0; index < this.colsProcessTasks.length; index++) {
					this.colsProcessTasks[index].width = Number(columnWidths[index]);
				}
				if (tableWidthOffset > 0 && this.colsProcessTasks.length > 0) {
					this.colsProcessTasks[this.colsProcessTasks.length - 1].width += tableWidthOffset;
					let count = this.getCountTableProcessTasksSafe();
					if (this.contentHeightProcessTasks < (count) * this.tableProcessTasks.virtualRowHeight) {
						this.colsProcessTasks[this.colsProcessTasks.length - 1].width -= 10;
					}
				}

				document.getElementById(this.tableProcessTasks.id + '-table').style.width = this.colsProcessTasks.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
				document.getElementById(this.tableProcessTasks.id + '-table').style.minWidth = this.colsProcessTasks.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

				setTimeout(() => {
					if (this.tableStateProcessTasks) {
						localStorage.setItem(this.stateNameProcessTasks, JSON.stringify(this.tableStateProcessTasks));
						if (this.stateProcessTasks) {
							localStorage.setItem(this.stateNameProcessTasks, JSON.stringify(this.stateProcessTasks));
						}
					}
				}, 0);
			}
		} catch {
			//
		} finally {
			this.lowerLoading();
		}
	}

	retrieveTableStateProcessTasks(stateName, state?) {
		//if( this.isTableVisibleProcessTasks() ) {
			this.tableStateProcessTasks = state ? state : JSON.parse(localStorage.getItem(stateName));
			if (this.tableProcessTasks && (this.tableStateProcessTasks == undefined)) {
				// for storage of table state
				this.tableProcessTasks.saveState();
				// reload and parse
				this.tableStateProcessTasks = JSON.parse(localStorage.getItem(stateName));
			}
		//}
	}

	toggleColumnProcessTasks(event) {
		this.tableStateProcessTasks = JSON.parse(localStorage.getItem(this.tableStateNameProcessTasks));
		this.tableStateProcessTasks.columnOrder = event.value.map(c => c.key);
		this.tableStateProcessTasks.columnWidths = event.value.map(c => c.width);
		this.tableStateProcessTasks.columnWidths = this.tableStateProcessTasks.columnWidths.join(',');
		this.tableStateProcessTasks.columnWidths = this.tableStateProcessTasks.columnWidths + ',' + this.buttonColWidth;

		this.tableStateProcessTasks.tableWidth = (this.tableStateProcessTasks.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filtersProcessTasks = event.value.map(c => c.key);
		localStorage.setItem(this.tableStateNameProcessTasks, JSON.stringify(this.tableStateProcessTasks));
		this.resizeTableWidthProcessTasks();
	}

	// berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	resizeTableWidthFromContentProcessTasks(bForce, state?) {
		if( this.isTableVisibleProcessTasks() ) {
			var bResize = bForce;
			try {
				this.raiseLoading();
				// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
				this.tableStateProcessTasks = state ? state : JSON.parse(localStorage.getItem(this.tableStateNameProcessTasks));
				if (this.tableStateProcessTasks == undefined) {
					// force storage of table state
					this.tableProcessTasks.saveState();
					// reload state
					this.tableStateProcessTasks = JSON.parse(localStorage.getItem(this.tableStateNameProcessTasks));
					bResize = true;
				}

				if (this.tableProcessTasks && bResize) {
					// autosize columns
					const lTable = document.getElementById(this.tableProcessTasks.id);
					var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
					// für alle spalten, alle daten            
					this.colsProcessTasks.forEach(col => {
						let columnname = this.translate.instant('HEADERS.' + col.key);
						let maxStringLength = this.getTextLength(columnname, lTableFont);
						// filter symbol
						maxStringLength = maxStringLength + 80;
						maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
						if (this.vehicleProcessingData.linkedTasks) {
							this.vehicleProcessingData.linkedTasks.forEach(row => {
								let newLength = 0;
								if (col.type == 'date') {
									if (row[col.key] != undefined)
										newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
									else
										newLength = 0;
								} else {
									newLength = this.getTextLength(row[col.key], lTableFont);
								}
								// margins zur zelle
								newLength = newLength + 26;
								if (newLength > maxStringLength)
									maxStringLength = newLength;
							})
						}

						col.width = maxStringLength;
					});

					this.tableStateProcessTasks.columnWidths = (this.colsProcessTasks.map(c => c.width)).concat([this.buttonColWidth]).join(',');
					//this.tableProcessTasks.saveState();
					localStorage.setItem(this.tableStateNameProcessTasks, JSON.stringify(this.tableStateProcessTasks));
				}

				// standard funktion aufrufen
				this.resizeTableWidthProcessTasks();
			} catch {
				//
			} finally {
				this.lowerLoading();
				//this.redrawTableProcessTasks();
			}
		}
	}
	/////////////////////

	// wenn eine spalte noch ein special symbol im header hat
	adaptColumnSize(maxStringLength, columnkey) {
		return maxStringLength;
	}

	/**
	 * 
	 * @param container Hilfsmethode die das Flackern der Listenkopfzeile verhindert
	 * @param speedY 
	 * @returns 
	 */

	changeWheelSpeed(container, speedY) {
		if( container ) {
			var scrollY = 0;
			var handleScrollReset = function () {
				scrollY = container.scrollTop;
			};

			var handleMouseWheel = function (e) {
				e.preventDefault();
				scrollY += speedY * e.deltaY
				if (scrollY < 0) {
					scrollY = 0;
				} else {
					var limitY = container.scrollHeight - container.clientHeight;
					if (scrollY > limitY) {
						scrollY = limitY;
					}
				}
				container.scrollTop = scrollY;
			};

			var removed = false;
			container.addEventListener('mouseup', handleScrollReset, false);
			container.addEventListener('mousedown', handleScrollReset, false);
			container.addEventListener('mousewheel', handleMouseWheel, false);

			return function () {
				if (removed) {
					return;
				}
				container.removeEventListener('mouseup', handleScrollReset, false);
				container.removeEventListener('mousedown', handleScrollReset, false);
				container.removeEventListener('mousewheel', handleMouseWheel, false);
				removed = true;
			};
		}
	}

	async vehicleProcessAddDefaultTasks() : Promise<any> {
		if(!this.isVisy)
			return;

		if(this.vehicleProcessingData && this.vehicleProcessingData['ds_this_id']) {
			this.raiseLoading();
			this.crudService.getVehicleProcessDefaultTaskTypesOrdered().then(defaultTypes => {
				if( defaultTypes && defaultTypes.length > 0 ) {
					this.raiseLoading();
					this.crudService.getVehicleProcessTasksByFile(this.vehicleProcessingData['ds_this_id']).then(tasks => {
						let addTasks: Base[] = [];
						if( tasks && tasks.length > 0 ) {
							// abgleich, nur noch nicht vorhandene
							//defaultTypes.foreach(t => {
							for(let datarowType of defaultTypes) {
								if( datarowType && datarowType['ds_this_id'] && datarowType['bezeichnung'] ) {
									let matchFound = false;
									//for(let i = 0; i < tasks.length; ++i) {
									for(let vorhandenTask of tasks) {
										if(vorhandenTask && vorhandenTask['abschnitt_typ_id'] && vorhandenTask['abschnitt_typ_id'] === datarowType['ds_this_id'] ) {
											matchFound = true;
											break;
										}
									}
									if( !matchFound ) {
										let newTask: Base = {};
										newTask['fahrzeug_abwicklung_id'] = this.vehicleProcessingData['ds_this_id'];
										newTask['abschnitt_typ_id'] = datarowType['ds_this_id'];
										newTask.bezeichnung = datarowType['bezeichnung'];
										addTasks.push(newTask);
									}
								}
							};
						} else {
							// alle
							for(let datarowType of defaultTypes) {
								if( datarowType && datarowType['ds_this_id'] && datarowType['bezeichnung'] ) {
									let newTask: Base = {};
									newTask['fahrzeug_abwicklung_id'] = this.vehicleProcessingData['ds_this_id'];
									newTask['abschnitt_typ_id'] = datarowType['ds_this_id'];
									newTask.bezeichnung = datarowType['bezeichnung'];
									addTasks.push(newTask);
								}
							};
						}

						let addedTasks: number = 0;
						let errorAdding: number = 0;
						if( addTasks.length > 0 ) {
							this.raiseLoading();
							this.crudService.createProcessTaskEntries(addTasks).then(res => {
								if(res && res['countOk'] && res['countError']) {
									addedTasks = res['countOk'];
									errorAdding = res['countError'];
								} else {
									addedTasks = addTasks.length; // no detail information
								}
							}).catch((err) => {
								errorAdding = addTasks.length;
								throw err;
							}).finally(() => {
								this.lowerLoading();
								this.messageService.add({ severity: 'success', summary: 'Success', detail: addedTasks + ' new Task(s) added, errors on ' + errorAdding, life: 3000 });
								this.loadVehicleProcessData(true);
							});
						}
					}).catch(err => {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
						})
					}).finally(() => {
						this.lowerLoading();
					});
				}
			}).catch(err => {
				this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
				})
			}).finally(() => {
				this.lowerLoading();
			});
		}
	}

	vehicleProcessAddTask() : void {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicle.ds_this_id && this.vehicleProcessingData && this.vehicleProcessingData['ds_this_id'] ) {
			// Popup mit Fahrzeugauswahl, Datum von, Datum bis, Bemerkungen
			const ref = this.dialogService.open(VehicleAddProcesstaskDialogComponent, {
				//header: this.translate.instant('HEADERS.COMPONENT_SET_VEHICLE'),
				header: this.translate.instant('BREADCRUMBS.CREATE'),
				width: '70%',
				data: { abwicklung_id: this.vehicleProcessingData['ds_this_id'] }
			});

			ref.onClose.subscribe((success) => {
				if (success) {
					this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Task added', life: 1500 });
					this.loadVehicleProcessData(true);
				}
			});
		}
	}

	vehicleProcessQuitTask(datarowTask: any) {
		//optionGroup1.set('mitarbeiter_id', { type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblMitarbeiter', values: [] });
		//optionGroup1.set('geoeffnet', { type: 'date_noTimespan' });
		//optionGroup1.set('geschlossen', { type: 'date_noTimespan' });
		//optionGroup1.set('bemerkungen', { type: 'text' });
		const ref = this.dialogService.open(VehicleCheckProcessTaskDialogComponent, {
			header: this.translate.instant('HEADERS.CHECK_PROCESS_TASK'),
			width: '70%',
			data: { buttonLabel: 'Vorgang Abschließen', entryData: datarowTask }
		});

		ref.onClose.subscribe((filters) => {
			if (filters) {
				this.loading += 1;
				let mitarbeiter_id = filters['mitarbeiter_id'];
				let von = filters['geoeffnet'];
				let bis = filters['geschlossen'];
				let bemerkungen = filters['bemerkungen'];

				if( von ) {
					von = new Date(von);
				}
				if( bis ) {
					bis = new Date(bis);
				}

				//console.log(mitarbeiter_id);
				//console.log(von);
				//console.log(bis);
				//console.log(bemerkungen);
				//console.log(datarowTask);

				datarowTask['abgezeichnet_mitarbeiter_id'] = mitarbeiter_id;
				datarowTask['geoeffnet_datetime'] = von;
				datarowTask['geschlossen_datetime'] = bis;
				datarowTask['bemerkungen'] = bemerkungen;

				this.crudService.editEntry('TblFahrzeugAbwicklungAbschnitt', datarowTask).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
					this.loadVehicleProcessData(true);
				}).catch(err => {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			} else {
				this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
			}
		});
	}

	vehicleComponentDeleteWithLink(entry) {
		//console.log(entry);
		if( entry && entry['komponente_id'] && entry['fahrzeug_akte_id'] ) {
			this.confirmationService.confirm({
				message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
				header: this.translate.instant('CONFIRMATION.CONFIRM'),
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: this.translate.instant('CONFIRMATION.YES'),
				rejectLabel: this.translate.instant('CONFIRMATION.NO'),
				accept: () => {
					this.raiseLoading();
					this.crudService.deleteComponent(entry['komponente_id']).then(delComponentRes => {
						//this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
						this.raiseLoading();
						this.crudService.deleteComponentVehicleLinkEx(entry['komponente_id'], entry['fahrzeug_akte_id']).then(delLinksRes => {
							// OUTPUT:
							//    res["countToDeleteInTotal"]
							//    res["countDeletions"]
							//    res["countErrors"]
							if(delLinksRes) {
								console.log(delLinksRes);
							}
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
						}).catch(err => {
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
								}
							})
						}).finally(() => {
							this.lowerLoading();
							this.getComponentHistory();
						});
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
							}
						})
					}).finally(() => {
						this.lowerLoading();
					});
				}
			});
		}
	}

	vehicleProcessDeleteTask(entry) {
		//console.log(entry);
		if( entry && entry['ds_this_id'] ) {
			this.confirmationService.confirm({
				message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
				header: this.translate.instant('CONFIRMATION.CONFIRM'),
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: this.translate.instant('CONFIRMATION.YES'),
				rejectLabel: this.translate.instant('CONFIRMATION.NO'),
				accept: () => {
					this.raiseLoading();
					this.crudService.deleteProcessTaskEntry(entry['ds_this_id']).then(success => {
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
							}
						})
					}).finally(() => {
						this.lowerLoading();
						this.loadVehicleProcessData(true);
					});
				}
			});
		}
	}

	komponenteZuweisung() : void {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicle.ds_this_id ) {
			// Popup mit Fahrzeugauswahl, Datum von, Datum bis, Bemerkungen
			const ref = this.dialogService.open(VehicleAddComponentDialogComponent, {
				//header: this.translate.instant('HEADERS.COMPONENT_SET_VEHICLE'),
				header: this.translate.instant('BREADCRUMBS.CREATE'),
				width: '70%'
			});

			ref.onClose.subscribe((results) => {
				if (results) {
					let callParams = {
						komponente_id: results['componentId'],
						fahrzeug_id: this.vehicle.ds_this_id,
						datum_von: results['dateFrom'],
						datum_bis: results['dateTo'],
						bemerkungen: results['comment']
					}
					this.raiseLoading();
					this.crudService.addVehicleComponent(callParams).then(res => {
						if(res && res >= 1) {
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							this.getComponentHistory();
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
						}
					}).catch(err => {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
						})
					}).finally(() => {
						this.lowerLoading();
					});
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
				}
			});
		}
	}

	komponentenMultiZuweisung() : void {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicle.ds_this_id ) {
			// Popup mit Fahrzeugauswahl, Datum von, Datum bis, Bemerkungen
			const ref = this.dialogService.open(VehicleAddMultipleComponentsDialogComponent, {
				//header: this.translate.instant('HEADERS.COMPONENT_SET_VEHICLE'),
				header: this.translate.instant('BREADCRUMBS.CREATE'),
				width: '70%'
			});

			ref.onClose.subscribe((results) => {
				if(results && results['neu_angelegte'] && results['neu_angelegte'].length > 0) {
					let callParams = {
						komponente_ids: results['neu_angelegte'],
						fahrzeug_id: this.vehicle.ds_this_id,
						datum_von: results['dateFrom']
					}
					this.raiseLoading();
					this.crudService.addVehicleComponents(callParams).then(res => {
						if(res && res >= 1) {
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							this.getComponentHistory();
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
						}
					}).catch(err => {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
						})
					}).finally(() => {
						this.lowerLoading();
					});
				}
			});
		}
	}

	komponentenAustausch(): void {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicle && this.vehicle.ds_this_id && this.selectedEntryComponentHistory && this.selectedEntryComponentHistory.ds_this_id ) {
			// Popup mit Fahrzeugauswahl, Datum von, Datum bis, Bemerkungen
			const ref = this.dialogService.open(VehicleReplaceComponentDialogComponent, {
				//header: this.translate.instant('HEADERS.COMPONENT_SET_VEHICLE'),
				header: this.translate.instant('BREADCRUMBS.CREATE'),
				width: '70%'
			});

			ref.onClose.subscribe((results) => {
				// aus VehicleReplaceComponentDialogComponent
				//if(this.ref && this.entry) {
				//	this.ref.close({
				//		bemerkungen_ausbau: this.entry['bemerkungen_ausbau'],
				//		bemerkungen_einbau: this.entry['bemerkungen_einbau'],
				//		COMPONENT_LINK_FROM: this.entry['COMPONENT_LINK_FROM']
				//	});
				//} else this.ref.close({isEmpty: true});
				let bDataOk: boolean = false;
				if(results) {
					if(results['isEmpty'] != null && results['isEmpty'] != undefined) {
						bDataOk = false;
					} else {
						if(results['COMPONENT_LINK_FROM'] != null && results['COMPONENT_LINK_FROM'] != undefined) {
							if(results['serial_no'] != null && results['serial_no'] != undefined && results['serial_no'].length > 0) {
								bDataOk = true;
							}
						}
					}
				}

				if(results['bemerkungen_ausbau'] != null && results['bemerkungen_ausbau'] != undefined && results['bemerkungen_ausbau'].length > 0)
					this.selectedEntryComponentHistory['bemerkungen'] = results['bemerkungen_ausbau'];
				this.selectedEntryComponentHistory['zugeordnet_bis_date'] = new Date(results['COMPONENT_LINK_FROM']);

				this.raiseLoading();
				// alten eintrag "schließen"...
				this.crudService.editEntry('TblFahrzeugAkteKomponenteLink', this.selectedEntryComponentHistory).then(resEdit => {
					this.lowerLoading();
					let newEntry: Base = {};
					newEntry.bezeichnung = this.selectedEntryComponentHistory['FREMD_komponente_bezeichnung'];
					newEntry['komponente_typ_id'] = this.selectedEntryComponentHistory['FREMD_komponente_typ_id'];
					newEntry['serial_no'] = results['serial_no'];
					this.raiseLoading();
					// neuen eintrag anlegen...
					this.crudService.createEntry('TblKomponente', newEntry).then(resCreate => {
						this.lowerLoading();
						// ...und verlinken...
						let callParams = {
							komponente_id: resCreate, // resCreate['ds_this_id'], // Create von TblKomponente gibt die neue id zurück
							fahrzeug_id: this.vehicle.ds_this_id,
							datum_von: new Date(results['COMPONENT_LINK_FROM']),
							datum_bis: null,
							bemerkungen: results['bemerkungen_einbau']
						}
						this.raiseLoading();
						this.crudService.addVehicleComponent(callParams).then(res => {
							this.lowerLoading();
							if(res && res >= 1) {
								this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
								this.getComponentHistory();
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
							}
						}).catch(err => {
							this.lowerLoading();
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
							err.error.forEach(e => {
								this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
							})
						});
					}).catch(errCreate => {
						this.lowerLoading();
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
						errCreate.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
						})
					});
				}).catch(errEdit => {
					this.lowerLoading();
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
					errEdit.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
					})
				});
			});
		}
	}

	getVehicleProcessScheduleAll() {
		this.getVehicleProcessScheduleSupply();
		this.getVehicleProcessScheduleDispatch();
	}

	addVehicleProcessScheduleSupply() {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicleProcessingData && this.vehicleProcessingData['ds_this_id']) {
			const ref = this.dialogService.open(VehicleAddProcessScheduleDialogComponent, {
				header: this.translate.instant('HEADERS.PROCESS_SCHEDULE_SUPPLY_DETAILS'),
				width: '70%',
				data: { buttonLabel: 'Termindetails speichern' }
			});
	
			ref.onClose.subscribe((selectedData) => {
				if (selectedData) {
					let mitarbeiter_id = selectedData['mitarbeiter_id'];
					let angelegt = selectedData['angelegt'];
					let geplant = selectedData['geplant'];
					let bemerkungen = selectedData['bemerkungen'];
	
					if( angelegt ) {
						angelegt = new Date(angelegt);
					} else angelegt = new Date();

					if( geplant ) {
						geplant = new Date(geplant);
					}

					if( geplant ) {
						this.raiseLoading();
						this.crudService.addVehicleProcessScheduleSupplyEntry(this.vehicleProcessingData['ds_this_id'], geplant, angelegt, mitarbeiter_id, bemerkungen).then(res => {
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							this.getVehicleProcessScheduleSupply();
						}).catch(err => {
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
								}
							})
						}).finally(() => {
							this.lowerLoading();
						});
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
					}
				}
			});
		}
	}

	addVehicleProcessScheduleDispatch() {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicleProcessingData && this.vehicleProcessingData['ds_this_id']) {
			const ref = this.dialogService.open(VehicleAddProcessScheduleDialogComponent, {
				header: this.translate.instant('HEADERS.PROCESS_SCHEDULE_DISPATCH_DETAILS'),
				width: '70%',
				data: { buttonLabel: 'Termindetails speichern' }
			});
	
			ref.onClose.subscribe((selectedData) => {
				if (selectedData) {
					let mitarbeiter_id = selectedData['mitarbeiter_id'];
					let angelegt = selectedData['angelegt'];
					let geplant = selectedData['geplant'];
					let bemerkungen = selectedData['bemerkungen'];
	
					if( angelegt ) {
						angelegt = new Date(angelegt);
					} else angelegt = new Date();

					if( geplant ) {
						geplant = new Date(geplant);
					}

					if( geplant ) {
						this.raiseLoading();
						this.crudService.addVehicleProcessScheduleDispatchEntry(this.vehicleProcessingData['ds_this_id'], geplant, angelegt, mitarbeiter_id, bemerkungen).then(res => {
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							this.getVehicleProcessScheduleDispatch();
						}).catch(err => {
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
								}
							})
						}).finally(() => {
							this.lowerLoading();
						});
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
					}
				}
			});
		}
	}

	showVehicleProcessScheduleHistorySupply() {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicleProcessingData && this.vehicleProcessingData['ds_this_id']) {
			let loweredLoading = false;
			this.raiseLoading();
			this.crudService.getVehicleProcessScheduleSupplyDataHistoryByFile(this.vehicleProcessingData['ds_this_id']).then(res => {
				loweredLoading = true;
				this.lowerLoading();
				const ref = this.dialogService.open(VehicleProcessScheduleHistoryListComponent, {
					header: this.translate.instant('HEADERS.PROCESS_SCHEDULE_HISTORY'),
					width: '70%',
					data: { dataset: res }
				});

				ref.onClose.subscribe(needsReload => {
					if( needsReload && needsReload === true ) {
						this.getVehicleProcessScheduleSupply();
						this.showVehicleProcessScheduleHistorySupply();
					}
				});
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
					}
				})
			}).finally(() => {
				if( !loweredLoading ) {
					this.lowerLoading();
				}
			});
		}
	}

	showVehicleProcessScheduleHistoryDispatch() {
		if( !this.isVisy ) {
			return;
		}
		if( this.vehicleProcessingData && this.vehicleProcessingData['ds_this_id']) {
			let loweredLoading = false;
			this.raiseLoading();
			this.crudService.getVehicleProcessScheduleDispatchDataHistoryByFile(this.vehicleProcessingData['ds_this_id']).then(res => {
				loweredLoading = true;
				this.lowerLoading();
				const ref = this.dialogService.open(VehicleProcessScheduleHistoryListComponent, {
					header: this.translate.instant('HEADERS.PROCESS_SCHEDULE_HISTORY'),
					width: '70%',
					data: { dataset: res }
				});

				ref.onClose.subscribe(needsReload => {
					if( needsReload && needsReload === true ) {
						this.getVehicleProcessScheduleDispatch();
						this.showVehicleProcessScheduleHistoryDispatch();
					}
				});
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
					}
				})
			}).finally(() => {
				if( !loweredLoading ) {
					this.lowerLoading();
				}
			});
		}
	}

	getVehicleProcessScheduleSupply() {
		if( !this.isVisy ) {
			return;
		}
		this.vehicleProcessScheduleSupply = null;
		if( this.vehicleProcessingData && this.vehicleProcessingData['ds_this_id']) {
			this.raiseLoading();
			this.crudService.getVehicleProcessScheduleSupplyDataLatestByFile(this.vehicleProcessingData['ds_this_id']).then(res => {
				if(res && res.length > 0) {
					this.vehicleProcessScheduleSupply = res[0];
					//console.log(this.vehicleProcessScheduleSupply);
				}
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
					}
				})
			}).finally(() => {
				this.lowerLoading();
			});
		}
	}

	getVehicleProcessScheduleDispatch() {
		if( !this.isVisy ) {
			return;
		}
		this.vehicleProcessScheduleDispatch = null;
		if( this.vehicleProcessingData && this.vehicleProcessingData['ds_this_id']) {
			this.raiseLoading();
			this.crudService.getVehicleProcessScheduleDispatchDataLatestByFile(this.vehicleProcessingData['ds_this_id']).then(res => {
				if( res && res.length > 0 ) {
					this.vehicleProcessScheduleDispatch = res[0];
				}
			}).catch(err => {
				this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
				})
			}).finally(() => {
				this.lowerLoading();
			});
		}
	}

	getTextLength(text: string, styleFont: string) {
		let result = undefined;
		try {
			if( this.emptyCanvas && this.emptyCanvas.nativeElement ) {
				const ctx = this.emptyCanvas.nativeElement.getContext('2d');
				ctx.font = styleFont;
				const textMetrics = ctx.measureText(text);
				result = Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
			}
		} catch {
			result = 0;
		}
		return result;
	}
	// #endregion Table

	raiseLoading() {
		this.loading += 1;
		//console.log('raised loading to ' + this.loading);
	}

	lowerLoading() {
		this.loading -= 1;
		//console.log('lowered loading to ' + this.loading);
	}

	_detectChanges(): void {
		try {
			this.changeDetectorRef.detectChanges();
		} catch(err) {
			//console.log(err);
			//err.error.forEach(e => {
			//	if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
			//		this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
			//	} else {
			//		this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
			//	}
			//})
		}
		//finally {
		//	console.log('this.changeDetectorRef.detectChanges()');
		//}
	}

	triggerLogboxPubkeyUpload(): void {
		this.loading += 1;
		this.crudService.startLogboxPubkeyUploadRequest(this.vehicle).then(() => {
			this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.LOGBOX_PUBKEY_REGISTRATION_OPENED'), life: 3000 });
			this.getEntry();
		}).catch(err => {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
			})
		}).finally(() => {
			this.loading -= 1;
		})
	}

	openPubkeyApprovalPopup(): void {
		// benötigt:
		//  - MD5 Logbox Public Key
		//  - MD5 Server Public Key
		//  - Timestamp Logbox Public Key

		if(this.vehicle && this.vehicle.ds_this_id && this.vehicle.ds_this_id > 0 ) {
			//console.log('timestamp logbox pubkey RAW');
			//console.log(this.vehicle['VIRTUAL_logbox_pubkey_stored_timestamp']);

			let strFingerprintLogboxPubkey: string = null;
			let strFingerprintServerPubkey: string = null;
			//let strLogboxPubkeyTimestamp: string = this.vehicle['VIRTUAL_logbox_pubkey_stored_timestamp'] ? this.parseDate(new Date(this.vehicle['VIRTUAL_logbox_pubkey_stored_timestamp']), true) : null;
			let strLogboxPubkeyTimestamp: string = this.vehicle['VIRTUAL_logbox_pubkey_stored_timestamp'] ? this.vehicle['VIRTUAL_logbox_pubkey_stored_timestamp'] : null;

			if(strLogboxPubkeyTimestamp && strLogboxPubkeyTimestamp.trim().length > 0) {
				//console.log('timestamp logbox pubkey');
				//console.log(strLogboxPubkeyTimestamp);

				this.loading += 1;
				this.crudService.getFingerpringOfLogboxPubkey(this.vehicle.ds_this_id).then(fingerprintLogboxPubkey => {
					//console.log('fingerprint logbox pubkey');
					//console.log(fingerprintLogboxPubkey);
					strFingerprintLogboxPubkey = fingerprintLogboxPubkey && fingerprintLogboxPubkey['fingerprint'] ? fingerprintLogboxPubkey['fingerprint'].trim() : null;
					this.loading += 1;
					this.accountService.logboxPubkeyApiGetFingerprintServerPubkey().then(fingerprintServerPubkey => {
						//console.log('fingerprint server pubkey');
						//console.log(fingerprintServerPubkey);
						strFingerprintServerPubkey = fingerprintServerPubkey && fingerprintServerPubkey['fingerprint'] ? fingerprintServerPubkey['fingerprint'].trim() : null;

						//
						let approvalData: any [] = [];
						approvalData.push({
							fieldkey: 'fingerprint_logbox_pubkey',
							value: strFingerprintLogboxPubkey
						});
						approvalData.push({
							fieldkey: 'fingerprint_server_pubkey',
							value: strFingerprintServerPubkey
						});
						approvalData.push({
							fieldkey: 'timestamp_logbox_pubkey',
							value: strLogboxPubkeyTimestamp
						});
						const ref = this.dialogService.open(LogboxPubkeyApprovalDialogComponent, {
							header: this.translate.instant('Schlüsselaustausch Prüfen/Abnahme'),
							width: '70%',
							data: { buttonLabel: 'Bestätigen', manualData1: approvalData }
						});
				
						ref.onClose.subscribe((data) => {
							if (data) {
								this.checkApprovalCommit(data);
							}
						});

					}).catch(err => {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN'), detail: this.translate.instant('ERRORCODE.UNKNOWN'), life: 5000 });
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
						});
					}).finally(() => {
						this.loading -= 1;
					});
				}).catch(err => {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN'), detail: this.translate.instant('ERRORCODE.UNKNOWN'), life: 5000 });
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
					});
				}).finally(() => {
					this.loading -= 1;
				});
			}
		}
	}

	checkApprovalCommit(data: any): void {
		//console.log(data);
		// beispiel
		//{
		//	"fingerprint_logbox_pubkey": "EAB1A9C71B71B8BD747579A68F894050",
		//	"fingerprint_server_pubkey": "EAB1A9C71B71B8BD747579A68F894050",
		//	"timestamp_logbox_pubkey": "13.03.2025, 11:43",
		//	"approved": true,
		//	"2fa_code": "12345"
		//}
		if(!data || !data['approved']) {
			this.messageService.add({ severity: 'info', summary: 'Abgelehnt', detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.NOT_YET_APPROVED'), life: 7000 });
		} else {
			if(!data['approved']) {
				this.messageService.add({ severity: 'info', summary: 'Abgelehnt', detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.NOT_YET_APPROVED'), life: 7000 });
			} else {
				// abnahme angehakt, weiter gehts...
				if(!data['2fa_code'] || data['2fa_code'].trim().length < 1) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.Unauthorized'), detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.REQUIRES_TWOFACTOR_CODE'), life: 7000 });
				} else {
					this.loading += 1;
					this.accountService.check2faEnabled('current').then(result2faEnabled => {
						if(result2faEnabled && result2faEnabled['has2fa'] && result2faEnabled['has2fa'] === true) {
							this.loading += 1;
							this.accountService.check2faCode(data['2fa_code'].trim()).then(result2favalidation => {
								//if(result2favalidation && result2favalidation === data['2fa_code'].trim()) {
								if(result2favalidation && result2favalidation['code'] && result2favalidation['code'] === data['2fa_code'].trim()) {
									this.accountService.getUserIdAndName().then(userIdAndName => {
										this.loading += 1;
										this.crudService.getVehicle(this.id).then(resReloadVehicle => {
											let tsNow = new Date();
											resReloadVehicle.approval_pubkey_logbox_userid = userIdAndName['userId'];
											resReloadVehicle.approval_pubkey_logbox_username = userIdAndName['userName'];
											resReloadVehicle.approval_pubkey_logbox_timestamp = new Date(Date.UTC(tsNow.getFullYear(), tsNow.getMonth(), tsNow.getDay(), tsNow.getHours(), tsNow.getMinutes(), tsNow.getSeconds(), tsNow.getMilliseconds()));
											resReloadVehicle.approval_pubkey_logbox_fingerprint = data['fingerprint_logbox_pubkey'];
											resReloadVehicle.approval_pubkey_server_fingerprint = data['fingerprint_server_pubkey'];
											this.loading += 1;
											this.crudService.editVehicleInLogboxPubkeyApprovalContext(resReloadVehicle).then(editResult => {
												this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
												this.getEntry();
											}).catch(err => {
												this.vehicle.approval_pubkey_logbox_userid = null;
												this.vehicle.approval_pubkey_logbox_username = null;
												this.vehicle.approval_pubkey_logbox_timestamp = null;
												this.vehicle.approval_pubkey_logbox_fingerprint = null;
												this.vehicle.approval_pubkey_server_fingerprint = null;
												this.messageService.add({ severity: 'error', summary: 'ERRORS.Error', detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.SAVE_ERROR_ON_APPROVAL'), life: 7000 });
												err.error.forEach(e => {
													this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
												});
											}).finally(() => {
												this.loading -= 1;
											});
										}).catch(err => {
											this.messageService.add({ severity: 'error', summary: 'ERRORS.Error', detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.SAVE_ERROR_ON_APPROVAL'), life: 7000 });
											err.error.forEach(e => {
												this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
											});
										}).finally(() => {
											this.loading -= 1;
										});
									}).catch(err => {
										this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.Unauthorized'), detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.USERNAME_USERID_UNKNOWN'), life: 7000 });
										err.error.forEach(e => {
											this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
										});
									});
								}
							}).catch(err => {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.Unauthorized'), detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.DENIED_TWOFACTOR_CODE'), life: 7000 });
								err.error.forEach(e => {
									this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
								});
							}).finally(() => {
								this.loading -= 1;
							});
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.Unauthorized'), detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.REQUIRES_TWOFACTOR_ACTIVATED'), life: 7000 });
						}
					}).catch(err => {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN'), detail: this.translate.instant('ERRORCODE.UNKNOWN'), life: 5000 });
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
						});
					}).finally(() => {
						this.loading -= 1;
					});
				}
			}
		}
	}

	revokeLogboxPublicKey() {
		if(this.vehicle && this.vehicle.ds_this_id && this.vehicle.ds_this_id >= 1) {
			if(this.vehicle['VIRTUAL_has_logbox_pubkey_data'] && this.vehicle['VIRTUAL_has_logbox_pubkey_data'] === true) {
				this.confirmationService.confirm({
					message: this.translate.instant('CONFIRMATION.LOGBOX_PUBKEY_EXCHANGE.REVOKE_LOGBOX_PUBKEY'),
					header: this.translate.instant('CONFIRMATION.CONFIRM'),
					icon: 'pi pi-exclamation-triangle',
					acceptLabel: this.translate.instant('CONFIRMATION.YES'),
					rejectLabel: this.translate.instant('CONFIRMATION.NO'),
					accept: () => {
						this.raiseLoading();
						this.accountService.check2faEnabled('current').then(result2FAenabled => {
							if(result2FAenabled && result2FAenabled['has2fa'] && result2FAenabled['has2fa'] === true) {
								// jetzt: Async Funktion für Ausführung triggern, welche von der korrekten 2fa Code Eingabe abhängt
								this.doConditionalRevoke();
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.Unauthorized'), detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.REQUIRES_TWOFACTOR_ACTIVATED'), life: 7000 });
							}
						}).catch(err => {
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
								}
							})
						}).finally(() => {
							this.lowerLoading();
						});
					}
				});
			}
		}
	}

	async doConditionalRevoke() {
		var twoFactorCodeOk = await this.twoFactorCheck.show();
		if(twoFactorCodeOk) {
			// Ok, machen!
			this.raiseLoading();
			this.crudService.revokeVehicleLogboxPubkey(this.vehicle.ds_this_id).then(res => {
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
				this.getEntry();
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: this.msgTimeoutError });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: this.msgTimeoutError });
					}
				})
			}).finally(() => {
				this.lowerLoading();
			});
		} else {
			// abgelehnt, Meldung
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.Unauthorized'), detail: this.translate.instant('ERRORMSG.LOGBOX_PUBKEY_EXCHANGE.CANCEL_OF_TWOFACTOR'), life: 3000 });
		}
	}
}
