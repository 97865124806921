import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals, VIONSessionStorage } from '@app/common/global_variables';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { MoveGarbageCanDialogComponent } from '../move_dialog/dialog.component';
import { ZPLPrintService } from '@app/services/zplprint.service';
import { TblGefaessMoveToHaldeDialogComponent } from './move_to_halde_dialog/move_to_halde_dialog.component';
import { PopupFromToReasonComponent } from '@app/common/popup/popupFromToReason.component'
import { tbl_objekt_flags } from '@app/models/tbl_objekt_flags';


@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblGefaessDetailComponent extends DetailComponent {
	@ViewChild('table') table: Table;
	@ViewChild('colselection') colselection: MultiSelect;
	@ViewChild('emptyCanvas', { static: false }) emptyCanvas: ElementRef;
	apiUrl: string = '';
	buttonColWidth: number = 150;
	cols: any[] = [];
	contentHeight: number = 4320;
	contextMenu: MenuItem[];
	count: number = 0;
	createPermission: boolean = false;
	entries: Base[];
	filters: string[];
	globalFilter: string = '';
	historyUrl: string = '';
	isMobile: boolean = false;
	isTableInit: boolean = false;
	loading: number = 0;
	loadTimestamp: Date;
	minTableHeight: number = 80;
	name: string = '';
	oldCols: any[] = [];
	persistenceCheckInterval: any;
	possibleCols: any[] = [];
	selectedEntry: Base;
	tableState: any;
	tableStateName: string = '';
	url: string = '';
	url_objekt: string = '';
	heapObjectId: number = null;
	visibleTable: boolean = true;

	constructor(
		public dialogService: DialogService,
		private exportService: ExportService,
		public globals: Globals,
		public elRef: ElementRef,
		private settingsService: SettingsService,
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
		public changeDetectorRef: ChangeDetectorRef,
		private zplPrinter: ZPLPrintService
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblGefaess';
		this.name = 'MENU.GEFAESS';
		this.url = '/' + PATH.GEFAESS;
		this.url_objekt = '/' + PATH.OBJEKT;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'barcode', required: true, width: 400 },
				{ type: 'date', key: 'erstdruck_zeitpunkt', required: true, width: 400 },
				{ type: 'date', key: 'nachdruck_zeitpunkt', required: true, width: 400 },
				{ type: 'numeric', key: 'druck_zaehler', required: true, width: 400 },
				{ type: 'text', key: 'bemerkung', required: false, width: 400 },
				{ type: 'boolean', key: 'FREMD_hat_identcode_sperre_im_zeitraum', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_identcode_sperre_sperrgrund', required: false, width: 400 },
				{ type: 'date', key: 'FREMD_identcode_sperre_von', required: false, width: 400 },
				{ type: 'date', key: 'FREMD_identcode_sperre_bis', required: false, width: 400 },
			],
			[
				{ type: 'object', key: 'FREMD_volumen_bezeichnung', required: false, width: 400, url: '/' + PATH.VOLUMEN, id: 'volumen_id' },
				{ type: 'object', key: 'FREMD_abfallart_bezeichnung', required: false, width: 400, url: '/' + PATH.ABFALLART, id: 'abfallart_id' },
				{ type: 'object', key: 'FREMD_abfuhrrhythmus_bezeichnung', required: false, width: 400, url: '/' + PATH.ABFUHRRHYTHMUS, id: 'abfuhrrythmus_id' },
				{ type: 'object', key: 'FREMD_typ_bezeichnung', required: false, width: 400, url: '/' + PATH.GEFAESS_TYP, id: 'typ_id' },
				{ type: 'object', key: 'FREMD_zustand_bezeichnung', required: false, width: 400, url: '/' + PATH.GEFAESS_ZUSTAND, id: 'zustand_id' },
				{ type: 'object', key: 'FREMD_status_bezeichnung', required: false, width: 400, url: '/' + PATH.GEFAESS_STATUS, id: 'status_id' },
				{ type: 'object', key: 'FREMD_objekt_bezeichnung', required: false, width: 400, url: '/' + PATH.OBJEKT, id: 'FREMD_objekt_id' },
				{ type: 'date', key: 'zugeordnet_von', required: false, width: 400 },
				{ type: 'date', key: 'zugeordnet_bis', required: false, width: 400 },
				{ type: 'numeric', key: 'FREMD_objekt_hausnummer1', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_objekt_hauszusatz1', required: false, width: 400 },
				{ type: 'numeric', key: 'FREMD_objekt_hausnummer2', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_objekt_hauszusatz2', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_objekt_zusatz_text', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_objekt_strasse_name', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_objekt_strasse_ortsteil', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_objekt_strasse_ort_name', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_objekt_strasse_ort_postleitzahl', required: false, width: 400 },
				{ type: 'object', key: 'FREMD_eigentuemer_bezeichnung', required: false, width: 400, url: '/' + PATH.KUNDE, id: 'FREMD_eigentuemer_id' },
				{ type: 'date', key: 'eigentuemer_von', required: false, width: 400 },
				{ type: 'date', key: 'eigentuemer_bis', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);

		this.historyUrl = '/objekt';
		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'date', key: 'FREMD_zugeordnet_von', required: true, width: 400 },
			{ type: 'date', key: 'FREMD_zugeordnet_bis', required: true, width: 400 },
			{ type: 'date', key: 'FREMD_eigentuemer_von', required: true, width: 400 },
			{ type: 'date', key: 'FREMD_eigentuemer_bis', required: true, width: 400 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_strasse_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer1', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_ort_plz', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_ort_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_gemeinde_bezeichnung', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'date', key: 'FREMD_zugeordnet_von', required: true, width: 400 },
			{ type: 'date', key: 'FREMD_zugeordnet_bis', required: true, width: 400 },
			{ type: 'date', key: 'FREMD_eigentuemer_von', required: true, width: 400 },
			{ type: 'date', key: 'FREMD_eigentuemer_bis', required: true, width: 400 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_strasse_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer1', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_ort_plz', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_ort_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_gemeinde_bezeichnung', required: false, width: 400 },
		];
	}

	// #region Angular

	ngOnInit() {
		this.loading += 1;

		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.DELETE);

		if (this.readPermission) {
			this.getHistory();
			this.getEntry();
		}

		this.stateName = 'state' + this.apiUrl + 'Detail';
		this.retrieveTableState(this.state);
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		this.tableStateName = 'state' + this.apiUrl + 'History' + 'List';
		this.retrieveTableState(this.tableState);
		if (this.tableState) {
			this.cols = [];
			this.tableState['columnOrder'].forEach(col => {
				this.possibleCols.forEach(c => {
					if (col == c.key) {
						this.cols.push(c);
					}
				});
			});
			if (this.tableState.filters.global) {
				this.globalFilter = this.tableState.filters.global.value;
			}
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detailHistory() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detailHistory('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detailHistory('window') },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw', command: () => this.resizeTableWidthFromContent(true) },
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.PRINT_CURRENT_LABEL'), icon: 'fa fa-print', command: () => this.printCMGefaessHistoryEntry() }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.loading -= 1;
	}

	ngAfterViewInit() {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		this.oldCols = this.cols;
		this.filters = this.cols.map(c => c.key);

		if (this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this._onResize();
	}

	ngAfterViewChecked() {
		if (!this.isTableInit && this.table && this.table.value) {
			this.isTableInit = true;
			this.resizeTableWidthFromContent(false);
			this.changeDetectorRef.detectChanges();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event): void {
		this._onResize();
	}

	_onResize() {
		this.resizeTableWidthFromContent(false, this.tableState);
	}

	// #endregion Angular

	// #region CRUD

	// override, weil vor Rückkehr zur gepufferten liste muss jetzt der gelöschte Eintrag aus dem Puffer gelöscht werden
	deleteEntry() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrl, this.entry.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });

					// hier eintrag aus gepufferter Liste löschen
					let storage = VIONSessionStorage.getInstance().get(this.apiUrl);
					if (null != storage && undefined != storage) {
						const storedTimestamp = new Date(storage.timestamp);
						if (null != storage.entries && undefined != storage.entries) {
							let index = 0;
							let foundElement = false;
							for (let i = 0; i < storage.entries.length; ++i) {
								if (storage.entries[i]['ds_this_id'] === this.entry.ds_this_id) {
									foundElement = true;
									break;
								}
								index += 1;
							}
							if (foundElement && index < storage.entries.length) {
								storage.entries.splice(index, 1);
								VIONSessionStorage.getInstance().set(this.apiUrl, storedTimestamp, storage.filters, storage.entries);
							}
						}
					}

					// und neu öffnen
					this.router.navigate([this.url + '/list/']);
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	getEntry() {
		this.loading += 1;
		this.crudService.getEntry(this.apiUrl, this.id).then(res => {
			this.entry = res;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'date') {
						if (this.entry[field.key] != null) {
							this.entry[field.key] = new Date(this.entry[field.key]);
						}
					}
				});
			});
			this.initTable();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion CRUD

	// #region History

	// #region CRUD

	getIsPrintZplDebug(): boolean {
		let result: boolean = false;
		let key: string = "zplDebug";
		var debugFlag = localStorage.getItem(key);
		if( debugFlag ) {
			result = debugFlag === 'true' ? true : false;
		} else {
			localStorage.setItem(key, 'false');
			debugFlag = localStorage.getItem(key);
			if( debugFlag ) {
				result = debugFlag === 'true' ? true : false;
			}
		}
		return result;
	}

	printGefaess(): void {
		if( this.entry && this.entry.ds_this_id ) {
			this.loading += 1;
			this.crudService.printGefaess(this.entry.ds_this_id).then(async printRes => {
				const blob = new Blob([printRes], { type: 'application/octet-stream' });
				if( this.getIsPrintZplDebug() ) {
					console.log(await blob.text());
				} else {
					await this.zplPrinter.print(await blob.text());
				}
				this.messageService.add({ severity: 'info', summary: 'Druckvorgang', detail: "Etikette wurde gedruckt", life: 10000 });
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading += 1;
				this.crudService.getEntry(this.apiUrl, this.id).then(res => {
					// hier das gleiche machen wie bei getEntry, aber eben auch noch die barcode sperre wenn nötig
					this.entry = res;
					this.fieldgroups.forEach(fg => {
						fg.forEach(field => {
							if (field.type == 'date') {
								if (this.entry[field.key] != null) {
									this.entry[field.key] = new Date(this.entry[field.key]);
								}
							}
						});
					});
					this.initTable();
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	printCMGefaessHistoryEntry(): void {
		if( this.entry && this.entry.ds_this_id && this.id && this.entries && this.entries.length > 0 && this.selectedEntry ) {
			this.loading += 1;
			var zugeordnetVon = this.selectedEntry["FREMD_zugeordnet_von"];
			let dtZugeordnetVon: Date = null;
			if(zugeordnetVon) {
				dtZugeordnetVon = new Date(zugeordnetVon);
				if( dtZugeordnetVon ) {
					dtZugeordnetVon.setHours(11, 0, 0, 0);
				}
			}

			var eigentuemerVon = this.selectedEntry["FREMD_eigentuemer_von"];
			let dtEigentuemerVon: Date = null;
			if(eigentuemerVon) {
				dtEigentuemerVon = new Date(eigentuemerVon);
				if( dtEigentuemerVon ) {
					dtEigentuemerVon.setHours(11, 0, 0, 0);
				}
			}

			let dtGfZeitpunkt = dtZugeordnetVon;
			if( !dtGfZeitpunkt || (dtEigentuemerVon && dtGfZeitpunkt < dtEigentuemerVon) ) {
				// dann eigentümerdatum als kontext entscheidend
				dtGfZeitpunkt = dtEigentuemerVon;
			}


			if( !dtGfZeitpunkt )
				return;

			//var lastBarcode = this.entry["barcode"];
			this.crudService.printGefaess(this.id /*hier natürlich nicht die id aus selectedEntry, weil das sind objekt zeilen*/, dtGfZeitpunkt).then(async printRes => {
				const blob = new Blob([printRes], { type: 'application/octet-stream' });
				if( this.getIsPrintZplDebug() ) {
					console.log(await blob.text());
				} else {
					await this.zplPrinter.print(await blob.text());
				}
				this.messageService.add({ severity: 'info', summary: 'Druckvorgang', detail: "Etiketten wurden gedruckt", life: 10000 });
			}).catch(err => {
				this.messageService.add({ severity: 'error', summary: 'Druckvorgang', detail: err.message, life: 10000 });
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
				this.loading += 1;
				this.crudService.getEntry(this.apiUrl, this.id).then(res => {
					// hier das gleiche machen wie bei getEntry, aber eben auch noch die barcode sperre wenn nötig
					this.entry = res;
					this.fieldgroups.forEach(fg => {
						fg.forEach(field => {
							if (field.type == 'date') {
								if (this.entry[field.key] != null) {
									this.entry[field.key] = new Date(this.entry[field.key]);
								}
							}
						});
					});
					this.getHistory();
					console.log('reloaded 1 entry');
					//if( lastBarcode !== this.entry['barcode'] && zugeordnetVon ) {
					//	console.log('locking identcode ' + lastBarcode);
					//	this.loading += 1;
					//	// der alte Barcode soll gesperrt werden (im finally-Block, weil es bei Fehldruck sonst nicht ausgeführt wird, aber ggf. der Identcode bereits verändert wurde)
					//	this.crudService.createLockBarcode(
					//		<JSON><unknown>{
					//			"barcode": lastBarcode,
					//			"lockingReason": "neues Etikett wurde gedruckt",
					//			"firstDay": dtGfZeitpunkt,
					//			"lastDay": null,
					//			"flags": 1
					//		}
					//	).catch(err => {
					//		err.error.forEach(e => {
					//			this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					//		})
					//	}).finally(() => {
					//		this.loading -= 1;
					//	});
					//}
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			});
		}
	}

	detailHistory(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	getHistory() {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.crudService.getObjectHistoryByGarbageCan(this.id).then(res => {
			this.entries = res;
			this.count = this.entries.length;
			this.possibleCols.forEach(c => {
				if (c.type == 'date') {
					this.entries.forEach(e => {
						if (e[c.key] != null) {
							e[c.key] = new Date(e[c.key]);
						}
					});
				}
			});
			//this.resizeTableWidthFromContent(false);
			this.initTable();
			setTimeout(() => this._onResize(), 1000);
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion CRUD

	// #region Table

	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	exportPDF() {
		this.exportService.exportPDF(this.translate.instant(this.tableStateName), this.table.value, this.cols);
	}

	exportXLSX() {
		this.exportService.exportXLSX(this.translate.instant(this.tableStateName), this.table.value, this.cols);
	}

	redrawTable(): void {
		this.visibleTable = false;
		setTimeout(() => this.visibleTable = true, 0);
	}

	initTable() {
		this.contentHeight = 0;
		setTimeout(() => {
			const detailDiv = document.getElementById('details');
			if (detailDiv) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 320 - detailDiv.offsetHeight + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.entries && this.entries.length > 0) {
					let entryHeight;
					if (this.entries.length < 5) {
						entryHeight = this.minTableHeight + (this.entries.length * 45);
					} else {
						entryHeight = this.minTableHeight + (5 * 45);
					}
					if (entryHeight > this.contentHeight) {
						this.contentHeight = entryHeight;
					}
				} else {
					this.contentHeight = this.minTableHeight;
				}
				this.redrawTable();
			}
		}, 0);
		this.resizeTableWidthFromContent(false);
		//this.changeDetectorRef.detectChanges();
	}

	isColFiltered(col) {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.tableState && this.tableState.filters[col.key]) {
			Object.keys(this.tableState.filters[col.key]).forEach(filter => {
				if (this.tableState.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event) {
		this.count = this.table.filteredValue ? this.table.filteredValue.length : this.entries.length;
	}

	onColReorder(event) {
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		const columnWidths = this.tableState.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.tableState.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
	}

	onColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.tableState.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));

		this.resizeTableWidth(this.tableState);
	}

	resetTable() {
		this.table.clearState();
		window.location.reload();
	}

	resizeTableWidth(state?) {
		this.loading += 1;

		this.retrieveTableState(this.tableState);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			tableElement.style.width = '100%';
			const columnWidths = this.tableState ? this.tableState.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.tableState) {
					localStorage.setItem(this.stateName, JSON.stringify(this.tableState));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(state?) {
		this.tableState = state ? state : JSON.parse(localStorage.getItem(this.tableStateName));
		if (this.table && (this.tableState == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		}
	}

	toggleColumn(event) {
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		this.tableState.columnOrder = event.value.map(c => c.key);
		this.tableState.columnWidths = event.value.map(c => c.width);
		this.tableState.columnWidths = this.tableState.columnWidths.join(',');
		this.tableState.columnWidths = this.tableState.columnWidths + ',' + this.buttonColWidth;
		this.tableState.tableWidth = (this.tableState.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
		this.resizeTableWidth(this.tableState);
	}

	// liefert die länge der darstellung eines textes in pixeln zurück 
	getTextLength(text: string, styleFont: string) {
		const ctx = this.emptyCanvas.nativeElement.getContext('2d');
		ctx.font = styleFont;
		const textMetrics = ctx.measureText(text);
		return Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
	}

	// wenn eine spalte noch ein special symbol im header hat
	adaptColumnSize(maxStringLength, columnkey) {
		return maxStringLength;
	}

	// berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	resizeTableWidthFromContent(bForce, state?) {
		var bResize = bForce;

		this.loading += 1;

		// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
		this.tableState = state ? state : JSON.parse(localStorage.getItem(this.tableStateName));
		if (this.tableState == undefined) {
			// force storage of table state
			this.table.saveState();
			// reload state
			this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
			bResize = true;
		}

		if (this.table && bResize) {
			// autosize columns
			const lTable = document.getElementById(this.table.id);
			var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
			// für alle spalten, alle daten            
			this.cols.forEach(col => {
				let columnname = this.translate.instant('HEADERS.' + col.key);
				let maxStringLength = this.getTextLength(columnname, lTableFont);
				// filter symbol
				maxStringLength = maxStringLength + 80;
				maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
				if (this.entries) {
					this.entries.forEach(row => {
						let newLength = 0;
						if (col.type == 'date') {
							if (row[col.key] != undefined)
								newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
							else
								newLength = 0;
						} else {
							newLength = this.getTextLength(row[col.key], lTableFont);
						}
						// margins zur zelle
						newLength = newLength + 26;
						if (newLength > maxStringLength)
							maxStringLength = newLength;
					})
				}

				col.width = maxStringLength;
			});

			this.tableState.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		}

		// standard funktion aufrufen
		this.resizeTableWidth(this.tableState);

		this.loading -= 1;
	}

	// #endregion Table

	// #region Spezial

	printCounterDecrement() : void {
		if( this.entry && this.entry.ds_this_id) {
			this.confirmationService.confirm({
				message: this.translate.instant('CONFIRMATION.PRINTCOUNTER_DECREMENT'),
				header: this.translate.instant('CONFIRMATION.CONFIRM'),
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: this.translate.instant('CONFIRMATION.YES'),
				rejectLabel: this.translate.instant('CONFIRMATION.NO'),
				accept: () => {
					this.loading += 1;
					this.crudService.printCounterDecrement([this.entry.ds_this_id]).then(res => {
						if (res /*&& res === 1*/) {
							this.getEntry();
							this.getHistory();
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 30000 });
						}
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
							}
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		}
	}

	printCounterIncrement() : void {
		if( this.entry && this.entry.ds_this_id) {
			this.confirmationService.confirm({
				message: this.translate.instant('CONFIRMATION.PRINTCOUNTER_INCREMENT'),
				header: this.translate.instant('CONFIRMATION.CONFIRM'),
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: this.translate.instant('CONFIRMATION.YES'),
				rejectLabel: this.translate.instant('CONFIRMATION.NO'),
				accept: () => {
					this.loading += 1;
					this.crudService.printCounterIncrement([this.entry.ds_this_id]).then(res => {
						if (res /*&& res === 1*/) {
							this.getEntry();
							this.getHistory();
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 30000 });
						}
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
							}
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		}
	}

	moveToHeapObject() {
		this.loading += 1;
		this.crudService.getSpecialObjectId(tbl_objekt_flags.flag_Objekt_ObjektTyp_Halde).then(res => {
			this.heapObjectId = res;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
			// dialog öffnen, datum erhalten, umstellen auf this.heapObjectId
			if( this.heapObjectId ) {
				const ref = this.dialogService.open(TblGefaessMoveToHaldeDialogComponent, {
					header: this.translate.instant('HEADERS.GFMOVETO_HEAP_POPUP'),
					width: '70%'
				});
				
				ref.onClose.subscribe((filters) => {
					if (filters) {
						if( filters['GF_HALDE_FROM'] ) {
							//console.log('Stelle auf Halde ab:')
							//console.log(new Date(filters['GF_HALDE_FROM']));
							let target = {
								gefaess_id: this.id,
								objekt_id: this.heapObjectId,
								datum_von: new Date(filters['GF_HALDE_FROM']),
								force: false
							};
							this.loading += 1;
							this.crudService.moveGarbageCan(target).then(res => {
								if (res && res.length && res.length > 0) {
									this.confirmationService.confirm({
										message: this.translate.instant('CONFIRMATION.CONFLICTS'),
										header: this.translate.instant('CONFIRMATION.CONFIRM'),
										icon: 'pi pi-exclamation-triangle',
										acceptLabel: this.translate.instant('CONFIRMATION.YES'),
										rejectLabel: this.translate.instant('CONFIRMATION.NO'),
										accept: () => {
											this.loading += 1;
											target.force = true;
											this.crudService.moveGarbageCan(target).then(res => {
												if (res && res.length && res.length > 0) {
													this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 30000 });
												} else {
													this.getHistory();
													this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
												}
											}).catch(err => {
												err.error.forEach(e => {
													if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
														this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
													} else {
														this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
													}
												})
											}).finally(() => {
												this.loading -= 1;
											});
										}
									});
								} else {
									this.getHistory();
									this.getEntry();
									this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
								}
							}).catch(err => {
								err.error.forEach(e => {
									if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
										this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
									} else {
										this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
									}
								})
							}).finally(() => {
								this.loading -= 1;
							});
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 30000 });
						}
					}
				});
			} else {
				this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORMSG.HEAP_NOT_FOUND_HEADER'), detail: this.translate.instant('ERRORMSG.HEAP_NOT_FOUND_MESSAGE'), life: 7000 });
			}
		});
	}

	move() {
		const ref = this.dialogService.open(MoveGarbageCanDialogComponent, {
			header: this.translate.instant('HEADERS.GEFAESS_STELLEN'),
			width: '70%'
		});

		ref.onClose.subscribe((target) => {
			if (target) {
				this.loading += 1;
				target.gefaess_id = this.id;
				this.crudService.moveGarbageCan(target).then(res => {
					if (res && res.length && res.length > 0) {
						this.confirmationService.confirm({
							message: this.translate.instant('CONFIRMATION.CONFLICTS'),
							header: this.translate.instant('CONFIRMATION.CONFIRM'),
							icon: 'pi pi-exclamation-triangle',
							acceptLabel: this.translate.instant('CONFIRMATION.YES'),
							rejectLabel: this.translate.instant('CONFIRMATION.NO'),
							accept: () => {
								this.loading += 1;
								target.force = true;
								this.crudService.moveGarbageCan(target).then(res => {
									if (res && res.length && res.length > 0) {
										this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 30000 });
									} else {
										this.getHistory();
										this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
									}
								}).catch(err => {
									err.error.forEach(e => {
										if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
											this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
										} else {
											this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
										}
									})
								}).finally(() => {
									this.loading -= 1;
								});
							}
						});
					} else {
						this.getHistory();
						this.getEntry();
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
					}
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	printLabel() {
		this.messageService.add({ severity: 'info', summary: 'Druckvorgang', detail: 'Etikett wird gedruckt', life: 5000 });
		this.printGefaess();
	}

	lockBarcode() {
		const ref = this.dialogService.open(PopupFromToReasonComponent, {
			data: {
				UserName: "user1",
				UserConnectionSettingsId: 123
			},
			header: this.translate.instant('Barcodesperre festlegen'),
			width: '30%'
		});

		ref.onClose.subscribe((result) => {
			if (result) {
				this.loading += 1;
				var fromDate = result["fromDate"];
				var toDate = result["toDate"];
				var reason = result["reason"];
				// wenn leer, dann ab heute sperren
				if (fromDate == null) {
					fromDate = new Date();
					fromDate = new Date(
						(fromDate as Date).getFullYear(), (fromDate as Date).getMonth(), (fromDate as Date).getDay(),
						0, 0, 0);
				}

				this.crudService.createLockBarcode(
					<JSON><unknown>{
						"barcode": this.entry["barcode"],
						"lockingReason": reason,
						"firstDay": fromDate,
						"lastDay": toDate,
						"flags": 1
					}
				).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading--;
					this.getHistory();
					this.getEntry();
				});
			}
		});
	}


	// #endregion Spezial

	// #endregion History
}