import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Module, Operation } from '@app/models/permission';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { User } from '@models/admin';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { VToolsCreateComponent } from '../vtools/create.component';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class AdminVisyUsersDetailComponent implements OnInit {
	adminPermission: boolean = false;
	entry: User;
	fieldgroups: any[] = [];
	id: string;
	limitedVehicles = [];
	loading: number = 0;
	name: string = '';
	permissions: any[];
	state: any = {};
	stateName: string = '';
	url: string = '';
	url_edit: string = PATH.EDIT;
	url_detail: string = PATH.DETAIL;

	constructor(
		private adminService: AdminService,
		private breadcrumbService: BreadcrumbService,
		private confirmationService: ConfirmationService,
		private crudService: CRUDService,
		public dialogService: DialogService,
		private jwtHelper: JwtHelperService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = href[href.length - 1];

		this.name = 'MENU.NUTZER';
		this.url = '/' + PATH.VISY_USERS;
		this.stateName = 'state' + 'AdminVisyUsers' + 'Detail';

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		this.permissions = [
			{ key: Module.OnlineCollections, label: 'Online Leerungen' },
			{ key: Module.OnlineVehicles, label: 'Online Fahrzeuge' },
			{ key: Module.BHV, label: 'BHV' },
			{ key: Module.ReversingCadastral, label: 'Rückfahrkataster' },
			{ key: Module.Masterdata, label: 'Stammdaten' },
		];

		this.fieldgroups = [
			[
				{ type: 'numeric', key: 'Id', required: true, width: 400 },
				{ type: 'text', key: 'Email', required: true, width: 400 },
				{ type: 'text', key: 'RoleName', required: true, width: 400 },
				{ type: 'boolean', key: 'TwoFactorEnabled', required: true, width: 400 },
				{ type: 'boolean', key: 'TwoFactorOnEveryLogin', required: true, width: 400 },
			], [
				{ type: 'object', key: 'UserConnectionSettingsName', required: true, width: 400, url: '/' + PATH.CONNECTION_SETTINGS, id: 'UserConnectionSettingsId' },
				{ type: 'boolean', key: 'MasterUser', required: true, width: 400 },
				{ type: 'boolean', key: 'ServiceUser', required: true, width: 400 },
				{ type: 'boolean', key: 'LimitVehicleAccess', required: true, width: 400 },
				{ type: 'vtools', key: 'VtoolsAuthentication', required: true, width: 400 },
			]
		];

		this.state = JSON.parse(localStorage.getItem(this.stateName));
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		const role = this.jwtHelper.decodeToken(localStorage.getItem('jwt'))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		if (role === 'ViSyAdministrator') {
			this.adminPermission = true;
			this.getEntry();
		}

		this.loading -= 1;
	}

	// #endregion Angular

	// #region CRUD

	deleteEntry(): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.adminService.deleteUser(this.entry.Id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					this.router.navigate([this.url + '/' + PATH.LIST]);
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	getEntry(): void {
		this.loading += 1;
		this.adminService.getUser(this.id).then(res => {
			this.entry = res;

			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'date') {
						if (this.entry[field.key] != null) {
							this.entry[field.key] = new Date(this.entry[field.key]);
						}
					}
				});
			});

			const bookedModules = [];
			this.permissions.forEach(p => {
				if (this.isBookedModule(p.key)) {
					bookedModules.push(p);
				}
			});
			this.permissions = bookedModules;

			this.limitedVehicles = [];
			this.crudService.getAllVehicles().then(vehicles => {
				const limitedIds = res['AccessibleVehicles'] ? res['AccessibleVehicles'].split(",") : [];
				vehicles.forEach(v => {
					if (limitedIds.indexOf(v.logbox_serial) > -1) {
						this.limitedVehicles.push(
							{ name: v.kennzeichen ? v.kennzeichen + ' (' + v.logbox_serial + ')' : ' - (' + v.logbox_serial + ')', value: v.logbox_serial }
						);
					}
				});
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			});
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion CRUD

	// #region Spezial

	isBookedModule(module: Module): boolean {
		return (this.entry.UserConnectionSettings.BookedModules & Math.pow(2, module)) == Math.pow(2, module);
	}

	isPermitted(module: Module, operation: Operation): boolean {
		let permission;
		if (operation == 0) {
			permission = (this.entry.CreatePermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 1) {
			permission = (this.entry.ReadPermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 2) {
			permission = (this.entry.UpdatePermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 3) {
			permission = (this.entry.DeletePermissions & Math.pow(2, module)) == Math.pow(2, module);
		}
		return permission;
	}

	activateVtools(): void {
		const ref = this.dialogService.open(VToolsCreateComponent, {
			data: {
				UserName: this.entry.Email,
				UserConnectionSettingsId: this.entry.UserConnectionSettings.Id
			},
			header: this.translate.instant('VTools')
		});

		ref.onClose.subscribe((vtools) => {
			if (vtools) {
				this.loading += 1;
				this.adminService.createVtoolsAuthentication(vtools).then(res => {
					this.entry['VtoolsAuthentication'] = vtools;
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	deactivateVtools(): void {
		this.loading += 1;
		this.entry['VtoolsAuthentication']['Mode'] = 2;
		this.adminService.deleteVtoolsAuthentication(this.entry['VtoolsAuthentication']).then(res => {
			this.entry['VtoolsAuthentication'] = null;
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	setHorizontalGroups(value: boolean): void {
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		if (!this.state) {
			this.state = {};
		}
		this.state.horizontalGroups = value;
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	// #endregion Spezial
}