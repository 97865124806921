<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">
	<!-- Message -->
	<p-toast>
		<ng-template let-message pTemplate="message">
			<div class="p-grid p-ml-2">
				<div class="p-col-2">
					<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
				</div>
				<div class="p-col-10">
					<h4>{{message.summary}}</h4>
				</div>
				<div class="p-col-12">
					<p>{{message.detail}}</p>
				</div>
				<div *ngIf="message.severity!='success'" class="p-col-12">
					<a class="p-button"
						href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
						Fehler melden
					</a>
				</div>
			</div>
		</ng-template>
	</p-toast>

	<div class="p-m-2">
		<a [routerLink]="route" (click)="translate.use('de')">
			de
		</a>
		|
		<a [routerLink]="route" (click)="translate.use('en')">
			en
		</a>
	</div>
	<div class="login-content">
		<div class="login-panel">
			<div class="login-panel-content">
				<div class="logo p-my-5">
					<img src="assets/images/visy/logo.svg" style="width:100%;" />
				</div>
				<h1 translate>LOGIN.LOGIN</h1>
				<p translate>LOGIN.WELCOME_MSG</p>
				<div class="forms">
					<div class="login-input-wrapper">
						<label for="username" translate>LOGIN.EMAIL</label>
						<input id="username" (input)="username=$event.target.value;" pInputText placeholder=""
							(keyup.enter)="confirmNoteOnTheGDPR()" />
						<i class="pi pi-user"></i>
					</div>

					<div class="login-input-wrapper">
						<label for="password" translate>LOGIN.PASSWORD</label>
						<input type="password" id="password" (input)="password=$event.target.value;" placeholder=""
							pPassword [feedback]="false" (keyup.enter)="confirmNoteOnTheGDPR()" />
						<i class="pi pi-lock"></i>
					</div>

					<div class="login-input-wrapper">
						<label for="twofactor" translate>LOGIN.TWOFACTOR</label>
							<input
							id="twofactor"
							pInputText
							placeholder="......"
							style="font-family: monospace;"
							maxlength="6"
							(input)="twoFactorCode=$event.target.value;"
							(keyup.enter)="confirmNoteOnTheGDPR()"
							/>
						<i class="pi pi-mobile"></i>
					</div>

					<!-- <div class="p-col-12">
						<p-checkbox [(ngModel)]="rememberMe" label="{{'LOGIN.REMEBERME'|translate}}" value="SaveInfo"
							binary="true"></p-checkbox>
					</div> -->


					<div class="buttons">
						<button pButton type="button" [disabled]="loading" (click)="confirmNoteOnTheGDPR()"
							(keyup.enter)="confirmNoteOnTheGDPR()" label="{{'LOGIN.LOGIN'|translate}}">
						</button>
						<!-- <a href="#" class="secondary-button">Passwort vergessen?</a> -->
					</div>

					<div *ngIf="loading" class="spinner">
						<span class="ball-1"></span>
						<span class="ball-2"></span>
						<span class="ball-3"></span>
						<span class="ball-4"></span>
						<span class="ball-5"></span>
						<span class="ball-6"></span>
						<span class="ball-7"></span>
						<span class="ball-8"></span>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>


<!-- Confirm -->
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>